import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { WhenFieldChangesDo } from '../../libraries/forms';
import Icon from '../../libraries/icons';
import PrivateButton from './PrivateButton';
import SearchInput from '../forms/SearchInput';
import Button from './Button';

export default function NavActionBar({ actions, breadcrumbs, ...rest }) {
  return (
    <div className={'navbar pt-4 ' + (rest.className ? rest.className : '')}>
      <div className="flex-1 hidden lg:flex">
        <div className="text-3xl font-base leading-7 sm:text-3xl sm:leading-9 sm:truncate">
          <ol className="list-none p-0 inline-flex">
            {breadcrumbs &&
              breadcrumbs.map((item, index) => (
                <li key={`bc${index}`} className="flex items-center">
                  <span className="pr-2">{item}</span>
                  {breadcrumbs.length > index + 1 ? (
                    <Icon className="w-8 h-8 pr-2" name="cheveron_right" />
                  ) : null}
                </li>
              ))}
          </ol>
        </div>
      </div>
      <div className="flex-1 lg:flex-none">
        {actions && actions.search && (
          <Form
            initialValues={Object.assign({})}
            onSubmit={actions.search.onSearch}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="search"
                    component={SearchInput}
                    inputClassName="input-ghost"
                    placeholder={actions.search.title}
                    onClick={actions.search.onSearch}
                  />
                </form>
              );
            }}
          </Form>
        )}
      </div>
      <div className="flex-none">
        {actions && actions.form && actions.form.fields && (
          <Form onSubmit={actions.form.onSubmit && actions.form.onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit} className="flex pb-4">
                  {actions.form.fields.map((action, index) => {
                    let visible = true;
                    if (
                      action.hasOwnProperty('visible') &&
                      action.visible !== null
                    ) {
                      // eslint-disable-next-line
                      visible = action.visible;
                    }
                    if (
                      action.hasOwnProperty('component') &&
                      action.component !== null
                    ) {
                      return (
                        <div
                          key={action.name}
                          className="flex ml-1 lg:ml-2 sm:ml-4"
                        >
                          {action.title && (
                            <span className="my-auto pr-4">{action.title}</span>
                          )}
                          <Field
                            className={
                              'my-auto ' +
                              (action.className ? action.className : '')
                            }
                            name={action.name}
                            component={action.component}
                            options={action.data}
                            noError={true}
                            validate={action.validate}
                          />
                          <WhenFieldChangesDo
                            field={action.name}
                            action={({ field, value, values }) => {
                              action.onChange && action.onChange(value);
                            }}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </form>
              );
            }}
          </Form>
        )}
        {actions &&
          actions.secondaries &&
          actions.secondaries.map((action, index) => {
            let actionColumn = null;
            let visible = true;
            if (action.hasOwnProperty('visible') && action.visible !== null) {
              visible = action.visible;
            }
            if (action.hasOwnProperty('render') && action.render !== null) {
              actionColumn = action.render();
            } else {
              actionColumn = (
                <Button
                  onClick={action.onClick}
                  disabled={action.disabled}
                  title={action.title}
                  icon={action.icon}
                  className={action.className}
                />
              );
            }
            return visible ? (
              <span
                className="ml-1 lg:ml-1 sm:ml-2"
                key={`actionButton${index}`}
              >
                <PrivateButton control={action.checkPermissions}>
                  {actionColumn}
                </PrivateButton>
              </span>
            ) : (
              ''
            );
          })}
        {actions && actions.main && actions.main.onClick && (
          <span className="ml-1 lg:ml-1 sm:ml-2">
            <PrivateButton control={actions.main.checkPermissions}>
              {typeof actions.main.visible !== "undefined" && actions.main.visible === false ? '' :
                (<Button
                  onClick={actions.main.onClick}
                  disabled={actions.main.disabled}
                  title={actions.main.title}
                  icon={actions.main.icon}
                  className={actions.main.className}
                />)}
            </PrivateButton>
          </span>
        )}
        {actions && actions.main && actions.main.to && (
          <span className="ml-1 lg:ml-1 sm:ml-2">
            <PrivateButton control={actions.main.checkPermissions}>
              <Link
                className={
                  actions.main.className
                    ? actions.main.className
                    : 'btn btn-link'
                }
                to={actions.main.to}
              >
                {actions.main.icon && (
                  <Icon
                    className={
                      '-ml-1 h-4 w-4' + (actions.main.title ? ' mr-2' : '')
                    }
                    name={actions.main.icon}
                  />
                )}
                {actions.main.title}
              </Link>
            </PrivateButton>
          </span>
        )}
      </div>
    </div>
  );
}
