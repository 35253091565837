import React from 'react';
import Icon from '../../libraries/icons';

const SearchInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={'form-control ' + (rest.className ? rest.className : '')}>
      {label && (
        <label
          htmlFor={input.name}
          className={
            'label ' + (rest.labelClassName ? rest.labelClassName : '')
          }
        >
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className="relative">
        <input
          {...input}
          type="text"
          className={
            'input w-full ' +
            (rest.inputClassName ? rest.inputClassName : 'input-bordered')
          }
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
        />
        <button
          type="button"
          className={
            'absolute right-0 ' +
            (rest.classButton ? rest.classButton : 'mt-2 mr-2')
          }
          onClick={() => rest.onClick && rest.onClick({ search: input.value })}
        >
          <Icon className="w-8 h-8 pr-2" name="search" />
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
