import config from '../../config';
import { request } from '../../libraries/request'
import { isObject } from '../../libraries/utils';

const getAll = (params = {}, token) => {
  console.log('SERVICES::RECEIPTS::getAll', params, token);
  if (params?.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) { 
    params.order_by = 'receipts__date';
    params.order_direction = 'DESC';
  }

  return request({
    url: config.BASE_API_URL + config.API.RECEIPTS,
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.data.forEach((e, index) => {
        response.data.data[index].date = new Date(e.date);
      });
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
      // sorting array
      response.data.data.sort((i, j) => { 
        return j.date - i.date;
      });
    }
    return response;
  })
}

const save = (data, token) => {
  console.log('SERVICES::RECEIPTS::save', data, token);
  if (Array.isArray(data)) { 
    console.log('SENDING ARRAY OF RECEIPTS');
    data.forEach((e, index) => {
      data[index].date = new Date(e.date);
    });
  } else {
    data.date = data.date.toISOString().substring(0,10);
    // if(data.receipts_lines) data.receipts_lines = data.receipts_lines.map((e, index) => {
      
    // });
  }
  
  return request({
    url: config.BASE_API_URL + config.API.RECEIPTS,
    accessToken: token,
    method: 'POST',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const update = (data, token) => {
  console.log('SERVICES::RECEIPTS::update', data, token)
  if (Array.isArray(data)) { 
    console.log('SENDING ARRAY OF RECEIPTS');
    data.forEach((e, index) => {
      data[index].date = new Date(e.date);
    });
  } else {
    data.date = data.date.toISOString().substring(0,10);
    // if(data.receipts_lines) data.receipts_lines = data.receipts_lines.map((e, index) => {
      
    // });
  }
  return request({
    url: config.BASE_API_URL + config.API.RECEIPT.replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const get = (id, token) => {
  console.log('SERVICES::RECEIPTS::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.RECEIPT.replace('{id}', id),
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    const {data : {data}} = response
    if (response.success) {
      if (isObject(data.provider)) data.provider = data.provider.id
      data.date = new Date(data.date);      
      if(data.receipts_lines) data.receipts_lines = data.receipts_lines.map((e, index) => (
        {...e, invoice: e.invoice.id}
        ));

    }
    return response;
  })

}

const del = (id, token) => {
  console.log('SERVICES::RECEIPTS::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.RECEIPT.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const services = {
  getAll,
  save,
  get,
  del,
  update,
};

export default services;