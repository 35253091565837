const api = {
  LOCALSTORAGE_APP_TOKEN: 'LOCALSTORAGE_APP_TOKEN',
  LOCALSTORAGE_USER_TOKEN: 'REACT_USER_TOKEN_AUTH',
  
  ROLE_CUSTOMER: 1,
  ROLE_PROVIDER: 2,
  USER_ROLE_ID: 1, // User
  QUERIES_PAGE_SIZE: 8,
  API: {
    OAUTH: '/oauth/token',
    INTERNAL: {
      INFO: {
        IP: '/internal/ip/{ip}',
      },
    },    
    FILES: '/files/upload',
    TOKEN: '/users/token',
    LOGIN_SSO: '/connectors/ad/sso',
    LOGIN: '/users/login',
    REGISTER: '/users/register',
    FORGOT: '/users/forgot',
    VERIFY: '/users/verify',
    HASH: '/users/hash/{hash}',
    POINTS: '/users/{id}/rewards',
    
    USER: '/users/{id}',
    USERS: '/users',
    
    ROLES: '/roles',
    ROLE: '/roles/{id}',
    ROLE_USERS: '/roles/{id}/users',
    ROLE_USER: '/roles/{roleId}/users/{id}',

    ACCOUNTS: '/accounts/',
    ACCOUNT: '/accounts/{id}',
    
    LOCATION: '/locations/{id}',
    LOCATIONS: '/locations',
    SERVICE: '/products/{id}',
    SERVICES: '/products',
    TRANSACTION: '/transactions/{id}',
    TRANSACTIONS: '/transactions',
    VEHICLE: '/vehicles/{id}',
    VEHICLES: '/vehicles',
    
    ACTION: '/actions/{id}',
    ACTIONS: '/actions',

    AGREEMENTS: '/agreements',
    AGREEMENT: '/agreements/{id}',
    
    BOOKINGS: {
      AVAILABILITY: '/bookings/{owner}/availability',
      ROOT: '/bookings',
      ONE: '/bookings/{id}',
      SLOTS: {
        ROOT: '/bookings/{booking}/slots',
        ONE: '/bookings/{booking}/slots/{id}',
      }, 
      INACTIVITY: {
        ROOT: '/bookings/{booking}/inactivity',
        ONE: '/bookings/{booking}/inactivity/{id}',
      }, 
    },

    BENEFITS: '/benefits',
    BENEFIT: '/benefits/{id}',

    DISCOUNTS: '/discounts',
    DISCOUNT: '/discounts/{id}',

    BUSINESSES: '/businesses',
    BUSINESS: '/businesses/{id}',
    
    CATEGORIES: '/categories',
    CATEGORY: '/categories/{id}',

    CELL: '/cells/{id}',
    CELLS: '/cells',

    COMPANIES: '/companies/',
    COMPANY: '/companies/{id}',
    
    CONVERSIONS: '/conversions',
    CONVERSION: '/conversions/{id}',
    
    CUSTOMERS: '/customers/',
    CUSTOMER: '/customers/{id}',
    
    DIAGNOSTIC: '/diagnostics/{id}',
    DIAGNOSTICS: '/diagnostics',

    INITIATIVE: '/initiatives/{id}',
    INITIATIVES: '/initiatives',
    INITIATIVE_ASSISTANTS: '/initiatives/{id}/assistants',
    INITIATIVE_ASSISTANT: '/initiatives/{initiativeId}/assistants/{id}',
    INITIATIVE_ASSISTANCES: '/initiatives/{id}/assistances',
    INITIATIVE_ASSISTANCE: '/initiatives/{initiativeId}/assistances/{id}',

    INSURANCE: '/insurances/{id}',
    INSURANCES: '/insurances',
    
    INVOICES: '/invoices',
    INVOICE: '/invoices/{id}',
    
    MEASURES: '/measures',
    MEASURE: '/measures/{id}',
    
    MEDICAMENT: '/vademecum/{id}',
    MEDICAMENTS: '/vademecum',
    
    MESSAGES: '/messages/',
    MESSAGE: '/messages/{id}',

    PERMISSION: '/permissions/{id}',
    PERMISSIONS: '/permissions',
    PERMISSION_ROLES: '/permissions/{id}/roles',
    PERMISSION_ROLE: '/permissions/{permissionId}/roles/{id}',

    PRESCRIPTION: '/prescriptions/{id}',
    PRESCRIPTIONS: '/prescriptions',

    PRODUCTS: '/products',
    PRODUCT: '/products/{id}',
    
    PROVIDERS: '/providers',
    PROVIDER: '/providers/{id}',
    
    PROVISIONS: '/provisions',
    PROVISION: '/provisions/{id}',

    RECEIPTS: '/receipts',
    RECEIPT: '/receipts/{id}',

    RECEPTIONS: '/receptions',
    RECEPTION: '/receptions/{id}',
    RECEPTION_TO_PROVISION: '/receptions/to_provision',

    VADEMECUM: '/vademecums/{id}',
    VADEMECUMS: '/vademecums',
    
    SETTING: '/settings/{id}',
    SETTINGS: '/settings',

    JDE_EXCHANGE_RATE: '/connectors/db2/exchange_rate',
    JDE_DOCUMENT_TYPES: {
      INVOICE: '/connectors/db2/f0411la',
      RECEIPT: '/connectors/db2/f0413jb', 
    },
    JDE_INVOICES: '/connectors/db2/invoices',
    JDE_RECEIPTS: '/connectors/db2/receipts',

    SEND_MAIL: '/internal/mailer/send',
  },
};
export default api;