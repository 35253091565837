import React from 'react';
import config from '../../config';

export default function FormLayout({
  leftSide,
  rightSide,
  children,
  onSubmit,
  values,
  form,
  state,
  debug = true,
}) {
  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      {(leftSide || rightSide) && (
        <div className="flex flex-col sm:flex-row justify-between">
          {leftSide && (
            <div className="sm:w-5/12 flex flex-col">{leftSide}</div>
          )}
          {rightSide && (
            <div className="sm:w-5/12 flex flex-col">{rightSide}</div>
          )}
        </div>
      )}
      {children && children}
      {debug && config.DEBUG_MODE && values && (
        <pre>{JSON.stringify(values, 0, 2)}</pre>
      )}
      {debug && config.DEBUG_MODE && state && (
        <pre>{JSON.stringify(state, 0, 2)}</pre>
      )}
    </form>
  );
}
