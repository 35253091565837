import config from '../../config';
import { request } from '../../libraries/request';

const getAll = (params = {}, token) => {
  console.log('SERVICES::PRESCRIPTIONS::get', params, token);
  if (params && params.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) {
    console.log('Params order_by in PRESCRIPIONS disabled');
    //params.order_by = 'created_at';
    //params.order_direction = 'DESC';
  }

  return request({
    url: config.BASE_API_URL + config.API.PRESCRIPTIONS,
    accessToken: token,
    data: params,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
      for (const item of response.data.data) {
        if (typeof item.json_data.professional === 'string') {
          item.json_data.professional = JSON.parse(item.json_data.professional);
        }
        if (typeof item.json_data.patient === 'string') {
          item.json_data.patient = JSON.parse(item.json_data.patient);
        }
      }
    }
    return response;
  });
};

const save = (data, token) => {
  console.log('SERVICES::PRESCRIPTIONS::save', data, token);
  return request({
    url: config.BASE_API_URL + config.API.PRESCRIPTIONS,
    accessToken: token,
    method: 'POST',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const update = (data, token) => {
  console.log('SERVICES::PRESCRIPTIONS::update', data, token);
  return request({
    url: config.BASE_API_URL + config.API.PRESCRIPTION.replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const get = (id, token) => {
  console.log('SERVICES::PRESCRIPTIONS::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.PRESCRIPTION.replace('{id}', id),
    accessToken: token,
    // data: {},
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      if (typeof response.data.data.json_data.patient === 'string')
        response.data.data.json_data.patient = JSON.parse(
          response.data.data.json_data.patient
        );
      if (typeof response.data.data.json_data.professional === 'string')
        response.data.data.json_data.professional = JSON.parse(
          response.data.data.json_data.professional
        );
    }
    return response;
  });
};

const del = (id, token) => {
  console.log('SERVICES::PRESCRIPTIONS::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.PRESCRIPTION.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const updateMany = (data, token) => {
  console.log('SERVICES::PRESCRIPTIONS::update', data, token);
  return request({
    url: config.BASE_API_URL + config.API.PRESCRIPTIONS,
    accessToken: token,
    method: 'PATCH',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const services = {
  getAll,
  save,
  get,
  del,
  update,
  updateMany,
};

export default services;
