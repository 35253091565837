import types from './types';
import bookings from './services.slots';

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  }
}

const getAll = (params) => {

  function request() { return { type: types.GETALL_SLOTS_REQUEST } }
  function success(payload) { return { type: types.GETALL_SLOTS_SUCCESS, payload } }
  function failure(error) { return { type: types.GETALL_SLOTS_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::BOOKINGS_SLOTS::getAll', params);
    dispatch(request());
    const response = await bookings.getAll(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS_SLOTS::getAll::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };  

}

const get = (params) => {
  console.log('ACTIONS::BOOKINGS_SLOTS::get', params);

  const request = (params) => { return { type: types.GET_SLOTS_REQUEST, params } };
  const success = (payload) => { return { type: types.GET_SLOTS_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_SLOTS_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(params));
    let response = await bookings.get(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS_SLOTS::get::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };
}

const saveOrUpdate = (params) => {

  function request(payload) { return { type: types.SAVE_SLOTS_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SLOTS_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_SLOTS_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::BOOKINGS_SLOTS::saveOrUpdate', params);
    dispatch(request(params));

    let response;
    if (params.id) {  // editing a existing record
      response = await bookings.update(params, getState().users.auth.token)
    } else {
      response = await bookings.save(params, getState().users.auth.token)
    }
    console.log('ACTIONS::BOOKINGS_SLOTS::saveOrUpdate::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };
}

const del = (params) => {
  console.log('ACTIONS::BOOKINGS_SLOTS::del', params);
 
  function request(params) { return { type: types.DELETE_SLOTS_REQUEST, params } }
  function success(params) { return { type: types.DELETE_SLOTS_SUCCESS, params } }
  function failure(params, error) { return { type: types.DELETE_SLOTS_FAILURE, params, error } }

  return async (dispatch, getState) => {
    dispatch(request(params));
    let response = await bookings.del(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS_SLOTS::del::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(params));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}


const updateAll = (params) => {

  function request(payload) { return { type: types.UPDATEALL_SLOTS_REQUEST, payload } }
  function success(payload) { return { type: types.UPDATEALL_SLOTS_SUCCESS, payload } }
  function failure(error) { return { type: types.UPDATEALL_SLOTS_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::BOOKINGS_SLOTS::updateAll', params);
    dispatch(request(params));

    const response = await bookings.updateAll(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS_SLOTS::updateAll::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };
}

const actionsSlots = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  del,
  updateAll,
};

export default actionsSlots;