const types = {
  CLEAR_CURRENT: 'ACTION_CLEAR_CURRENT',
  GET_REQUEST: 'ACTION_GET_REQUEST',
  GET_SUCCESS: 'ACTION_GET_SUCCESS',
  GET_FAILURE: 'ACTION_GET_FAILURE',

  SAVE_REQUEST: 'ACTION_SAVE_REQUEST',
  SAVE_SUCCESS: 'ACTION_SAVE_SUCCESS',
  SAVE_FAILURE: 'ACTION_SAVE_FAILURE',

  GETALL_REQUEST: 'ACTION_GETALL_REQUEST',
  GETALL_SUCCESS: 'ACTION_GETALL_SUCCESS',
  GETALL_FAILURE: 'ACTION_GETALL_FAILURE',

  DELETE_REQUEST: 'ACTION_DELETE_REQUEST',
  DELETE_SUCCESS: 'ACTION_DELETE_SUCCESS',
  DELETE_FAILURE: 'ACTION_DELETE_FAILURE',    

  UPDATE_REQUEST: 'ACTION_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ACTION_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ACTION_UPDATE_FAILURE'    

};

export default types;