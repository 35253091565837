import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { validateIsfilled } from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextWithIconInput from '../../components/forms/TextWithIconInput';

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      showPassword: false,
      showPassword2: false,
      user: null,
    };
  }

  componentDidMount() {
    const params = this.props.match.params;
    if (params.hash) {
      this.getUserFromHash(params);
    } else {
      history.push(config.ROUTES.LOGIN);
    }
  }

  getUserFromHash = async (params) => {
    await this.props.onGetFromHash(params);
    if (this.props.user.error) {
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ user: this.props.user.item });
    }
  };

  onSubmit = async (values) => {
    const user = this.state.user;
    this.setState({ submitting: true });
    await this.props.onUpdate({
      password: values.password,
      id: user.id,
      user_type: config.USER_TYPE.REGULAR,
    });
    if (this.props.user.error) {
      this.setState({ submitting: false });
      notify(this.t(this.props.user.error.message));
    } else {
      await this.props.onLogin({
        email: user.email,
        password: values.password,
        user_type: config.USER_TYPE.REGULAR,
      });
      if (this.props.auth.error) {
        this.setState({ submitting: false });
        notify(this.t(this.props.auth.error.message));
      } else {
        history.push(config.ROUTES.HOME);
      }
    }
  };

  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onPasswordClick2 = () => {
    this.setState({ showPassword2: !this.state.showPassword2 });
  };

  render() {
    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const validate = (values) => {
      let errors = [];
      if (values.password !== values.password2)
        errors.password2 = this.t('Passwords must match');
      return errors;
    };
    return (
      <LayoutSmall
        main={{ className: 'bg-base-200 text-primary bg-pattern' }}
        header={{
          title: this.t('Set your password'),
          className: 'bg-transparent text-primary',
        }}
        container={{
          className: 'mt-10 p-4',
        }}
      >
        <ToastContainer />
        <Form onSubmit={this.onSubmit} validate={validate}>
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className="w-full max-w-lg">
              <div className="w-full px-3 mb-3 md:mb-0">
                <Field
                  name="password"
                  component={TextWithIconInput}
                  placeholder={this.t('Password')}
                  showPassword={this.state.showPassword}
                  clickPassword={this.onPasswordClick}
                  validate={required}
                />
              </div>
              <div className="w-full px-3 mb-10 md:mb-0">
                <Field
                  name="password2"
                  component={TextWithIconInput}
                  placeholder={this.t('Repite password')}
                  showPassword={this.state.showPassword2}
                  clickPassword={this.onPasswordClick2}
                  validate={required}
                />
              </div>
              <div className="w-full px-3">
                <Button
                  className="btn-primary btn-block"
                  title={this.t('Confirm')}
                  onClick={handleSubmit}
                  disabled={this.state.submitting}
                />
              </div>
            </form>
          )}
        </Form>
      </LayoutSmall>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    user: state.users.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (values) =>
      dispatch(userActions.saveOrUpdate(values, 'register')),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
    onLogin: (values) => dispatch(userActions.login(values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Password));
