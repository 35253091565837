import config from '../../config';
import { request } from '../../libraries/request'
import { isDate } from '../../libraries/utils';

const getAll = (params = {}, token) => {
  console.log('SERVICES::AGREEMENTS::get', params, token);
  if (params?.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) { 
    params.order_by = 'providers__name';
    params.order_direction = 'ASC';
  }

  return request({
    url: config.BASE_API_URL + config.API.AGREEMENTS,
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.data.forEach((e, index) => {
        response.data.data[index].start_at = new Date(e.start_at);
        response.data.data[index].end_at = new Date(e.end_at);
      });
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  })
}

const save = (data, token) => {
  console.log('SERVICES::AGREEMENTS::save', data, token);
  data.start_at = data.start_at && data.start_at.toISOString();
  data.end_at = data.end_at && data.end_at.toISOString();
  
  return request({
    url: config.BASE_API_URL + config.API.AGREEMENTS,
    accessToken: token,
    method: 'POST',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const update = (data, token) => {
  console.log('SERVICES::AGREEMENTS::update', data, token);
  data.start_at = !isDate(data.start_at) ? data.start_at : data.start_at.toISOString();
  data.end_at = !isDate(data.end_at) ? data.end_at : data.end_at.toISOString();

  return request({
    url: config.BASE_API_URL + config.API.AGREEMENT.replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const get = (id, token) => {
  console.log('SERVICES::AGREEMENTS::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.AGREEMENT.replace('{id}', id),
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      
    }
    return response;
  })

}

const del = (id, token) => {
  console.log('SERVICES::AGREEMENTS::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.AGREEMENT.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const services = {
  getAll,
  save,
  get,
  del,
  update,
};

export default services;