import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import config from '../../../config';
import Icon from '../../../libraries/icons';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';
import discountsActions from '../../../context/discounts/actions';
import businessesActions from '../../../context/businesses/actions';
import PrivateButton from '../../../components/commons/PrivateButton';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import { capitalize, getOwner } from '../../../libraries/utils';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Discounts extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      discounts: [],
      businessIDs: [],
      loading: false,
      windowWidth: window.innerWidth,
      sort: null,
      search: null
    };
    this.breadcrumbs = [this.t('Descuentos')];
  }

  // ** Life cycle
  componentDidMount() {
    const { user } = this.props.auth;
    this.setState({ loading: true });
    // if (user.roles.includes(config.ROLES.DISCOUNTS)) {
      // this.getBusinessesIDs(user.id);
    // } else {
      this.getDiscounts();
    // }

    // ** Get window width
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener('resize', this.handleResize);
  }
  // ** Function to handle resize
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  getCategory = async id => {
    if (id.length === 36) {
      await this.props.onGetCategory(id);
      if (this.props.category.error) notify(this.t(this.props.category.error.message));
      // returns a promise
      return this.props.category.item?.name;
    } else {
      return id;
    }
  };

  getBusinessesIDs = async id => {
    //await this.props.onGetBusinesses({ owner: id });
    await this.props.onGetBusinesses({ owner: getOwner(this.props.auth.user) });
    const { businesses } = this.props;
    if (businesses.error) {
      notify(this.t(businesses.error.message));
    } else {
      const businessIDs = businesses.items.map(b => b.id);
      this.setState({ businessIDs: businessIDs }, () => this.getDiscounts());
    }
  };

  getDiscounts = async (page_num = 1) => {
    // ** By default, page_num equal to 1, it will change if you send it as a parameter
    const params = { page_num };
    const { businessIDs, search } = this.state;
    const { user } = this.props.auth;
    // ** If search exist, filter the data
    if (search && search !== '') {
      params.where = { json_data: [] };
      if (search && search !== '') {
        params.where = { custom: { name: `%${search.toLowerCase()}` } };
      }
    }
    // ** If sort exist, sort the data
    if (this.state.sort) {
      params.order_by = this.state.sort.order_by;
      params.order_direction = this.state.sort.order_direction;
    }
    // ** Execute get request with params
    // interrumpir el get si no tengo negocios a mi nombre
    // if (user.roles.includes(config.ROLES.DISCOUNTS)) {
      // if (businessIDs.length < 1) {
        // notify(capitalize(this.t("user doesn't own any businesses")));
        // this.setState({ loading: false });
        // return;
      // }
      params.owner = 'system';
    // } else if (user.roles.includes(config.ROLES.COMPANIES)) {
      // params.type = 'user';
    // }

    // if (user.roles.includes(config.ROLES.ADMIN)) params.type = ['user', 'companies', 'both']
    await this.props.onGetAllDiscounts(params);

    const { discounts } = this.props;
    if (discounts.error) {
      notify(this.t(discounts.error.message));
    } else {
      // ** Set state based on response data
      this.setState({ discounts: discounts.items });
      //this.getCategoryNamesForActionItems(this.state.actions);
      this.setState({ loading: false });
    }
  };

  getCategoryNamesForActionItems = async actionItems => {
    for (let i = 0; i < actionItems.length; i++) {
      /* 
      await resolution of promise returned, 
      set value on the actionItem type once it's done 
      source for own reference: https://stackoverflow.com/questions/64164994/is-there-a-way-to-save-promise-result-as-a-variable
      -JC
      */
      //fixme: esto está mal, seguro se puede hacer con un promise.all
      actionItems[i].type = await this.getCategory(actionItems[i].type);
    }
  };
  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getDiscounts(currentPage));
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { discounts } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize('delete multiple elements') : '¿Are you sure?'),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          // ** Loading
          discounts.loading = true;
          // ** If the parameter recieved is an array, execute multiple delete function
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // ** Wait until the api removes selected items
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (discounts.error) {
              notify(this.t(discounts.error.message));
            } else {
              // ** Get actions
              this.getDiscounts(this.state.currentPage);
            }
            // ** If the parameter is a number execute unique delete
          } else {
            // ** wait until the api removes selected items
            await this.props.onRemove(id);
            if (discounts.error) {
              notify(this.t(discounts.error.message));
            } else {
              // ** Get actions
              this.getDiscounts(this.state.currentPage);
            }
          }
        }
      });
  };
  // ** Redirection to edit action route
  onEdit = id => {
    history.push(`${config.ROUTES.DISCOUNTS}/${id}`);
  };
  // ** Redirection to new action route
  onNew = () => {
    history.push(config.ROUTES.DISCOUNTS_NEW);
  };
  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getDiscounts());
  };
  // ** Use this function when configuring columns, its for hiding columns when the window width goes below the number specified
  hide = number => {
    if (this.state.windowWidth < number) {
      return 'lg';
    }
    return null;
  };

  render() {
    const { windowWidth, discounts, loading } = this.state;

    const columns = [
      {
        name: capitalize(this.t('name of discount')),
        sortable: true,
        selector: row => row['name'],
        sortSelector: 'name',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: capitalize(this.t('Enabled')),
        sortable: true,
        selector: row => row['enabled'] == 1 ? this.t("Habilitado") : this.t("Deshabilitado"),
        sortSelector: 'name',
        maxWidth: `${windowWidth - 64 - 465}px`
      },
      {
        name: 'Acciones',
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: this.hide(370),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full gap-2">
              <ButtonIcon onClick={() => this.onEdit(row.id)} icon="view_show" buttonClassName="btn-link text-gray-600 bg-gray-100 btn-sm" className="w-5" />
              <PrivateButton control="delete">
                <ButtonIcon onClick={() => this.onRemove(row.id)} icon="trash" buttonClassName="btn-link text-error bg-gray-100 btn-sm" />
              </PrivateButton>
            </div>
          );
        }
      },

    ];
    // ** Main and secondary actions of out layout
    const _actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions1: 'insert'
      },
      secondaries: [],
      search: { onSearch: this.onSearch, title: this.t('Search') }
    };
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={_actions}
        loading={loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={discounts}
            columns={columns}
            //selectableRows={this.props.auth.user.roles.includes(config.ROLES.DISCOUNTS) ? windowWidth > 600 : null}
            query={this.props.discounts.query}
            getDataFunction={this.getDiscounts}
            //multipleDeleteFunction={
            //  this.props.auth.user.roles.includes(config.ROLES.DISCOUNTS) ? this.onRemove : null
            //}
            pagination
            loading={loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            onRowExpand={() => {}}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    discounts: state.discounts.list,
    businesses: state.businesses.list,
    category: state.categories.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllDiscounts: params => dispatch(discountsActions.getAll(params)),
    //onGetCategory: id => dispatch(categoryActions.get(id)),
    onGetBusinesses: params => dispatch(businessesActions.getAll(params)),
    onRemove: id => dispatch(discountsActions.del(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Discounts));
