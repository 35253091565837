import React from 'react';
import Layout from '../components/layout/LayoutWithSidebar';
import Navbar from '../components/layout/Navbar';
import Icon from '../libraries/icons';
import { history } from '../routes';
import config from '../config';

export default function Dashboard() {
  const adminMenu = config.SIDEBAR.MENU.ADMIN.filter(e => e.route);
  return (
    <Layout>
      <Navbar>
        <div className="lg:w-0 lg:flex-1 inline-flex justify-start px-4">
          <span className="light-primary-text text-bold text-3xl">Dashboard</span>
        </div>
      </Navbar>
      <div className="grid grid-flow-row grid-cols-3 gap-4 mt-5 py-4">
        {adminMenu.map(({ icon, label, route }) => (
          <div
            key={`${icon}${route}${label}`}
            className="card white mb-3 p-4 h-full"
            onClick={() => {
              history.push(route);
            }}
            style={{ cursor: 'pointer' }}>
            <Icon className={'h-10 w-10 mx-auto text-primary'} name={icon} />
            <h4 className="m-auto">{label}</h4>
          </div>
        ))}
      </div>
    </Layout>
  );
}
