const provisions = {
  CLEAR_CURRENT: 'PROVISIONS_CLEAR_CURRENT',
  GET_REQUEST: 'PROVISIONS_GET_REQUEST',
  GET_SUCCESS: 'PROVISIONS_GET_SUCCESS',
  GET_FAILURE: 'PROVISIONS_GET_FAILURE',

  SAVE_REQUEST: 'PROVISIONS_SAVER_REQUEST',
  SAVE_SUCCESS: 'PROVISIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'PROVISIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'PROVISIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PROVISIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PROVISIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'PROVISIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PROVISIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PROVISIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'PROVISIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROVISIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROVISIONS_UPDATE_FAILURE'    

};

export default provisions