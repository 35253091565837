import React, { useState, useReducer } from 'react';
import useUpdateEffect from '../../../libraries/hooks/useUpdateEffect';
import { Form, Field } from 'react-final-form';
import FormLayout from '../../../components/forms/FormLayout';
import TextWithIconButtonInput from '../../../components/forms/TextWithIconButtonInput';
import AttachImage from '../../../components/forms/AttachImage';
import { uuidv4 } from '../../../libraries/utils';
import { capitalizePhrase, getOwner } from '../../../libraries/utils';
import Loader from '../../../components/commons/Loader';
import Swal from 'sweetalert2';
import Icon from '../../../libraries/icons';
import messageActions from '../../../context/messages/actions';
import filesActions from '../../../context/files/actions'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, notify } from '../../../libraries/notifications';
// import Picker from 'emoji-picker-react';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    denyButton: 'btn btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text',
  },
  buttonsStyling: false,
  background: '#fff',
});

const alignment = (message, owner) => {
  return message.source.id === owner || message.source === owner ? 'items-end' : 'items-start'
}
const style = (message, owner, chat, i) => {
  const prevMessage = chat[i - 1]
  const isPrevMessageDefined = prevMessage !== undefined
  return (message.source.id === owner || message.source === owner ? `text-black bg-gray-300 ml-2 md:ml-8 ${(!isPrevMessageDefined || prevMessage.source.id !== message.source.id) && 'rounded-tr-none mt-2'}` : `text-white bg-primary mr-2 md:mr-8 ${(!isPrevMessageDefined || prevMessage.source.id !== message.source.id) && 'rounded-tl-none mt-2'}`) + `${message.json_data?.deleted_for_all ? ' italic' : ' '}`
}

const seen = (seenIndex, chat, owner, i) => {
  return seenIndex === i && (i === chat.length - 1 || chat[i + 1].source.id === owner || chat[i + 1].source === owner)
}
const findLastSeen = (chat, owner) => {
  chat = chat.map(message => message.target.id !== owner ? message.readed : 0)
  return chat.lastIndexOf(1)
}


const initialState = { seenIndex: null, chat: [], root: null, chatWith: null, loading: true, preview: null, image_loading: true }

const Chat = ({ t, closeChat }) => {
  // ** Redux state
  const reduxDispatch = useDispatch()
  const saveOrUpdate = params => reduxDispatch(messageActions.saveOrUpdate(params))
  const pictureUpload = params => reduxDispatch(filesActions.upload(params))
  const pictureClean = () => reduxDispatch(filesActions.clean())
  const owner = useSelector(state => getOwner(state.users.auth.user))
  const message = useSelector(state => state.messages.current)
  const messages = useSelector(state => state.messages.list)
  const files = useSelector(state => state.files)
  const { items } = messages

  const scrollToBottom = () => {
    const chatHTML = document.getElementById('chat')
    chatHTML.scrollTo(0, chatHTML.scrollHeight)
  }
  // ** useReducer for handle the local state with actions
  const reducer = (state, action) => {
    let newState = { ...state }
    const setState = (data) => {
      newState = { ...newState, ...data }
    }
    switch (action.type) {
      case 'setItems':
        const _items = action.data ?? items
        const chat = _items.filter(e => e.source.id === owner || e.source === owner ? !e.json_data['deleted_for_source'] : !e.json_data['deleted_for_target'])
        setState({chat})
        break;
      case 'addItem':
        setState({chat : [...state.chat, action.data]})
        setTimeout(()=> scrollToBottom())
        break;
      case 'setRoot':
        const data = action.data ? {...action.data, source:{id: action.data.source}, target:{id: action.data.target}} : state.chat.find(e => e.parent === 'root')
        setState({ root: data })
        if (!state.chatWith) setState({ chatWith: data.source.id !== owner ? data.source : data.target })
        break;
      case 'seen':
        setState({seenIndex: findLastSeen(state.chat, owner) })
        break;
      case 'loadingChange':
        setState({loading: action.data })
        break;
      case 'replaceItemFromApi':
        const item = action.data || message.item
        setState({ chat: newState.chat
          .map(e => (e?.json_data?._id === item?.json_data?._id || e.id === item.id) ? {...item, source: { id: item.source }, target: { id: item.target }, json_data: {...item.json_data, picture: e?.json_data?.picture}} : e)
          .filter(e => e.source.id === owner || e.source === owner ? !e.json_data.deleted_for_source : !e.json_data.deleted_for_target)
        })
        break;
      case 'replaceItem':
        setState({chat : state.chat.map(e => e.id === action.data.id ? action.data : e)})
        break;
      case 'previewImage':
        setState({preview : action.data})
        break;
      case 'cleanPreview':
        setState({preview : null})
        break;
      case 'imageLoadingReady':
        setState({image_loading : false})
        break;
      default:
        throw new Error();
    }
    return newState;
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { seenIndex, chat, root, chatWith, loading, image_loading, preview } = state


  // ** Local states for boolean variables
  const [deleteMode, setDeleteMode] = useState(false)
  const [rootSeted, setRootSeted] = useState(false)

  // **  This function handles the submit event of our form
  const onSubmit = (values, form) => {
    if (validateForm(values)) {
      // ** Undeletes chat for the other part in case it has been deleted
      handleUndeleteChat()
      // create an object
      const message = {
        ...values,
        parent: root.id,
        subject: root.subject,
        source: owner,
        target: chatWith?.id,
        type: 'message',
        json_data: {
          _id: uuidv4(),
        }
      }
      // push the message to the array, while sending it, it will have a loading animation
      dispatch({ type: 'addItem', data: { ...message, source: { id: message.source }, target: { id: message.target }, loading: true } })
      // send message
      saveOrUpdate(message)
      form.reset()
    }
  }

  // ** Delete Message function
  const deleteMessage = async (index, message) => {
    const { id, source } = message
    const isOwnerMessage = source.id === owner || source === owner
    customSwal
      .fire({
        title: t('Delete message?'),
        icon: 'warning',
        showDenyButton: isOwnerMessage && !message?.json_data?.deleted_for_all,
        showCancelButton: true,
        confirmButtonText: t('For me'),
        denyButtonText: t('For everyone'),
        cancelButtonText: t('Cancel'),
      })
      .then(({ isConfirmed, isDenied }) => {
        // Depending on the option the user choose. The message with be deleted for the user, or for everyone
        if (isConfirmed || isDenied) {
          const obj = isDenied ? { id, json_data: { deleted_for_all: 1 }, body: 'Mensaje eliminado' } : { id, json_data: isOwnerMessage ? { deleted_for_source: 1 } : { deleted_for_target: 1 } }
          delete message.json_data.picture
          dispatch({ type: 'replaceItem', data: { ...message, loading: true, body: 'Eliminando'}})
          // ** Its a path request. We create an object here and manipulate the json server. Then we save the modified object
          saveOrUpdate(obj)
        }
      })
  }

  // ** This function undeletes the chat for the other part in case it was deleted. It means that if the other part deletes a chat, and recieve a message on the same chat. It will apear again
  const handleUndeleteChat = () => {
    const isOwnerChat = root.source.id === owner
    if (root.json_data[`deleted_chat_for_${isOwnerChat ? 'target' : 'source'}`]) {
      const obj = { id: root.id, json_data: { ...root.json_data, [`deleted_chat_for_${isOwnerChat ? 'target' : 'source'}`]: 0 } }
      saveOrUpdate(obj)
    }
  }

  // ** This is for pausing the conversation. This can only be ejected by the owner of the chat
  const handlePauseChat = () => {
    const obj = { id: root.id, json_data: { ...root.json_data, paused: root.json_data.paused ? 0 : 1 } }
    saveOrUpdate(obj)
  }

  const validateForm = (values) => {
    return values.body && true
  };

  // ** This effect delayes a bit the loading change. Preventing a latency visual bug caused by the chat rendering into screen after the loading screen disapeared
  useUpdateEffect(() => {
    setTimeout(() => {
      dispatch({ type: 'loadingChange', data:  messages.loading })
    }, messages.loading ? 0 : 100)
  }, [messages.loading])


  // ** This effect loads all the data into our local state. Sets the root element, the chat state and handles seen behavior of messages
  useUpdateEffect(() => {
    const handleSeen = (array) => {
      array.forEach((item) => {
        if (item.target?.id === owner && !item.readed) {
          saveOrUpdate({ id: item.id, readed: 1 })
        }
      })
    }
    if (!messages.loading) {
      if (messages.error) {
        notify(t(messages.error.message));
      } else {
        const {items} = messages
        dispatch({ type: 'setItems' })
        dispatch({ type: 'setRoot' })
        dispatch({ type: 'seen' })
        handleSeen(items)
        console.log('REDUX MESSAGES', messages)
      }
    }
  }, [messages])

  // ** This modified the root element to change the unread messages to 0 when entering a chat
  useUpdateEffect(() => {
    const handleNotifyReadedContent = () => {
      if (!rootSeted) {
        if (root.source.id) {
          const isOwnerChat = root.source.id === owner
          if (root.json_data[`unread_messages_for_${isOwnerChat ? 'source' : 'target'}`] > 0) {
            const obj = { id: root.id, json_data: { ...root.json_data, [`unread_messages_for_${isOwnerChat ? 'source' : 'target'}`]: 0 } }
            saveOrUpdate(obj)
          }
        }
        setRootSeted(true)
      }
    }
    handleNotifyReadedContent()
  }, [root])

  // ** Handles preview for images when attaching files
  const previewImage = ({data, e}) => {
    dispatch({type: 'previewImage', data: {data, src: URL.createObjectURL(e)}})
  }
  // ** Upload an image, and create a pseudo messages to show while the image is uploading
  const uploadImage = (values, form) => {
    console.log('UPLOAD IMAGE', preview)
    console.log('UPLOAD IMAGE', values)
    const {data, src} = preview
    const code = parseInt(Math.random() * 10 ** 9)
    let image_identifier = `message_input_${code}`
    data.append('identifier', image_identifier);

    const message = {
      body: values?.body || null,
      parent: root.id,
      subject: root.subject,
      source: owner,
      target: chatWith?.id,
      type: 'message',
      json_data: {
        picture: src,
        _id: code,
        deleted_for_source: 0
      }
    }

    // push the message to the array, while sending it, it will have a loading animation
    dispatch({ type: 'addItem', data: { ...message, loading: true} })
    dispatch({type: 'cleanPreview'})
    pictureUpload(data)
    form.reset()
  }
  // ** Once the image uploads it catched by this effect and saved as a message
  useUpdateEffect(() => {
    if(files.files) {
      if (!files.uploading) {
        if(files.error) {
          notify(t(files.error.message))
        }else{
          const sendImage = () => {
            const pseudoMessage = chat.find(e => files.files.name.includes(e?.json_data?._id))
            const message = {
              body: pseudoMessage.body,
              parent: root.id,
              subject: root.subject,
              source: owner,
              target: chatWith?.id,
              type: 'message',
              json_data: {
                _id: pseudoMessage.json_data._id,
                picture: files.files.fileInfo.location,
                deleted_for_source: 0
              }
            }
            // push the message to the array, while sending it, it will have a loading animation
            saveOrUpdate(message)
            pictureClean()
          }
        sendImage()
        }
      }
    }
  }, [files])

  // ** This effect handles scroll behavior when entering the chat. It scroll at the bottom, but if there is messages we haven't read, scrolls to the first unseen message
  useUpdateEffect(() => {
    const chatHTML = document.getElementById('chat')
    const images = Array.prototype.slice.call(chatHTML.querySelectorAll('img'))
    Promise.all(
      images.filter(img => !img.complete).map(img => new Promise(
        resolve => {img.onload = img.onerror = resolve }
      ))).then(() =>{
        const scrollHandler = (chat) => {
          if(!loading) return
          chat = chat.map(message => message.source.id === owner ? 1 : message.readed)
          const element = document.getElementById(`message${chat.indexOf(0)}`)
          if (element) {
            element.scrollIntoView()
          } else {
            chatHTML.scrollTo(0, chatHTML.scrollHeight)
          }
        }
        scrollHandler(chat)
        dispatch({type: 'imageLoadingReady'})
      })
  }, [chat.length])

  // ** This effect catches when a message has been saved. And replace the pseudo message that has been displaying on the chat while the real message was saving
  useUpdateEffect(() => {
    const handleNotifyNewContent = () => {
      if(root.source.id) {
        const isOwnerChat = root.source.id === owner
        const unreadMessages = chat.map(message => message.target.id !== owner ? message.readed : 1).filter(e => !e).length
        if (unreadMessages > 0) {
          const obj = {id : root.id, json_data: {...root.json_data, [`unread_messages_for_${isOwnerChat ? 'target' : 'source'}`] : unreadMessages}}
          saveOrUpdate(obj)
        } 
      }
    }
    if (!message.loading) {
      if (message.error) {
        notify(t(message.error.message));
      } else {
        if (message.item) {
          console.log('MESSAGE REPLACE')
          if (message.item.parent === 'root') {
            dispatch({ type: 'setRoot', data: message.item })
          } else {
            dispatch({ type: 'replaceItemFromApi'})
            // ** It also modify the root element of the chat, to notify a new message on the inbox/sent tray of the other part
            handleNotifyNewContent()
          }
        }
      }
      dispatch({ type: 'seen' })
    }
  }, [message])
  
  return (
    <div className='flex flex-col h-full'>
      <ToastContainer />
      <div className='tabs tabs-boxed p-3 justify-between'>
        <button onClick={closeChat} className='tab'>Cerrar</button>
        {
          (!messages.loading && !loading && !image_loading) &&
          <div className='flex'>
            <p className='text-xs p-2 text-gray-400'>{capitalizePhrase(chatWith?.name)}</p>
            <button onClick={() => !root.json_data.paused && setDeleteMode(!deleteMode)} className='tab px-1'><Icon className="h-3 w-3" name="trash" /></button>
            {(root?.source?.id === owner || root?.source === owner) && <button onClick={() => handlePauseChat()} className='tab px-1'><Icon className="h-3 w-3" name={root?.json_data?.paused ? 'play' : 'pause'} /></button>}
          </div>
        }
      </div>
      <div id='chat' className={`h-96 p-2 md:p-5 relative overflow-scroll overflow-x-hidden scrollbar`}>
        {preview && 
        <div className='flex h-80 pt-10 absolute bg-white justify-center items-center w-full'>
          <p className='absolute top-1 left-8'>Preview</p>
          <button onClick={()=> dispatch({type:'cleanPreview'})}><Icon className="w-5 h-5 absolute top-1 left-0" name='close'/></button>
          <div className='w-36'><img src={preview.src} alt="" /></div>
        </div>}
        
        {(!messages.loading && !loading && !image_loading)  || <div className='flex h-full absolute w-full bg-white'><Loader /></div>}
        {!preview && chat.map((message, i, array) => {
          const date = new Date(message['created_at'])
          // const month = capitalize(date.toLocaleDateString(undefined, { month: 'long' }).substring(0, 3))
          // const day = date.toLocaleDateString(undefined, { day: 'numeric' })
          const time = date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
          return (
            <div key={message.id || 'load' + i} id={`message${i}`} className={`flex flex-col mb-1 ${alignment(message, owner)}`}>
              <span className={`px-4 break-all py-1 items-end flex justify-end rounded-box text-sm ${style(message, owner, array, i)}`}>
                <span className='flex flex-col'>
                  {message?.json_data?.picture && !message?.json_data?.deleted_for_all && <img className='image-message mb-2' src={message.json_data.picture} alt="" />}
                  {message.body}
                </span>

                <span className='text-xs ml-4 break-normal'>
                  {message.loading ?
                    <Loader containerClassName="p-0 pb-1 ml-2 relative" spinnerClassName="text-secondary h-3 w-3" />
                    :
                    (deleteMode ? <button onClick={() => deleteMessage(i, message)}><Icon className="h-3 w-3" name="trash" /></button> : time)}
                </span>
              </span>
              {seen(seenIndex, array, owner, i) && <span className='text-xs px-4 py-1 text-gray-400'>{t('Seen')}</span>}
            </div>
          )
        })}
        {root?.json_data?.paused ? <p className='text-center p-3 text-gray-400'>{t('Conversación pausada')}</p> : null}
      </div>
      <Form
        initialValues={{}}
        onSubmit={preview ? uploadImage : onSubmit}
      >
        {({ handleSubmit, form, values }) => {
          return (
            <FormLayout form={form} onSubmit={handleSubmit}>
              <div className='input flex items-center m-1'>
                  <div className='border-r pr-2'>
                    <Field
                      name="picture"
                      component={AttachImage}
                      placeholder={t('Send an image')}
                      inputOnChange={previewImage}
                      readOnly={messages.loading || root?.json_data?.paused}
                      noError={true}
                    />
                  </div>
                  <Field
                    name="body"
                    component={TextWithIconButtonInput}
                    placeholder={t('Send a message')}
                    readOnly={messages.loading || root?.json_data?.paused}
                    icon={'arrow_right'}
                    inputClassName={'pl-2 ml-2'}
                  />
              </div>
            </FormLayout>
          );
        }}
      </Form>
    </div>
  );
};

export default Chat;