import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { Form, Field } from 'react-final-form';
import { ToastContainer, notify } from '../../../libraries/notifications';
import {
  composeValidators,
  formatOnlyNumberInput,
  optionSelectGenerator,
  validateEmail,
  validateIsfilled
} from '../../../libraries/utils';
// config
import config from '../../../config';
// components
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import FormLayout from '../../../components/forms/FormLayout';
import SelectInput from '../../../components/forms/SelectInput';
import TextInput from '../../../components/forms/TextInput';
import TextWithIconInput from '../../../components/forms/TextWithIconInput';
import ImageUploadFileInput from '../../../components/forms/ImageUploadFileInput';
// context
import usersActions from '../../../context/users/actions';
import rolesActions from '../../../context/roles/actions';
import filesActions from '../../../context/files/actions';
class User extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      creatingNew: false,
      editing: false,
      view: false,
      roles: [],
      showPassword: false,
      showPassword2: false,
      submitting: false,
      user: {}
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Users'), this.t('New')];
    this.titles = this.t('Users');
  }
  // Lifecycle methods
  componentDidMount() {
    const params = this.props.match.params;
    this.getRoles();
    if (params.id) {
      this.breadcrumbs[1] = this.t('Edit');
      this.setState({ view: true });
      this.getUser(params.id);
    } else {
      this.setState({ creatingNew: true });
    }
  }
  componentWillUnmount() {
    this.props.onClearCurrentUser();
  }
  // API calls
  getRoles = async () => {
    await this.props.onGetRoles();
    const { roles } = this.props;
    if (roles.error) {
      notify(this.t(roles.error.message));
    } else {
      this.setState({ roles: [...roles.items] });
    }
  };
  getUser = async id => {
    await this.props.onGetUser({ id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        user: { ...this.props.user.item }
      });
    }
  };
  onSubmit = async values => {
    const { creatingNew } = this.state;
    this.setState({ submitting: true });
    let data = {
      phone: values.phone,
      email: values.email,
      user_type: values.user_type,
      password: values.password,
      profile_image: values.profile_image,
      cover_image: values.cover_image
    };
    // Add names
    if (creatingNew) {
      data.first_name = values.first_name;
      data.last_name = values.last_name;
    } else {
      data.json_data = {
        first_name: values.first_name,
        last_name: values.last_name
      };
    }
    // Add password
    if (!data.password) data.password = '12345';
    if (values.profile_image !== '') {
      let profile_image =
        this.state.files &&
        this.state.files[
          values.profile_image.split('\\')[values.profile_image.split('\\').length - 1]
        ];
      if (profile_image) {
        profile_image.append('identifier', data.id);
        profile_image.append('prefix', 'profile');
        await this.props.onPictureUpload(profile_image);
        const error = this.props.files.error;
        // add profile image
        data.profile_image = this.props.files?.files?.fileInfo?.location;
        if (error) {
          notify(this.t(error?.message));
        }
      }
    }
    if (values.cover_image !== '') {
      let cover_image =
        this.state.files &&
        this.state.files[values.cover_image.split('\\')[values.cover_image.split('\\').length - 1]];
      if (cover_image) {
        cover_image.append('identifier', data.id);
        cover_image.append('prefix', 'cover');
        await this.props.onPictureUpload(cover_image);
        const error = this.props.files.error;
        // Add cover image
        data.cover_image = this.props.files?.files?.fileInfo?.location;
        if (error) {
          notify(this.t(error?.message));
        }
      }
    }
    if (creatingNew) {
      data.role = 'default';
      data.user_type = 'regular';
    }
    await this.props.onSaveUser(data);
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    } else {
      history.push(config.ROUTES.USERS);
    }
  };
  onFileChange = async ({ file, data }) => {
    this.setState({ files: { ...this.state.files, [file]: data } });
  };
  disableEnableUser = async () => {
    const { user } = this.state;
    const data = {
      id: user.id,
      enabled: user.enabled
    };
    await this.props.onSaveUser(data);
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
  };
  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  onPasswordClick2 = () => {
    this.setState({ showPassword2: !this.state.showPassword2 });
  };
  render() {
    const { view, creatingNew, editing, submitting, user } = this.state;
    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.USERS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        },
        {
          onClick: e => {
            if (user.enabled === 1)
              this.setState({ user: { ...user, enabled: 0 } }, () => this.disableEnableUser());
            if (user.enabled === 0)
              this.setState({ user: { ...user, enabled: 1 } }, () => this.disableEnableUser());
          },
          title: this.t(user.enabled ? 'Disable' : 'Enable'),
          icon: !user.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || this.props.user.loading,
          checkPermissions: 'insert',
          visible: !creatingNew
        }
      ]
    };
    // Options
    const userTypeOptions = optionSelectGenerator(config.OPTIONS.USER_TYPES);
    // Form validation
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    // CHECK EMAIL
    const checkEmail = value =>
      validateEmail(value) || (view && !editing) ? undefined : this.t('Not a valid email');
    const validateForm = values => {
      const errors = {};
      errors.first_name = required(values.first_name);
      errors.last_name = required(values.last_name);
      errors.email = checkEmail(values.email);
      errors.phone = required(values.phone);
      if (values.password !== values.password2) errors.password2 = this.t('Passwords must match');
      return errors;
    };
    if (user && user.json_data) {
      user.first_name = user.json_data.first_name;
      user.last_name = user.json_data.last_name;
    }
    // Render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={this.props.user.loading}>
          <Form
            initialValues={Object.assign(user || {}, {
              profile_image: '',
              cover_image: ''
            })}
            onSubmit={this.onSubmit}
            validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={handleSubmit} values={values}>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-1/2 sm:w-3/3 px-2">
                      <Field
                        name="first_name"
                        component={TextInput}
                        label={this.t('Nombre')}
                        readOnly={view && !editing}
                      />
                    </div>
                    <div className="w-1/2 sm:w-3/3 px-2">
                      <Field
                        name="last_name"
                        component={TextInput}
                        label={this.t('Apellido')}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                  <div className="flex -mx-2">
                    <div className="w-full sm:w-1/4 px-2">
                      <Field
                        name="phone"
                        component={TextInput}
                        label={this.t('Phone')}
                        readOnly={view && !editing}
                        parse={v => formatOnlyNumberInput(v)}
                      />
                    </div>
                    <div className="w-full sm:w-2/4 px-2">
                      <Field
                        name="email"
                        component={TextInput}
                        label={this.t('Email')}
                        validate={composeValidators(required, checkEmail)}
                        readOnly={view && !editing}
                      />
                    </div>
                  </div>
                  {creatingNew && (
                    <div className="w-full sm:w-4/6 lg:w-5/12 px-2">
                      <Field
                        name="user_type"
                        component={SelectInput}
                        label={this.t('Tipo de usuario')}
                        options={userTypeOptions}
                        empty={this.t('Select a value')}
                        readOnly={view && !editing}
                      />
                    </div>
                  )}

                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="password"
                      component={TextWithIconInput}
                      placeholder={this.t('Password')}
                      showPassword={this.state.showPassword}
                      clickPassword={this.onPasswordClick}
                      validate={creatingNew && required}
                    />
                  </div>
                  <div className="w-full px-3 mb-10 md:mb-0">
                    <Field
                      name="password2"
                      component={TextWithIconInput}
                      placeholder={this.t('Repite password')}
                      showPassword={this.state.showPassword2}
                      clickPassword={this.onPasswordClick2}
                      validate={creatingNew && required}
                    />
                  </div>
                  {view && (
                    <>
                      <div className="flex flex-wrap -mx-2">
                        <div className="w-full px-2">
                          <Field
                            name="profile_image"
                            component={ImageUploadFileInput}
                            label={this.t('Profile image')}
                            readOnly={view && !editing}
                            inputOnChange={this.onFileChange}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-2">
                        <div className="w-full px-2">
                          <Field
                            name="cover_image"
                            component={ImageUploadFileInput}
                            label={this.t('Cover image')}
                            readOnly={view && !editing}
                            inputOnChange={this.onFileChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    user: state.users.current,
    roles: state.roles.list,
    files: state.files
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUser: id => dispatch(usersActions.get(id)),
    onSaveUser: params => dispatch(usersActions.saveOrUpdate(params)),
    onDelUser: id => dispatch(usersActions.del(id)),
    onClearCurrentUser: () => dispatch(usersActions.clearCurrent()),
    onGetRoles: params => dispatch(rolesActions.getAll(params)),
    onPictureUpload: params => dispatch(filesActions.upload(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(User));
