const types = {
  CLEAR_CURRENT: 'RECEIPTS_CLEAR_CURRENT',
  GET_REQUEST: 'RECEIPTS_GET_REQUEST',
  GET_SUCCESS: 'RECEIPTS_GET_SUCCESS',
  GET_FAILURE: 'RECEIPTS_GET_FAILURE',

  SAVE_REQUEST: 'RECEIPTS_SAVER_REQUEST',
  SAVE_SUCCESS: 'RECEIPTS_SAVE_SUCCESS',
  SAVE_FAILURE: 'RECEIPTS_SAVE_FAILURE',

  GETALL_REQUEST: 'RECEIPTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'RECEIPTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'RECEIPTS_GETALL_FAILURE',

  DELETE_REQUEST: 'RECEIPTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'RECEIPTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'RECEIPTS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'RECEIPTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RECEIPTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RECEIPTS_UPDATE_FAILURE',   

};

export default types;