import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import {
  composeValidators,
  validateEmail,
  validateIsfilled,
} from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutHalfScreen from '../../components/layout/LayoutHalfScreen';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import TextWithIconInput from '../../components/forms/TextWithIconInput';
import Splash from '../../components/commons/Splash';
import logo from '../../assets/img/logo-negative.png';
// import slide0 from '../../assets/img/01.jpg';
// import slide1 from '../../assets/img/02.jpg';
// import slide2 from '../../assets/img/03.jpg';
// import slide3 from '../../assets/img/04.jpg';
// const images = Object.freeze({ slide0, slide1, slide2, slide3 });

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from = (this.props.location &&
      this.props.location.state &&
      this.props.location.state.from) || { pathname: '/' };
    this.state = {
      showPassword: false,
      submitting: false,
      credentials: {},
    };
    this.slide = Math.floor(Math.random() * 3);
  }

  componentDidMount() {
    this.userToken();
  }

  onForgotPassword = async (values) => {
    history.push(config.ROUTES.FORGOT);
  };

  onSubmit = async (values) => {
    this.setState({ submitting: true, credentials: { ...values } });
    await this.props.onLogin({
      ...values,
      user_type: config.USER_TYPE.SUPERADMIN,
    });
    if (this.props.auth.error) {
      this.setState({ submitting: false });
      notify(this.t(this.props.auth.error.message));
    } else {
      if(!this.props.auth.user?.type.includes("superadmin")){
        notify(this.t("No es posible ingresar con las credenciales actuales."));
        await this.props.onLogout();
        this.setState({ submitting: false });
        //history.push(config.ROUTES.HOME);
        return
      }
      history.push(this.from.pathname); //config.ROUTES.HOME
    }
  };

  userToken = async () => {
    const token = JSON.parse(
      localStorage.getItem(config.LOCALSTORAGE_USER_TOKEN)
    );
    if (!this.props.auth.logged && token) {
      await this.props.onGetToken();
      if (this.props.auth.error) {
        await this.props.onLogout();
        history.push(config.ROUTES.HOME);
      } else {
        history.push(this.from.pathname); //config.ROUTES.HOME
      }
    }
  };

  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onSignup = () => {
    history.push(config.ROUTES.SIGNUP);
  };

  render() {
    if (
      !this.props.auth.logged &&
      this.props.auth.loading &&
      !this.state.submitting
    )
      return <Splash />;

    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const email = (value) =>
      validateEmail(value) ? undefined : this.t('Not a valid email');

    return (
      <LayoutHalfScreen
        side={{
          className: 'bg-primary flex items-center h-full justify-center',
          imageClassName: ' ',
          image: logo /*images[`slide${this.slide}`]*/,
        }}
        // header={{ logo: true }}
      >
        <ToastContainer />
        <p className="mt-10 text-center text-3xl">{this.t('Welcome')}</p>
        <h2 className="h2 text-primary-100 text-center mb-20">
          {this.t('Log in')}
        </h2>
        <Form
          initialValues={this.state.credentials || {}}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              onSubmit={handleSubmit}
              className="w-full max-w-lg mx-auto px-4"
            >
              <div className="w-full mb-3 md:mb-0">
                <Field
                  name="email"
                  component={TextInput}
                  placeholder={this.t('Email')}
                  validate={composeValidators(required, email)}
                />
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field
                  name="password"
                  component={TextWithIconInput}
                  placeholder={this.t('Password')}
                  showPassword={this.state.showPassword}
                  clickPassword={this.onPasswordClick}
                  validate={required}
                />
              </div>
              <div className="flex flex-wrap mb-3">
                <Button
                  className="btn-link ml-auto mb-10 text-sm"
                  title={this.t('Forgot your password?')}
                  onClick={this.onForgotPassword}
                />
                <Button
                  className="btn-block btn-primary"
                  title={this.t('Log in')}
                  onClick={handleSubmit}
                  disabled={this.state.submitting}
                />
              </div>
            </form>
          )}
        </Form>
        {/* <div className="mt-20 p-4 flex justify-center items-center">
          <p className="text-primary-100 text-center mr-3">{this.t("Does not have an account?")}</p>
          <Button
              className="btn btn-link mb-0 underline"
              title={this.t("Register")}
              onClick={this.onSignup}
            />
        </div> */}
      </LayoutHalfScreen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (values) => dispatch(userActions.login(values)),
    onLogout: () => dispatch(userActions.logout()),
    onGetToken: () => dispatch(userActions.getFromToken()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login));
