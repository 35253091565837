import config from './routes';

const customs = {
  COMPANY: {
    SHORT_NAME: 'Regalale Bitcoins',
    LONG_NAME: 'Regalale Bitcoins'
  },
  SUPPORTED_LANGUAGES: ['en', 'es'],
  DEFAULT_LANGUAGE: 'es',
  MONEY_SYMBOL: '$',
  MONEY_CODE: 'u$s',
  MONEY_POSITION: 'prefix', //'sufix'
  MONEY_SPACE: true,
  CURRENCIES: ['USD', 'ARS'],
  CURRENCY: 'ARS',
  LOCALE: 'es-ES',
  USER_TYPE: {
    REGULAR: 'regular',
    SUPERADMIN: 'superadmin',
  },
  USER_ACCOUNTS_INTEGRATED: true,
  USER_ACCOUNTS: {
    TYPES: {
      OWNER: 'owner',
      GUEST: 'guest'
    },
    STATUS: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
      REVOKED: 'revoked'
    }
  },
  OWNER: {
    SYSTEM: 'system'
  },
  TYPES: {
    MESSAGES: {
      COMMENTS: 'comments'
    },
    CATEGORIES: {
      DISCOUNTS: 'discounts'
    },
    TRANSACTIONS: {
      MERCADO_PAGO: 'mercado_pago',
      BINANCE: 'binance',
      DISCOUNT: 'discount'
    }
  },
  STATUS: {
    TRANSACTIONS: {
      REDEEMED: "redeemed",
      COMPLETED: "completed",
    },
  },
  TRANSACTIONS_TYPES: [
    { label: 'Mercado Pago', value: 'mercado_pago' },
    { label: 'Binance', value: 'binance' },

  ],
  ROLES: {
    ADMIN: 'admin',
    BENEFITS: 'benefits',
    INITIATIVES: 'initiatives',
    COMPANIES: 'companies',
    DISCOUNTS: 'discounts'
  },
  TRANSACTIONS: {
    BOOK: {
      TYPE: 'book',
      SUB_TYPE: {
        VIDEO: 'video',
        VISIT: 'visit',
        CALL: 'call'
      },
      STATUS: {
        BOOKED: 'booked',
        CANCELED: 'canceled',
        REVIEW: 'review',
        COMPLETED: 'completed'
      }
    },
    PURCHASE: {
      TYPE: 'book',
      SUB_TYPE: {
        PHARMACY: 'pharmacy'
      },
      STATUS: {
        BOOKED: 'added',
        CANCELED: 'canceled',
        REVIEW: 'review',
        COMPLETED: 'completed'
      }
    },
    DISCOUNT: {
      TYPE:'discount',
      SUB_TYPE: {
        USER:'user',
        BUSINESS:'business',
      },
      STATUS:{
        GENERATED: 'generated',
        CONSUMED: 'consumed',
        CANCELED: 'canceled',
      },
    },
  },
  DISCOUNTS: {
    DISCOUNT_TYPES: [
      {label: 'porcentaje', value: 1},
      {label: 'monto', value: 0}
    ],
    TYPE: 'btc',
  },
  OPTIONS: {
    ROLE_TYPES: ['security', 'group'],
    PERMISSIONS: {
      OBJECT_TYPE: ['object example 1', 'object example 2', 'object example 3'],
      ACTION_TYPE: ['action example 1', 'action example 2', 'action example 3']
    },
    PRESCRIPTIONS: ['prescription'],
    PRODUCTS: ['goods', 'services'],
    USER_TYPES: ['regular', 'advanced', 'patient', 'professional', 'pharmacy', 'benefits', 'companies', 'initiatives'],
  },
  SIDEBAR: {
    MENU: {
      MAIN: [{ icon: 'home', label: 'Dashboard', route: config.ROUTES.HOME }],
      SECONDARY: [],
      ADMIN: [
        {
          icon: 'cog',
          label: 'Settings',
          route: config.ROUTES.MY_SETTINGS
        },
        {
          icon: 'switch_horizontal',
          label: 'Operaciones',
          route: config.ROUTES.TRANSACTIONS
        },
        {
          icon: 'switch_horizontal',
          label: 'Transferencias',
          route: config.ROUTES.TRANSACTIONS_TRANSFERS
        },
        {
          icon: 'dolar',
          label: 'Descuentos',
          route: config.ROUTES.DISCOUNTS
        },
        { icon: 'user_group', label: 'Usuarios', route: config.ROUTES.USERS },

      ],
      EXTRAS: [
        { label: 'Information' },
        { icon: 'help', label: 'How works?', route: config.ROUTES.HOW_WORKS },
        { icon: 'phone', label: 'Contact us', route: config.ROUTES.CONTACT_US },
        { icon: '', label: 'Terms and conditions', route: config.ROUTES.TERMS }
      ]
    }
  },
  SLIDER: {
    SHOW: false,
    CONTENT: []
  },
  EVENT_NAME_CODES: [
    {title: 'cumpleaños',   code: "birthday"},
    {title: 'casamiento',   code: "wedding"},
    {title: 'aniversario',  code: "anniversary"},
    {title: 'bautismo',     code: "baptism"},
    {title: 'confirmación', code: "confirmation"},
    {title: 'san valentín', code: "valentines"},
    {title: 'graduación',   code: "graduation"},
    {title: 'mudanza',      code: "move"},
    {title: 'nacimiento',   code: "birth"},
    {title: 'comunión',     code: "communion"},
    {title: 'fiesta',       code: "party"},
    {title: 'navidad',      code: "christmas"},
    {title: 'reyes',        code: "threekings"},
    {title: 'regalo',        code: "bitcoin"},
  ],
};
export default customs;
