const types = {
  CLEAR_CURRENT: 'PRESCRIPTIONS_CLEAR_CURRENT',
  GET_REQUEST: 'PRESCRIPTIONS_GET_REQUEST',
  GET_SUCCESS: 'PRESCRIPTIONS_GET_SUCCESS',
  GET_FAILURE: 'PRESCRIPTIONS_GET_FAILURE',

  SAVE_REQUEST: 'PRESCRIPTIONS_SAVE_REQUEST',
  SAVE_SUCCESS: 'PRESCRIPTIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'PRESCRIPTIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'PRESCRIPTIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PRESCRIPTIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PRESCRIPTIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'PRESCRIPTIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PRESCRIPTIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PRESCRIPTIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'PRESCRIPTIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PRESCRIPTIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PRESCRIPTIONS_UPDATE_FAILURE',
  
  UPDATE_MANY_REQUEST: 'PRESCRIPTIONS_UPDATE_MANY_REQUEST',
  UPDATE_MANY_SUCCESS: 'PRESCRIPTIONS_UPDATE_MANY_SUCCESS',
  UPDATE_MANY_FAILURE: 'PRESCRIPTIONS_UPDATE_MANY_FAILURE',

};

export default types;