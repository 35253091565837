import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import config from '../../config/';

import { useSelector } from 'react-redux';
import { permissionsCheck } from '../../libraries//utils';

const PrivateButton = ({ children, ...rest }) => {
  const logged = useSelector((state) => state.users.auth.logged);
  const userType = useSelector(state => state.users.auth.user?.type[0]);
  const permissions = useSelector((state) => state.users.auth.user.permissions);
  const location = useLocation();
  const access = () => {
    return permissionsCheck(location.pathname, userType, permissions, rest.control);
  };

  if (!rest.control || typeof rest.control === 'undefined') return children;

  return logged ? (
    access() ? (
      children
    ) : null
  ) : (
    <Redirect
      to={{ pathname: config.ROUTES.LOGIN, state: { from: location.pathname } }}
    />
  );
};

export default PrivateButton;
