import config from '../../config';
import { request } from '../../libraries/request'
import { isDate } from '../../libraries/utils';

const searchPayment = (id, token) => {
  console.log('SERVICES::VEHICLES::get', id, token);
  return request({
    url: config.BASE_API_URL + "/payments/search",
    accessToken: token,
    method: 'POST',
    data: {external_reference: id},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}

const capturePayment = (data, token) => {
  return request({
    url: config.BASE_API_URL + "/payments/capture-order",
    accessToken: token,
    method: 'POST',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}


const services = {
  searchPayment,
  capturePayment,
};

export default services;