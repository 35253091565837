const types = {
  CLEAR_CURRENT: 'BOOKINGS_CLEAR_CURRENT',
  GET_REQUEST: 'BOOKINGS_GET_REQUEST',
  GET_SUCCESS: 'BOOKINGS_GET_SUCCESS',
  GET_FAILURE: 'BOOKINGS_GET_FAILURE',

  SAVE_REQUEST: 'BOOKINGS_SAVE_REQUEST',
  SAVE_SUCCESS: 'BOOKINGS_SAVE_SUCCESS',
  SAVE_FAILURE: 'BOOKINGS_SAVE_FAILURE',

  GETALL_REQUEST: 'BOOKINGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'BOOKINGS_GETALL_SUCCESS',
  GETALL_FAILURE: 'BOOKINGS_GETALL_FAILURE',

  DELETE_REQUEST: 'BOOKINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'BOOKINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'BOOKINGS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'BOOKINGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'BOOKINGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'BOOKINGS_UPDATE_FAILURE',

  AVAILABILITY_REQUEST: 'BOOKINGS_AVAILABILITY_GET_REQUEST',
  AVAILABILITY_SUCCESS: 'BOOKINGS_AVAILABILITY_GET_SUCCESS',
  AVAILABILITY_FAILURE: 'BOOKINGS_AVAILABILITY_GET_FAILURE',

  CLEAR_SLOTS_CURRENT: 'BOOKINGS_SLOTS_CLEAR_CURRENT',
  GETALL_SLOTS_REQUEST: 'BOOKINGS_SLOTS_GETALL_REQUEST',
  GETALL_SLOTS_SUCCESS: 'BOOKINGS_SLOTS_GETALL_SUCCESS',
  GETALL_SLOTS_FAILURE: 'BOOKINGS_SLOTS_GETALL_FAILURE',

  GET_SLOTS_REQUEST: 'BOOKINGS_SLOTS_GET_REQUEST',
  GET_SLOTS_SUCCESS: 'BOOKINGS_SLOTS_GET_SUCCESS',
  GET_SLOTS_FAILURE: 'BOOKINGS_SLOTS_GET_FAILURE',

  SAVE_SLOTS_REQUEST: 'BOOKINGS_SLOTS_SAVE_REQUEST',
  SAVE_SLOTS_SUCCESS: 'BOOKINGS_SLOTS_SAVE_SUCCESS',
  SAVE_SLOTS_FAILURE: 'BOOKINGS_SLOTS_SAVE_FAILURE',

  DELETE_SLOTS_REQUEST: 'BOOKINGS_SLOTS_DELETE_REQUEST',
  DELETE_SLOTS_SUCCESS: 'BOOKINGS_SLOTS_DELETE_SUCCESS',
  DELETE_SLOTS_FAILURE: 'BOOKINGS_SLOTS_DELETE_FAILURE',    

  UPDATE_SLOTS_REQUEST: 'BOOKINGS_SLOTS_UPDATE_REQUEST',
  UPDATE_SLOTS_SUCCESS: 'BOOKINGS_SLOTS_UPDATE_SUCCESS',
  UPDATE_SLOTS_FAILURE: 'BOOKINGS_SLOTS_UPDATE_FAILURE',

  UPDATEALL_SLOTS_REQUEST: 'BOOKINGS_SLOTS_UPDATEALL_REQUEST',
  UPDATEALL_SLOTS_SUCCESS: 'BOOKINGS_SLOTS_UPDATEALL_SUCCESS',
  UPDATEALL_SLOTS_FAILURE: 'BOOKINGS_SLOTS_UPDATEALL_FAILURE',


  CLEAR_INACTIVITY_CURRENT: 'BOOKINGS_INACTIVITY_CLEAR_CURRENT',
  SET_INACTIVITY_ARRAY: 'BOOKINGS_SET_INACTIVITY_ARRAY',
  GETALL_INACTIVITY_REQUEST: 'BOOKINGS_INACTIVITY_GETALL_REQUEST',
  GETALL_INACTIVITY_SUCCESS: 'BOOKINGS_INACTIVITY_GETALL_SUCCESS',
  GETALL_INACTIVITY_FAILURE: 'BOOKINGS_INACTIVITY_GETALL_FAILURE',

  GET_INACTIVITY_REQUEST: 'BOOKINGS_INACTIVITY_GET_REQUEST',
  GET_INACTIVITY_SUCCESS: 'BOOKINGS_INACTIVITY_GET_SUCCESS',
  GET_INACTIVITY_FAILURE: 'BOOKINGS_INACTIVITY_GET_FAILURE',

  SAVE_INACTIVITY_REQUEST: 'BOOKINGS_INACTIVITY_SAVE_REQUEST',
  SAVE_INACTIVITY_SUCCESS: 'BOOKINGS_INACTIVITY_SAVE_SUCCESS',
  SAVE_INACTIVITY_FAILURE: 'BOOKINGS_INACTIVITY_SAVE_FAILURE',

  DELETE_INACTIVITY_REQUEST: 'BOOKINGS_INACTIVITY_DELETE_REQUEST',
  DELETE_INACTIVITY_SUCCESS: 'BOOKINGS_INACTIVITY_DELETE_SUCCESS',
  DELETE_INACTIVITY_FAILURE: 'BOOKINGS_INACTIVITY_DELETE_FAILURE',    

  UPDATE_INACTIVITY_REQUEST: 'BOOKINGS_INACTIVITY_UPDATE_REQUEST',
  UPDATE_INACTIVITY_SUCCESS: 'BOOKINGS_INACTIVITY_UPDATE_SUCCESS',
  UPDATE_INACTIVITY_FAILURE: 'BOOKINGS_INACTIVITY_UPDATE_FAILURE',

};

export default types;