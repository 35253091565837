import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../libraries//icons';

export default function MenuItem({ item, location, ...rest }) {
  const { t } = useTranslation();
  if (!item) return null;
  const { icon, label, route, action } = item;
  const isSelected = (route, location) => {
    return location.pathname === route;
  };
  return route ? (
    <li>
      <Link
        className={'' + (isSelected(route, location) ? 'selected' : '')}
        to={route}
      >
        {icon && <Icon className="h-6 w-6 mr-2 text-primary" name={icon} />}
        {t(label)}
      </Link>
    </li>
  ) : action ? (
    <li>
      <span className={''} onClick={action ? action : () => {}}>
        {icon && <Icon className="h-6 w-6 mr-2 text-primary" name={icon} />}
        {t(label)}
      </span>
    </li>
  ) : (
    // Only a title
    <li>
      <p className="text-xs text-gray-500 uppercase px-3">{t(label)}</p>
    </li>
  );
}
