import types from './types';
import bookings from './services';

import actionsSlots from './actions.slots';


const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  }
}
const setInactivity = array => ({
  type: types.SET_INACTIVITY_ARRAY,
  array
})

const getAll = (params) => {

  function request() { return { type: types.GETALL_REQUEST } }
  function success(payload) { return { type: types.GETALL_SUCCESS, payload } }
  function failure(error) { return { type: types.GETALL_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::BOOKINGS::getAll', params);
    dispatch(request());
    const response = await bookings.getAll(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS::getAll::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };  

}

const get = (params) => {
  console.log('ACTIONS::BOOKINGS::get', params);

  const request = (params) => { return { type: types.GET_REQUEST, params } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(params));
    let response = await bookings.get(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS::get::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}

const saveOrUpdate = (params) => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::BOOKINGS::saveOrUpdate', params);
    dispatch(request(params));

    let response;
    if (params.id) {  // editing a existing record
      response = await bookings.update(params, getState().users.auth.token)
    } else {
      response = await bookings.save(params, getState().users.auth.token)
    }
    console.log('ACTIONS::BOOKINGS::saveOrUpdate::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}
const saveOrUpdateInactivity = (params) => {

  function request(payload) { return { type: types.SAVE_INACTIVITY_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_INACTIVITY_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_INACTIVITY_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::BOOKINGS::saveOrUpdateInactivity', params);
    dispatch(request(params));

    let response;
    if (params.data.edited) {  // editing a existing record
      delete params.data.edited
      delete params.data.uid
      params.id = params.data.id
      console.log('EDITING', params)
      response = await bookings.updateInactivity(params, getState().users.auth.token)
    } else {
      console.log('SAVING', params)
      response = await bookings.saveInactivity(params, getState().users.auth.token)
    }
    console.log('ACTIONS::BOOKINGS::saveOrUpdate::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const del = (params) => {
  console.log('ACTIONS::BOOKINGS::del', params);
 
  function request(params) { return { type: types.DELETE_REQUEST, params } }
  function success(params) { return { type: types.DELETE_SUCCESS, params } }
  function failure(params, error) { return { type: types.DELETE_FAILURE, params, error } }

  return async (dispatch, getState) => {
    dispatch(request(params));
    let response = await bookings.del(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS::del::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(params));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}
const delInactivity = (params) => {
  console.log('ACTIONS::BOOKINGS::delInactivity', params);
 
  function request(params) { return { type: types.DELETE_INACTIVITY_REQUEST, params } }
  function success(params) { return { type: types.DELETE_INACTIVITY_SUCCESS, params } }
  function failure(params, error) { return { type: types.DELETE_INACTIVITY_FAILURE, params, error } }

  return async (dispatch, getState) => {
    dispatch(request(params));
    let response = await bookings.delInactivity(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS::delInactivity::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(params));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}


const getInactivities = (params) => {
  console.log('ACTIONS::BOOKINGS::getAvailability', params);

  const request = (params) => { return { type: types.GETALL_INACTIVITY_REQUEST, params } };
  const success = (payload) => { return { type: types.GETALL_INACTIVITY_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GETALL_INACTIVITY_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(params));
    let response = await bookings.getInactivities(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS::getInactivities::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}
const getAvailability = (params) => {
  console.log('ACTIONS::BOOKINGS::getAvailability', params);

  const request = (params) => { return { type: types.AVAILABILITY_REQUEST, params } };
  const success = (payload) => { return { type: types.AVAILABILITY_SUCCESS, payload } }
  const failure = (error) => { return { type: types.AVAILABILITY_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(params));
    let response = await bookings.getAvailability(params, getState().users.auth.token)
    console.log('ACTIONS::BOOKINGS::getAvailability::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}


// const run = (action, params) => {

//   function request(params) { return { type: types[`${action}_REQUEST`], params } }
//   function success(payload) { return { type: types[`${action}_SUCCESS`], payload } }
//   function failure(error) { return { type: types[`${action}_FAILURE`], error } }

//   return async (dispatch, getState) => {
//     console.log('ACTIONS::BOOKINGS::', action, params);
//     dispatch(request(params));
//     const response = await bookings.run(action, params, getState().users.auth.token)
//     console.log('ACTIONS::BOOKINGS::',action,'::RESPONSE FROM SERVICE', response);
//     if (response.success) {
//       dispatch(success(response.data));
//     } else {
//       dispatch(failure(response.error));
//     }

//   };  

// }


const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  saveOrUpdateInactivity,
  del,
  delInactivity,
  getAvailability,
  getInactivities,
  setInactivity,
  slots: actionsSlots,
};

export default actions;