import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize, isEmptyObject, validateIsfilled, selectGeneratorWObjChild,
  formatOnlyNumberInput, replaceNewLines, validateURL
} from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import discountsActions from '../../../context/discounts/actions';
import filesActions from '../../../context/files/actions';
import usersActions from '../../../context/users/actions';
import settingsActions from '../../../context/settings/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import SelectInput from '../../../components/forms/SelectInput';
import IntegerInput from '../../../components/forms/IntegerInput';
import TextareaInput from '../../../components/forms/TextareaInput';
import DatepickerInput from '../../../components/forms/DatepickerInput';
import CheckboxInput from '../../../components/forms/CheckboxInput';
import arrayMutators from 'final-form-arrays';
import { WhenFieldChangesSetAnotherField } from '../../../libraries/forms';
import { format } from 'date-fns';

class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      discountCategories: [],
      imageUpdated: false,
      creatingNew: false,
      editing: false,
      loading: false,
      remove: false,
      setting: null,
      submitting: false,
      titles: '',
      view: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Discounts'), this.t('New')];
    this.titles = this.t('Discount details');
  }
  // ** Life cycle
  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit discount')
      });
      this.breadcrumbs = [this.t('Discounts'), this.t('Edit')];
      this.getDiscount(params.id);
    } else {
      let discount = { days_of_week: { l: true, m: true, x: true, j: true, v: true, s: true, d: true }}
      this.setState({ titles: this.t('New Discount'), creatingNew: true, discount });
    } 
  }

  getDiscount = async id => {
    this.setState({ loading: true });
    await this.props.onGetDiscount(id);
    const error = this.props.discount.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      let discount = {...this.props.discount.item, ...this.props.discount.item.json_data, ...this.props.discount.item.json_data?.rules}
      discount.unlimited_group_uses = discount.uses === 0 //fixme: cambiarle el nombre a esto      
      this.setState({
        discount,
        files: { picture: this.props.discount.item.picture },
        loading: this.props.discount.loading
      });
    }
    this.setState({ loading: false });
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  goBack = () => {
    history.push(config.ROUTES.DISCOUNTS);
  };

  onSubmit = async (values) => {
    let code = '';
    if (values.code) {
      code = values.code
    } else {
      code = 'CODETEST' // generar el codigo FIXME: Crear un código único checkeable
    }

    // format() no acepta fechas como string y así es como se guardan en la db
    // por eso tengo que asegurarme de que la fecha esta como una Date antes de formatearla
    // sino tendria que hacer format(parseISO(fechaString)) pero eso no sirve cuando estoy
    // creando un nuevo registro xq el formulario si me da una Date y asi el parseISO se rompe 
    values.start_date = new Date(values.start_date);
    values.end_date = new Date(values.end_date);

    let detail = values.detail ? values.detail : "" 
    // generar objeto data
    const data = {
      type: config.DISCOUNTS.TYPE,
      name: values.name,
      detail: replaceNewLines(values.detail),
      owner: 'system',
      code,
      pack: "",
      //enabled: this.state.discount?.enabled || 1,
      percentage: values['percentage'],
      value: values.value,
      uses: parseInt(values.uses),
      start_date: format(values.start_date, 'yyyy/MM/dd'),
      end_date: format(values.end_date, 'yyyy/MM/dd'),
      json_data: { // Dinámica para canjear descuento en planilla
        rules: {
          days_of_week: values.days_of_week
        }
      }
    };

    // agregar id para que se edite
    if (values.id) {
      data.id = values.id;
    }

    console.log("DATA",data)

    await this.props.onSaveOrUpdate({ ...data });
    const discount = this.props.discount;
    if (discount.error) {
      notify(this.t(discount.error.message));
    } else {
      this.goBack();
    }
  };

  disableEnableDiscount = async () => {
    this.setState({loading: true})
    const { discount } = this.state;
    const data = { id: discount.id, enabled: discount.enabled };
    await this.props.onSaveOrUpdate(data);
    const error = this.props.discount.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    }
    this.setState({loading: false})
  };

  render() {
    const { discountCategories, creatingNew, view, editing, submitting, discount, loading } = this.state;
    const discountTypeOptions = selectGeneratorWObjChild(config.DISCOUNTS.DISCOUNT_TYPES, 'value', 'label');

    // Layout actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.DISCOUNTS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },

        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        },
        {
          onClick: e => {
            if (discount.enabled === 1)
              this.setState({ discount: { ...discount, enabled: 0 } }, () => this.disableEnableDiscount());
            if (discount.enabled === 0)
              this.setState({ discount: { ...discount, enabled: 1 } }, () => this.disableEnableDiscount());
          },
          title: this.t(discount?.enabled ? 'Disable' : 'Enable'),
          icon: !discount?.enabled ? 'view_show' : 'view_hide',
          disabled: submitting || this.props.discount.loading,
          checkPermissions: 'enable',
          visible: !creatingNew
        }
      ]
    };

    // ** Form validation functions
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');

    const validateForm = values => {
      const errors = {};
      errors.name = required(values.name);
      return errors;
    };
    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={loading}>
          <Form
            initialValues={discount}//
            onSubmit={this.onSubmit}
            mutators={{
              ...arrayMutators
            }}
            validate={validateForm}>
            {({ handleSubmit, form, submitting, pristine, values, form: { mutators: { push, pop } }}) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="xl:grid xl:grid-cols-12 xl:grid-rows-1">
                    <Field
                      name="name"
                      component={TextInput}
                      placeholder={capitalize(this.t('name of discount'))}
                      label={capitalize(this.t('name of discount'))}
                      readOnly={view && !editing}
                      className="px-3 mb-3 md:mb-0 xl:col-span-10"
                      validate={required}
                    />
                  </div>
                  <Field
                    name="detail"
                    component={TextareaInput}
                    placeholder={capitalize(this.t('details'))}
                    label={capitalize(this.t('details'))}
                    readOnly={view && !editing}
                    className="px-3 mb-3 md:mb-0"
                    validate={required}
                  />
                  <Field
                    name="code"
                    component={TextInput}
                    placeholder={capitalize(this.t('code'))}
                    label={capitalize(this.t('code'))}
                    readOnly={(view && !editing) || editing}
                    className="xl:col-span-4 px-3 mb-3 md:mb-0"
                    validate={required}
                  />
                  <div className='flex flex-wrap'>
                    <hr />
                    <div className='md:w-1/2'>
                      <h2 className="text-xl px-3 mb-3">{capitalize(this.t('discount usage rules'))}</h2>
                      <h3 className="px-3 mb-3 text-lg ml-3">{capitalize(this.t('cantidad total'))}:</h3>
                      <div className="flex ml-8">
                        <Field
                          name="unlimited_group_uses"
                          component={CheckboxInput}
                          placeholder={capitalize(this.t('unlimited'))}
                          label={capitalize(this.t('unlimited'))}
                          readOnly={view && !editing}
                          type="checkbox"
                          className="mr-8"
                        />
                        <Field
                          name="uses"
                          component={IntegerInput}
                          placeholder={capitalize(this.t('uses'))}
                          label={capitalize(this.t('usos totales'))}
                          parse={v => formatOnlyNumberInput(v, 7)}
                          readOnly={view && !editing}
                          disabled={values.unlimited_group_uses === true}
                          className="mr-4"
                        />
                        <WhenFieldChangesSetAnotherField
                          set="uses"
                          field="unlimited_group_uses"
                          becomes="1"
                          to="0"
                        />
                      </div>
                    </div>
                    <hr />
                    <div className='md:w-1/2'>
                      <h2 className="text-xl px-3 mb-3">{capitalize(this.t('discount availability period'))}</h2>
                      <div className="flex ml-8">
                        <Field
                          name="start_date"
                          component={DatepickerInput}
                          placeholder={this.t('Start date')}
                          label={this.t('Start date')}
                          readOnly={view && !editing}
                          className="pr-3"
                        />
                        <Field
                          name="end_date"
                          component={DatepickerInput}
                          placeholder={this.t('End date')}
                          label={this.t('End date')}
                          readOnly={view && !editing}
                        />
                      </div>
                      <h3 className="px-3 mb-3 text-lg ml-3">{capitalize(this.t('days where it is available'))}</h3>
                      <div className="flex flex-wrap ml-8">
                        <Field
                          name="days_of_week.l"
                          component={CheckboxInput}
                          placeholder={this.t('Monday')}
                          label={this.t('Monday')}
                          readOnly={view && !editing}
                          type="checkbox"
                          className='w-fit p-2'
                          inputClassName='flex justify-center'
                        />
                        <Field
                          name="days_of_week.m"
                          component={CheckboxInput}
                          placeholder={this.t('Tuesday')}
                          label={this.t('Tuesday')}
                          readOnly={view && !editing}
                          type="checkbox"
                          className='w-fit p-2'
                          inputClassName='flex justify-center'
                        />
                        <Field
                          name="days_of_week.x"
                          component={CheckboxInput}
                          placeholder={this.t('Wednesday')}
                          label={this.t('Wednesday')}
                          readOnly={view && !editing}
                          type="checkbox"
                          className='w-fit p-2'
                          inputClassName='flex justify-center'
                        />
                        <Field
                          name="days_of_week.j"
                          component={CheckboxInput}
                          placeholder={this.t('Thursday')}
                          label={this.t('Thursday')}
                          readOnly={view && !editing}
                          type="checkbox"
                          className='w-fit p-2'
                          inputClassName='flex justify-center'
                        />
                        <Field
                          name="days_of_week.v"
                          component={CheckboxInput}
                          placeholder={this.t('Friday')}
                          label={this.t('Friday')}
                          readOnly={view && !editing}
                          type="checkbox"
                          className='w-fit p-2'
                          inputClassName='flex justify-center'
                        />
                        <Field
                          name="days_of_week.s"
                          component={CheckboxInput}
                          placeholder={this.t('Saturday')}
                          label={this.t('Saturday')}
                          readOnly={view && !editing}
                          type="checkbox"
                          className='w-fit p-2'
                          inputClassName='flex justify-center'
                        />
                        <Field
                          name="days_of_week.d"
                          component={CheckboxInput}
                          placeholder={this.t('Sunday')}
                          label={this.t('Sunday')}
                          readOnly={view && !editing}
                          type="checkbox"
                          className='w-fit p-2'
                          inputClassName='flex justify-center'
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <h2 className="text-xl mb-1 px-3 mr-3">{capitalize(this.t('discount'))}</h2>
                  </div>
                    <div className="flex w-1/8 ml-5 items-center">
                      <Field
                        name={`percentage`}
                        component={SelectInput}
                        options={discountTypeOptions}
                        placeholder={capitalize(this.t('selecciona un tipo de descuento'))}
                        label={capitalize(this.t('tipo de descuento'))}
                        empty={this.t('Selecciona un tipo de descuento')}
                        className="mt-3"
                        validate={required}
                        readOnly={view && !editing}
                      />
                      <Field
                        name={`value`}
                        component={TextInput}
                        placeholder={capitalize(this.t('value'))}
                        label={capitalize(this.t('value'))}
                        readOnly={view && !editing}
                        className="ml-4"
                        parse={v => formatOnlyNumberInput(v, 3)}
                        validate={required}
                      />
                    </div>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    discount: state.discounts.current,
    user: state.users.current,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(discountsActions.saveOrUpdate(params)),
    onGetDiscount: id => dispatch(discountsActions.get(id)),
    onGetUser: params => dispatch(usersActions.get(params)),
    onClearCurrent: () => dispatch(discountsActions.clearCurrent()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Discount));
