import config from '../../config';
import { request } from '../../libraries/request'
import { isJSON } from '../../libraries/utils';

const getAll = (params = {}, token) => {
  console.log('SERVICES::BOOKINGS::get', params, token);
  if (params && params.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) { 
    params.order_by = 'created_at';
    params.order_direction = 'DESC';
  }

  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.ROOT,
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  })
}

const save = (params, token) => {
  console.log('SERVICES::BOOKINGS::save', params, token);
  params.starting_at = params.starting_at?.toISOString().substring(0,10);
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.ROOT,
    accessToken: token,
    method: 'POST',
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}
const saveInactivity = (params, token) => {
  console.log('SERVICES::BOOKINGS_INACTIVITIES::save', params, token);
  delete params.data.uid
  params.data.start_date = params.data.start_date?.toISOString().substring(0,10)
  params.data.end_date = params.data.end_date?.toISOString().substring(0,10)
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.INACTIVITY.ROOT.replace('{booking}', params.booking),
    accessToken: token,
    method: 'POST',
    data: params.data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const update = (params, token) => {
  console.log('SERVICES::BOOKINGS::update', params, token);
  params.starting_at = params.starting_at?.toISOString().substring(0,10);
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.ONE.replace('{id}', params.id),
    accessToken: token,
    method: 'PATCH',
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}
const updateInactivity = (params, token) => {
  delete params.data.id
  delete params.data.booking
  console.log('SERVICES::BOOKINGS::updateInactivity', params, token);
  params.data.start_date = params.data.start_date?.toISOString().substring(0,10);
  params.data.end_date = params.data.end_date?.toISOString().substring(0,10);
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.INACTIVITY.ONE.replace('{booking}', params.booking).replace('{id}', params.id),
    accessToken: token,
    method: 'PATCH',
    data: params.data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const get = (params, token) => {
  console.log('SERVICES::BOOKINGS::get', params, token);
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.ONE.replace('{id}', params.id),
    accessToken: token,
 })
  .then(response => {
    console.log('RETURN FROM API', response);
    const {data} = response.data
    if (response.success) {
      if(isJSON(data.json_data.time_ranges)){
        data.json_data.time_ranges = JSON.parse(data.json_data.time_ranges)
      }
      data.starting_at = new Date(data.starting_at);
    }
    return response;
  })

}

const del = (id, token) => {
  console.log('SERVICES::BOOKINGS::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.ONE.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const delInactivity = (params, token) => {
  console.log('SERVICES::BOOKINGS_INACTIVITY::remove', params.id, params.booking, token);
  console.log('REQUEST URL', config.BASE_API_URL + config.API.BOOKINGS.INACTIVITY.ONE.replace('{booking}', params.booking).replace('{id}', params.id))
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.INACTIVITY.ONE.replace('{booking}', params.booking).replace('{id}', params.id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const getAvailability = (params, token) => {
  console.log('SERVICES::BOOKINGS::getAvailability', params, token);
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.AVAILABILITY.replace('{owner}', params.owner),
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}
const getInactivities = (params, token) => {
  console.log('SERVICES::BOOKINGS::getAvailability', params, token);
  return request({
    url: config.BASE_API_URL + config.API.BOOKINGS.INACTIVITY.ROOT.replace('{booking}', params.id),
    accessToken: token,
    data: params,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if(response.data.data) {
      const {data :  {data: items}, data} = response
      if (response.success) {
        data.data = items.map(e => ({...e, start_date: new Date(e.start_date), end_date: new Date(e.end_date)}))
      }
    }
    return response;
  })

}


// const run = (action, params, token) => {
//   console.log('SERVICES::BOOKINGS::', action, params, token);
//   let route;
//   if (['GET', 'DELETE', 'UPDATE'].includes(action)) {
//     route = config.API.BOOKINGS.ONE;
//   } else if (['GETALL', 'SAVE'].includes(action)) {
//     route = config.API.BOOKINGS.ROOT;
//   } else {
//     route = config.API.BOOKINGS[action];
//   }


//   return request({
//     url: config.BASE_API_URL + route.replace('{owner}', params.owner),
//     accessToken: token,
//     data: params,
//   })
//   .then(response => {
//     console.log('RETURN FROM API', response);
//     if (response.success) {

//     }
//     return response;
//   })

// }

const services = {
  getAll,
  save,
  get,
  del,
  delInactivity,
  update,
  updateInactivity,
  saveInactivity,
  getInactivities,
  getAvailability,
};

export default services;