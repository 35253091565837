import types from './types';
import payments from './services';

const searchPayment = (id) => {

  const request = (id) => { return { type: types.PAYMENT_SEARCH_REQUEST, id } };
  const success = (payload) => { return { type: types.PAYMENT_SEARCH_SUCCESS, payload } }
  const failure = (error) => { return { type: types.PAYMENT_SEARCH_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await payments.searchPayment(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }

  };

}

const capturePayment = (id) => {
  const request = (id) => { return { type: types.PAYMENT_CAPTURE_REQUEST, id } };
  const success = (payload) => { return { type: types.PAYMENT_CAPTURE_SUCCESS, payload } }
  const failure = (error) => { return { type: types.PAYMENT_CAPTURE_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await payments.capturePayment(id, getState().users.auth.token)
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }

  };

}


const actions = {
  searchPayment,
  capturePayment,
};

export default actions;