import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { validateIsfilled, getOwner, selectGeneratorWObjChild } from '../../../libraries/utils';
import FormLayout from '../../../components/forms/FormLayout';
import messageActions from '../../../context/messages/actions';
import userActions from '../../../context/users/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import TextareaInput from '../../../components/forms/TextareaInput';
import SelectInput from '../../../components/forms/SelectInput';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      view: false,
      editing: false,
      titles: '',
      remove: false,
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Messages'), this.t('New')];
    this.titles = this.t('User details');
  }

  // ** Life cycle
  componentDidMount() {
    const params = this.props.match.params;
    console.log('OWNER', getOwner(this.props.auth.user))
    console.log('USER', this.props.auth.user)
    this.getUsers()
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit Message'),
      });
      this.breadcrumbs = [this.t('Messages'), this.t('Edit')];
      this.getMessage(params.id);
    } else {
      this.setState({ titles: this.t('New Message') });
    }
  }
  getMessage = async (id) => {
    await this.props.onGet(id);
    const message = this.props.messages;
    if (message.error) notify(this.t(message.error.message));
  };
  getUsers = async () => {
    const params = {  };
    // const { search } = this.state;
    // ** If search exist, filter the data
    params.where = {
      id : { 
        comparison: '!=',
        value: getOwner(this.props.auth.user) , 
         }
        
    }
    // if (search && search !== '') {
    //   params.where = { json_data: [] };
    //   if (search && search !== '') {
    //     params.where = { custom: { name: `%${search.toLowerCase()}` } };
    //   }
    // }
    // ** Execute get request with params
    await this.props.onGetAllUsers(params);
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      // ** Set state based on response data
      this.setState({ users: users.items });
    }
  };
  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  gotoBack = (message) => {
    if (!this.props.location.state) {
      history.push(config.ROUTES.MESSAGES);
    } else {
      const { location, from } = this.props.location.state;
      if (from === config.ROUTES.BOOK.MESSAGE) {
        history.push({
          pathname: message
            ? config.ROUTES.BOOK.SERVICE
            : config.ROUTES.BOOK.MESSAGE,
          state: {
            location,
            message: message || undefined,
          },
        });
      } else {
        history.push(config.ROUTES.MESSAGES);
      }
    }
  };

  onSubmit = async (values) => {
    await this.props.onSaveOrUpdate({
      ...values,
      source: getOwner(this.props.auth.user),
      parent: 'root',
      type: 'Not specified'
    });
    const messages = this.props.messages;
    if (messages.error) {
      notify(this.t(messages.error.message));
    } else {
      this.gotoBack(messages.item);
    }
  };

  render() {
    // **Destructuring objects for direct use of values
    const { props, state, t, submit, onSubmit, reset, breadcrumbs, titles } =
      this;
    const { view, editing, submitting, users } = state;
    const { loading, item: message } = props.messages;

    const userOptions = selectGeneratorWObjChild(users, 'id','name')
    /* 
    -------------------------------------------------------------------
                          FORM VALIDATION FUNCTIONS        
    -------------------------------------------------------------------
    */

    const required = (value) =>
      validateIsfilled(value) || (view && !editing)
        ? undefined
        : t('This field is required');

    // Layout actions
    const actions = {
      main: {
        onClick: (e) => submit(e),
        title: t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert',
      },
      secondaries: [
        {
          onClick: (e) => history.push(config.ROUTES.MESSAGES),
          title: t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent',
        },
        {
          onClick: (e) => this.setState({ editing: true }),
          title: t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update',
        },
        {
          onClick: (e) => {
            reset();
            this.setState({ editing: false });
          },
          title: t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing,
        },
      ],
    };

    const validateForm = (values) => {
      const errors = {};
      errors.target = required(values.target);
      errors.subject = required(values.subject);
      errors.body = required(values.body);
      return errors;
    };

    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs,
        }}
        container={{ className: 'px-8' }}
        actions={actions}
      >
        <ToastContainer />
        <PanelWindow outerTitle={titles} loading={loading}>
          <Form
            initialValues={message || {}}
            onSubmit={onSubmit}
            validate={validateForm}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={onSubmit} values={values}>
                  <div className="w-full sm:w-2/4 px-2">
                    <Field
                      name="target"
                      component={SelectInput}
                      options={userOptions}
                      placeholder={t('To')}
                      label={this.t('To')}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full sm:w-2/4 px-2">
                    <Field
                      name="subject"
                      component={TextInput}
                      placeholder={t('Subject')}
                      label={this.t('Subject')}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full sm:w-2/4 px-2">
                    <Field
                      name="body"
                      component={TextareaInput}
                      placeholder={t('Body')}
                      label={this.t('Body')}
                      readOnly={view && !editing}
                    />
                  </div>

                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    users: state.users.list,
    messages: state.messages.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrUpdate: (params) => dispatch(messageActions.saveOrUpdate(params)),
    onGet: (id) => dispatch(messageActions.get(id)),
    onRemove: (id) => dispatch(messageActions.del(id)),
    onClearCurrent: () => dispatch(messageActions.clearCurrent()),
    onGetAllUsers: (params) => dispatch(userActions.getAll(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Message));
