import React from 'react';

export default function UserProfileImage({ data, picture, username, ...rest }) {
  return (
    <>
      <div
        className={
          'rounded-full bg-gray-500 overflow-hidden flex items-center text-center mr-4 ' +
          (picture && picture.className ? picture.className : 'w-12 h-12')
        }
        onClick={() => {
          picture && picture.onClick && picture.onClick();
        }}
      >
        {data.profile_image ? (
          <img className="w-full h-auto" src={data.profile_image} alt="" />
        ) : (
          <span className="m-auto text-white text-2xl">{data.name}</span>
        )}
      </div>
      <h4
        className={
          username && username.className
            ? username.className
            : 'h4 text-base-content mb-0 inline-block'
        }
      >
        {`${data.first_name} ${data.last_name}`}
      </h4>
    </>
  );
}
