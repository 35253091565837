import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import CodeInput from '../../components/forms/CodeInput';

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      code: null,
    };
  }

  componentDidMount() {
    const params = this.props.match.params;
    if (params.hash) {
      this.checkHash(params);
    } else {
      history.push(config.ROUTES.LOGIN);
    }
  }

  checkHash = async (params) => {
    await this.props.onGetFromHash(params);
    const user = this.props.user;
    if (user.error) {
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ id: this.props.user.item.id });
    }
  };

  onSubmit = async () => {
    const data = this.state;
    await this.props.onVerifyPIN(data);
    const auth = this.props.auth;
    if (auth.error) {
      notify(this.t(auth.error.message));
    } else {
      //history.push(config.ROUTES.LOGIN);
      history.push(
        config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash)
      );
    }
  };

  onSendAgain = async () => {};

  onChange = (value) => {
    this.setState({ code: value });
  };

  render() {
    return (
      <LayoutSmall
        main={{ className: 'bg-base-200 text-primary bg-pattern' }}
        header={{
          title: this.t('Verification email'),
          className: 'bg-transparent text-primary text-center',
        }}
        container={{
          className: 'mt-10 p-4',
        }}
      >
        <ToastContainer />
        <div className="text-center mb-10">
          <p className="">{this.t('Enter code')}</p>
        </div>
        <form className="w-full max-w-lg">
          <CodeInput
            name="code"
            type="number"
            fields={6}
            onChange={this.onChange}
            autoFocus={true}
          />
          <div className="w-full px-3 text-center">
            <Button
              className="btn-link mb-10 text-primary-100 text-sm"
              title={this.t('Enviar de nuevo')}
              onClick={this.onSendAgain}
            />
            <Button
              className="btn-primary btn-block"
              title={this.t('Ingresar')}
              onClick={this.onSubmit}
            />
          </div>
        </form>
      </LayoutSmall>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    user: state.users.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onVerifyPIN: (params) => dispatch(userActions.verifyPIN(params)),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Verify));
