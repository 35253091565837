import { combineReducers } from 'redux';

import accounts from './accounts';
import actions from './actions';
import agreements from './agreements';
import benefits from './benefits'
import bookings from './bookings';
import businesses from './businesses';
import categories from './categories';
import cells from './cells';
import companies from './companies';
import conversions from './conversions';
import customers from './customers';
import diagnostics from './diagnostics';
import discounts from './discounts';
import files from './files';
import initiatives from './initiatives';
import initiative_assistants from './initiative_assistants';
import initiative_assistances from './initiative_assistances';
import insurances from './insurances';
import internals from './internals';
import invoices from './invoices';
import localization from './localization'; //TODO: REMOVE
import locations from './locations';
import measures from './measures';
import medicaments from './medicaments';
import messages from './messages';
import notifications from './notifications';
import oauth from './oauth';
import permissions from './permissions';
import permissions_roles from './permissions_roles';
import prescriptions from './prescriptions';
import products from './products';
import product_variants from './product_variants';
import providers from './providers';
import provisions from './provisions';
import receipts from './receipts';
import receptions from './receptions';
import roles from './roles';
import roles_users from './roles_users';
import services from './services';
import settings from './settings';
import slots from './slots';
import transactions from './transactions';
import users from './users';
import vehicles from './vehicles';

import payments from './payments';

export default combineReducers({
  accounts: accounts.reducers,
  actions: actions.reducers,
  agreements: agreements.reducers,
  benefits: benefits.reducers,
  bookings: bookings.reducers,
  businesses: businesses.reducers,
  categories: categories.reducers,
  cells: cells.reducers,
  conversions: conversions.reducers,
  companies: companies.reducers,
  customers: customers.reducers,
  diagnostics: diagnostics.reducers,
  discounts: discounts.reducers,
  files: files.reducers,
  initiatives: initiatives.reducers,
  initiative_assistants: initiative_assistants.reducers,
  initiative_assistances: initiative_assistances.reducers,
  insurances: insurances.reducers,
  internals: internals.reducers,
  invoices: invoices.reducers,
  locale: localization.reducers, //TODO: REMOVE
  locations: locations.reducers,
  measures: measures.reducers,
  medicaments: medicaments.reducers,
  messages: messages.reducers,
  notifications: notifications.reducers,
  oauth: oauth.reducers,
  payments: payments.reducers,
  permissions: permissions.reducers,
  permissions_roles: permissions_roles.reducers,
  prescriptions: prescriptions.reducers,
  products: products.reducers,
  product_variants: product_variants.reducers,
  providers: providers.reducers,
  provisions: provisions.reducers,
  receipts: receipts.reducers,
  receptions: receptions.reducers,
  roles: roles.reducers,
  roles_users: roles_users.reducers,
  services: services.reducers,
  settings: settings.reducers,
  slots: slots.reducers,
  transactions: transactions.reducers,
  users: users.reducers,
  vehicles: vehicles.reducers,
});
