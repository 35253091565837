import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import {
  composeValidators,
  validateEmail,
  validateIsfilled,
  getTimeZone,
  selectGeneratorWObjChild,
  capitalizePhrase
} from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import CheckboxInput from '../../components/forms/CheckboxInput';
import rolesActions from '../../context/roles/actions';
import SelectInput from '../../components/forms/SelectInput';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      roles: [],
      signupData: {}
    };
  }

  componentDidMount() {
    this.getData();
  }

  onSubmit = async values => {
    this.setState({ submitting: true, signupData: { ...values } });
    const data = {
      ...values,
      time_zone: getTimeZone(),
      user_type: config.USER_TYPE.REGULAR
    };
    // data.birth_date = dateComponents(values.birth_date)[3].dateOnly;
    await this.props.onRegister(data);
    const user = this.props.user;
    if (user.error) {
      this.setState({ submitting: false });
      notify(this.t(user.error.message));
    } else {
      //history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
      history.push(
        config.ROUTES.VERIFY.replace(':hash', this.props.user.item.hash)
      );
    }
  };

  getData = async () => {
    await this.props.onGetRoles();
    this.setState({
      roles: [...this.props.roles.items]
    });
    console.log(this.state)
  };

  onTerms = () => {
    history.push(config.ROUTES.TERMS);
  };

  onLogin = () => {
    history.push(config.ROUTES.LOGIN);
  };

  render() {
    const { roles } = this.state;
    const required = value =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const email = value =>
      validateEmail(value) ? undefined : this.t('Not a valid email');
    /* const onlynumber = value =>
      validateOnlyNumber(value)
        ? undefined
        : this.t('El documento solo debe llevar digitos');
    const dnilength = value =>
      value.length >= 7 && value.length <= 8
        ? undefined
        : this.t('EL documento debe tener 7 u 8 numeros'); */
    /* const dni_types = config.OPTIONS.SINGUP.IDENTIFICATION.TYPES.map((o) => {
      return { value: o, label: this.t(o) };
    });
    const gender_types = config.OPTIONS.SINGUP.GENDER.TYPES.map((o) => {
      return { value: o, label: capitalize(o) };
    }); */

    let roleIDs;
    if (roles.length) {
      roleIDs = roles.map(role => role.id);
    }

    const roleOptions = selectGeneratorWObjChild(
      roles?.filter(role => roleIDs.includes(role.id)),
      'id',
      o => capitalizePhrase(o.name)
    );

    return (
      <LayoutSmall
        main={{ className: 'bg-base-200 text-primary bg-pattern' }}
        header={{
          title: this.t('Register'),
          className: 'bg-transparent text-primary'
        }}
        container={{
          className: 'mt-10'
        }}>
        <ToastContainer />
        <Form
          initialValues={this.state.signupData || {}}
          onSubmit={this.onSubmit}>
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg mx-auto px-4">
                <div className="w-full">
                  <Field
                    name="first_name"
                    component={TextInput}
                    placeholder={this.t('Ej. Juan')}
                    label={this.t('Nombre')}
                    validate={required}
                  />
                </div>
                <div className="w-full">
                  <Field
                    name="last_name"
                    component={TextInput}
                    placeholder={this.t('Ej. Sosa')}
                    label={this.t('Apellido')}
                    validate={required}
                  />
                </div>
                <div className="w-full">
                  <Field
                    name="email"
                    component={TextInput}
                    placeholder={this.t('Ej. mail@mail.com')}
                    label="Correo electrónico"
                    validate={composeValidators(required, email)}
                  />
                </div>
                <div className="w-full">
                  <Field
                    name="phone"
                    component={TextInput}
                    placeholder={this.t('Cod de área sin el 15 ')}
                    label="Nro de Teléfono"
                    validate={required}
                  />
                </div>

                <div className="flex">
                  <div className="w-full px-3 md:mb-0">
                    <Field
                      component={SelectInput}
                      empty={this.t('Select a role')}
                      label={this.t('Role')}
                      name="role"
                      options={roleOptions}
                      placeholder={this.t('Role')}
                      validate={required}
                    />
                  </div>
                  {/* <div className="w-1/4 mr-3">
                    <Field
                      name="identification_type"
                      component={SelectInput}
                      placeholder={this.t('Tipo de Doc')}
                      options={dni_types}
                      label="Tipo de Doc."
                      empty={this.t('Seleccione')}
                      validate={required}
                    />
                  </div> */}
                  {/* <div className="w-3/4">
                    <Field
                      name="identification"
                      component={TextInput}
                      placeholder={this.t('8 dígitos')}
                      label="Nro de Documento"
                      validate={composeValidators(
                        required,
                        onlynumber,
                        dnilength
                      )}
                    />
                  </div> */}
                </div>

                <div className="flex">
                  {/* <div className="w-1/2 mr-3">
                    <Field
                      name="gender"
                      component={SelectInput}
                      placeholder={this.t('Genero')}
                      options={gender_types}
                      label="Género"
                      empty={this.t('Seleccione')}
                      validate={required}
                    />
                  </div> */}

                  {/* <div className="w-1/2">
                    <Field
                      name="birth_date"
                      component={DatepickerInput}
                      placeholder={this.t('dd/mm/aaaa')}
                      label="Fecha de Nacimiento"
                      validate={required}
                    />
                </div>*/}
                </div>

                <div className="w-full mb-3 md:mb-0">
                  <Field
                    name="terms"
                    component={CheckboxInput}
                    label={this.t('I accept terms and conditions')}
                    validate={required}
                    checkboxClassName="checkbox-primary"
                    type="checkbox"
                  />
                </div>

                <div className="w-full">
                  <Button
                    className="btn-primary btn-block"
                    title={this.t('Next')}
                    onClick={handleSubmit}
                    disabled={this.state.submitting || pristine}
                  />
                </div>
              </form>
            </>
          )}
        </Form>
        <div className="w-full px-3 mt-5">
          <Button
            className="btn-link btn-block underline"
            title={this.t('Terms and conditions')}
            onClick={this.onTerms}
          />
        </div>
        <div className="mt-2 p-2 flex justify-center items-center">
          <p className="text-center mr-1">{this.t('Has an account?')}</p>
          <Button
            className="btn-link mb-0 underline"
            title={this.t('Login with your user')}
            onClick={this.onLogin}
          />
        </div>
      </LayoutSmall>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    roles: state.roles.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: params =>
      dispatch(userActions.saveOrUpdate(params, 'register')),
    onGetRoles: params => dispatch(rolesActions.getAll(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Signup));
