const routes = {
  ROUTES: {
    HOME: '/',
    LOGIN: '/login',
    SIGNUP: '/signup',
    SIGNUP_FULL: '/social/signup',
    FORGOT: '/forgot',
    PASSWORD: '/password/:hash',
    VERIFY: '/verify/:hash',

    USER: {
      PASSWORD_CHANGE: '/user/change',
      PROFILE: '/user/profile',
      INFO: '/user/info',
    },

    HELP: '/help',
    TERMS: '/terms',
    PRIVACY: '/privacy',
    CONTACT_US: '/contact_us',


    USERS: '/users',
    USERS_NEW: '/users/new',
    USERS_EDIT: '/users/:id',

    MESSAGES: '/messages',
    MESSAGES_NEW: '/messages/new',
    MESSAGES_EDIT: '/messages/:id',

    ACCOUNTS: '/accounts',
    ACCOUNTS_NEW: '/accounts/new',
    ACCOUNTS_EDIT: '/accounts/:id',

    SETTINGS: '/settings',
    SETTINGS_NEW: '/settings/new',
    SETTINGS_EDIT: '/settings/:id',
    MY_SETTINGS: '/my_settings',

    TRANSACTIONS: '/operations',
    TRANSACTIONS_TRANSFERS: '/transfers',
    TRANSACTION: '/transactions/:id',

    DISCOUNTS: '/discounts',
    DISCOUNTS_NEW: '/discounts/new',
    DISCOUNTS_EDIT: '/discounts/:id'
  }
};
export default routes;