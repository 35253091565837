import React, { forwardRef, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Icon from '../../libraries/icons';
import ReactPaginate from 'react-paginate';
import { paginate } from '../../libraries//utils';
import 'animate.css';

import Loader from '../../components/commons/Loader';
// ** Import swal and customize the stlyes of it
// ****** UNUSED --JC ******
/* import Swal from "sweetalert2"
const customSwal = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary mx-1",
    cancelButton: "btn btn-outline btn-primary mx-1",
    title: "swal2-title",
    htmlContainer: "swal-text"
  },
  buttonsStyling: false,
  background: "#fff"
}) */

const StyledDataTable = (props) => {
  // ** Local states
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checked, setChecked] = useState(true);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setHidden(true);
  }, [props.loading]);
  // ** Show how many records are on screen, and in total
  let totalRecords = 0;
  let pageRecords = 0;
  if (props?.query) {
    totalRecords = parseInt(props.query.records);
    pageRecords = parseInt(props.query.page_size);
  }
  const { startIndex, endIndex } = paginate(
    totalRecords,
    currentPage + 1,
    pageRecords
  );
  // ** Function to reset selected rows
  const resetRows = () => {
    setChecked(false);
    setSelectedRows([]);
    setChecked(true);
  };
  useEffect(() => {
    setTimeout(()=> {
      resetRows()
    })
  }, [props?.tab])
  // ** Custom delete function
  const customMultiFunction = () => {
    if (props.multiFunction) props.multiFunction(selectedRows, resetRows);
    else if (props.multipleDeleteFunction)
      props.multipleDeleteFunction(selectedRows, resetRows);
  };
  // ** Handle change on selected rows // hide or show function button
  const handleChange = (state) => {
    setSelectedRows(state.selectedRows);
    if (state.selectedRows.length) {
      setHidden(false);
    } else {
      setTimeout(() => {
        setHidden(true);
      }, 250);
    }
  };
  // ** Custom styles for our data table
  const customStyles = {
    table: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
        minHeight:  props.loading ? '0' : props.height ? props.height : "248px" 
      },
    },
    headRow: {
      style: {
        color: '#000',
        backgroundColor: '#fff',
        fontSize: 15,
        fontWeight: 650,
      },
    },
    rows: {
      style: {
        fontSize: '13px',
        fontWeight: 400,
        color: '#000',
        backgroundColor: '#fff',
        cursor: props.onRowClicked ? 'pointer': 'auto'
      },
    },
    noData: {
      style: {
        color: '#000',
        backgroundColor: '#fff',
        padding: '5rem',
        minHeight: '248px',
      },
    },
    expanderRow: {
      style: {
        color: '#000',
      },
    },
    expanderButton: {
      style: {
        color: '#000',
        '&:disabled': {
          color: '#616d85',
        },
      },
    },
    progress: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000',
        backgroundColor: '#fff',
        minHeight: props.height ? props.height : "248px" ,
      },
    },
  };

  // ** Function to handle Pagination
  const handlePagination = (page) => {
    props.getDataFunction(page.selected + 1);
    setCurrentPage(page.selected);
  };

  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      previousLabel={<Icon className="h-7 w-7" name="cheveron_left" />}
      nextLabel={<Icon className="h-7 w-7" name="cheveron_right" />}
      forcePage={currentPage}
      onPageChange={(page) => handlePagination(page)}
      pageCount={Math.ceil(props.query?.records / props.query?.page_size) || 1}
      breakLabel="..."
      pageRangeDisplayed={props.screenWidth < 350 ? -1 : 2}
      marginPagesDisplayed={-1}
      activeClassName="bordered"
      containerClassName="artboard menu horizontal flex justify-center bg-base-100 rounded-b-box border-t border-base-150"
    />
  );
  // ** Custom selectable row component
  const SelectableRow = forwardRef((props, ref) => {
    return (
      <div className="custom-control custom-checkbox">
        <input type="checkbox" className="" {...props} ref={ref} />
      </div>
    );
  });
  // ** Custom sort function
  const handleSort = async (column, sortDirection) => {
    if (column.sortSelector) {
      resetRows();
      props.customSort(column.sortSelector, sortDirection, currentPage + 1);
    }
  };

  return (
    <div
      className={`${props.className}${props.data?.length ? 'rounded-t-box' : 'rounded-box' } grid relative`}
    >
      <DataTable
        noTableHead={props.noHead}
        expandableRows={props.expandableRows}
        sortIcon={<Icon className="h-12 w-12" name="cheveron_down" />}
        selectableRows={props.selectableRows}
        paginationPerPage={props.query?.page_size}
        columns={props.columns}
        data={props.data}
        customStyles={customStyles}
        noDataComponent={props.noDataComponent || 'No records'}
        selectableRowsComponent={SelectableRow}
        onSelectedRowsChange={handleChange}
        expandableRowsComponent={props.expandableRowsComponent}
        clearSelectedRows={checked}
        progressPending={props.loading}
        progressComponent={
          props.customLoaderComponent || (
            <Loader spinnerClassName="text-primary h-12 w-12" />
          )
        }
        conditionalRowStyles={props.conditionalRowStyles}
        onSort={props.customSort && handleSort}
        sortServer={props.customSort}
        onRowClicked={props.onRowClicked}
      />
      {!props.loading && props.pagination && <CustomPagination />}
      <button
        onClick={customMultiFunction}
        className={`absolute bottom-4 left-4 animate__animated ${
          hidden
            ? 'hidden'
            : selectedRows.length
            ? 'animate__fadeIn'
            : 'animate__fadeOut'
        }`}
      >
        <Icon className="h-5 w-5 mr-2 block md:hidden" name="trash" />
        <span className="ml-1 hidden md:flex btn btn-xs bg-base-150 border-base-150">
          <Icon className="h-4 w-4 mr-2" name="trash" />
          Multiple delete
        </span>
      </button>
      <div className="px-0 absolute bottom-3 right-4">
        {!props.loading && props.pagination && totalRecords > 0 && (
          <p className="text-sm leading-5 text-gray-700 hidden sm:block">
            <span className="font-medium p-1">{startIndex + 1}</span>a
            <span className="font-medium p-1">{endIndex + 1}</span>
            de
            <span className="font-medium p-1">{totalRecords}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default StyledDataTable;
