import config from '../../config';
import { request } from '../../libraries/request'

const getAll = (params = {}, token) => {
  console.log('SERVICES::SLOTS::get', params, token);

  return request({
    url: config.BASE_API_URL + config.API.SLOTS.replace('{booking_id}', params.booking_id),
    accessToken: token,
    data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  })
}

const save = (data, token) => {
  console.log('SERVICES::SLOTS::save', data, token);
  return request({
    url: config.BASE_API_URL + config.API.SLOTS.replace('{booking_id}', data.booking_id),
    accessToken: token,
    method: 'POST',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const update = (data, token) => {
  console.log('SERVICES::SLOTS::update', data, token);
  return request({
    url: config.BASE_API_URL + config.API.SLOT.replace('{booking_id}', data.booking_id).replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const get = (data, token) => {
  console.log('SERVICES::SLOTS::get', data.id, token);
  return request({
    url: config.BASE_API_URL + config.API.SLOT.replace('{booking_id}', data.booking_id).replace('{id}', data.id),
    accessToken: token,
    // data: {},
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })

}

const del = (data, token) => {
  console.log('SERVICES::SLOTS::remove', data.id, token);
  return request({
    url: config.BASE_API_URL + config.API.SLOT.replace('{booking_id}', data.booking_id).replace('{id}', data.id),
    accessToken: token,
    method: 'DELETE',
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

//updatemanyslots
//savemanyslots
const saveMany = (data, token) => {
  console.log('SERVICES::SLOTS::savemany', data, token);
  return request({
    url: config.BASE_API_URL + config.API.SLOTS.replace('{booking_id}', data.booking_id),
    accessToken: token,
    method: 'POST',
    data: data.slots,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const updateMany = (data, token) => {
  console.log('SERVICES::SLOTS::updatemany', data, token);
  return request({
    url: config.BASE_API_URL + config.API.SLOTS.replace('{booking_id}', data.booking_id),
    accessToken: token,
    method: 'PATCH',
    data: data.slots,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  })
}

const services = {
  getAll,
  save,
  get,
  del,
  update,
  updateMany,
  saveMany,
};

export default services;