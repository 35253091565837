const types = {
  CLEAR_CURRENT: 'CATEGORIES_CLEAR_CURRENT',
  GET_REQUEST: 'CATEGORIES_GET_REQUEST',
  GET_SUCCESS: 'CATEGORIES_GET_SUCCESS',
  GET_FAILURE: 'CATEGORIES_GET_FAILURE',

  SAVE_REQUEST: 'CATEGORIES_SAVER_REQUEST',
  SAVE_SUCCESS: 'CATEGORIES_SAVE_SUCCESS',
  SAVE_FAILURE: 'CATEGORIES_SAVE_FAILURE',

  GETALL_REQUEST: 'CATEGORIES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CATEGORIES_GETALL_SUCCESS',
  GETALL_FAILURE: 'CATEGORIES_GETALL_FAILURE',

  DELETE_REQUEST: 'CATEGORIES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CATEGORIES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CATEGORIES_DELETE_FAILURE',    

  UPDATE_REQUEST: 'CATEGORIES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CATEGORIES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CATEGORIES_UPDATE_FAILURE'    

};

export default types;