import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import config from '../../../config';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import FormLayout from '../../../components/forms/FormLayout';
import settingActions from '../../../context/settings/actions';
import companyActions from '../../../context/companies/actions';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import SwitchboxInput from '../../../components/forms/SwitchboxInput';
import TextInput from '../../../components/forms/TextInput';
import { getOwner } from '../../../libraries/utils';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      defaultSettings: [],
      loading: false,
      submitting: false,
      view: false,
      editing: false,
      settings: [],
      titles: '',
      type: '',
      owner: '',
      remove: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Settings'), this.t('New')];
    this.titles = this.t('User details');
  }

  // ** Life cycle
  componentDidMount() {
    this.setState({
      remove: true,
      view: true,
      titles: this.t('Edit Settings')
    });
    this.breadcrumbs = [this.t('Settings'), this.t('Edit')];
    this.getDefaultSettings();
  }

  getDefaultSettings = async () => {
    const { user } = this.props.auth;
    const type = "regalalebitcoins"
    this.setState({ type: type });
    const params = {
      type: type,
      owner: config.OWNER.SYSTEM
    };
    this.setState({ loading: true });
    await this.props.onGetSettings(params);
    const { settings } = this.props;

    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      this.setState({ defaultSettings: settings.items, owner: config.OWNER.SYSTEM }, () => this.getSettings(params));
    }
  };

  getSettings = async params => {
    const { defaultSettings, owner } = this.state;
    params.owner = owner;
    await this.props.onGetSettings(params);

    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      console.log("settings", settings.items)
      this.setState({ settings: settings.items, loading: false }, () => {
        if (this.state.settings.length === 0) {
          let finalArr = [];

          for (let i = 0; i < defaultSettings.length; i++) {
            finalArr.push({
              name: defaultSettings[i].name,
              details: defaultSettings[i].details,
              value: defaultSettings[i].value,
              code: defaultSettings[i].code,
              type: defaultSettings[i].type
            });

            if (i === defaultSettings.length - 1) {
              this.setState({
                settings: [...finalArr],
                loading: false
              });
            }
          }
        }
      });
    }
  };

  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  gotoBack = action => {
    if (!this.props.location.state) {
      history.push(config.ROUTES.SETTINGS);
    } else {
      const { location, from } = this.props.location.state;
      if (from === config.ROUTES.BOOK.SETTING) {
        history.push({
          pathname: action ? config.ROUTES.BOOK.SERVICE : config.ROUTES.BOOK.SETTING,
          state: {
            location,
            action: action || undefined
          }
        });
      } else {
        history.push(config.ROUTES.SETTINGS);
      }
    }
  };

  onSubmit = async values => {
    this.setState({loading: true})
    const localSettings = this.state.settings;
    const { owner } = this.state;
    // por un lado el config.OWNER.SYSTEM para buscar por primera vez
    // para buscar el valor si esta seteado con el owner
    // obj.code
    // combinar el default settings y el owner settings
    for (let obj of localSettings) {
      obj.value = values[obj.code];
    }

    for (let i = 0; i < localSettings.length; i++) {
      const element = { ...localSettings[i], owner: owner };
      element.value = element.value.replaceAll(",", ".")
      await this.props.onSaveOrUpdate({
        ...element,
        data_type: null,
      });
    }
    const settings = this.props.settings;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      this.setState({ editing: false });
      const params = {type: this.state.type, owner: this.state.owner}
      this.getSettings(params);
    }
  };

  render() {
    // **Destructuring objects for direct use of values
    const { view, editing, submitting, settings, loading } = this.state;
    const { user } = this.props.auth;

    // Layout settings
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        }
      ]
    };

    const customFormObject = {};
    const detailsDisplayObject = {};
    const namesDisplayObject = {};

    // {details: setting.details, label: setting.name}
    // customFormObject[setting.code]

    if (settings.length > 0) {
      settings?.forEach(setting => {
        customFormObject[setting.code] = setting.value === 'true' ? true : setting.value === 'false' ? false : true;
        detailsDisplayObject[setting.code] = setting.details || '';
        namesDisplayObject[setting.code] = setting.name || '';
        return;
      });
    }

    if (settings.length > 0) {
      settings?.forEach(setting => {
        customFormObject[setting.code] = setting.value;
        detailsDisplayObject[setting.code] = setting.details || '';
        namesDisplayObject[setting.code] = setting.name || '';
        return;
      });
    }

    console.log(settings)

    // ** Actual render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outterTitle={this.titles} loading={loading}>
          <Form initialValues={customFormObject || {}} onSubmit={this.onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>

                    <>
                      <div className="w-full px-3 mb-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="ars_to_usd"
                          component={TextInput}
                          label={this.t(namesDisplayObject.ars_to_usd)}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.ars_to_usd}`}</span>
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="buyer_fee"
                          component={TextInput}
                          label={this.t(namesDisplayObject.buyer_fee)}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.buyer_fee}`}</span>
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="binance_withdraw"
                          component={TextInput}
                          label={this.t(namesDisplayObject.binance_withdraw)}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.btc_price}`}</span>
                      </div>
                     
                      <div className="w-full px-3 mb-3 md:mb-0 font-bold">
                        <Field
                          labelClassName="mt-3"
                          name="btc_price"
                          component={TextInput}
                          label={this.t(namesDisplayObject.btc_price)}
                          readOnly={view && !editing}
                        />
                      </div>
                      <div className="w-full px-3 mb-3 md:mb-0 italic">
                        <span>{`${detailsDisplayObject.btc_price}`}</span>
                      </div>
                     
                    </>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    settings: state.settings.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(settingActions.saveOrUpdate(params)),
    onGetSettings: params => dispatch(settingActions.getAll(params)),
    onGetCompanies: params => dispatch(companyActions.getAll(params)),
    onRemove: id => dispatch(settingActions.del(id)),
    onClearCurrent: () => dispatch(settingActions.clearCurrent())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Settings));
