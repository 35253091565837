const dotenv = {
  production: {
    BASE_API_URL: 'https://api.regalalebitcoins.com/api/v1',
    DEBUG_MODE: false,
    FRONTEND_URL: 'https://regalalebitcoins.com',
    // ONESIGNAL_APPID: 'xx',
    // ONESIGNAL_SAFARI_WEB_ID: 'web.onesignal.auto.xxx',
  },
  development: {
    BASE_API_URL: 'https://api.regalalebitcoins.com/api/v1',
    //BASE_API_URL: 'https://9853-2803-9800-a443-82a2-aca8-5def-f87-8415.ngrok-free.app/api/v1',
    //BASE_API_URL: 'http://localhost:8893/api/v1',
    DEBUG_MODE: false,
    FRONTEND_URL: 'https://regalalebitcoins.com',
    // ONESIGNAL_APPID: 'xx',
    // ONESIGNAL_SAFARI_WEB_ID: 'web.onesignal.auto.xxx',
  }
}

const vars = {
  VERSION: 'v20210129.001',
  CLIENT_ID: '11111111111',
  CLIENT_SECRET: 'qwetqutuqwteutuqwteuqwtuetqwyteuqt',
};

const { NODE_ENV } = process.env;
const dotenvVars = Object.assign(
  ...Object.keys(dotenv[NODE_ENV])
    .map( key => ({ [key]: dotenv[NODE_ENV][key] }) )
);
const general = { ...vars, ...dotenvVars };
export default general;