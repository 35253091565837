import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import Icon from '../../../libraries/icons';
import { capitalize, hideColumns } from '../../../libraries/utils';
// config
import config from '../../../config';
// context
import accountActions from '../../../context/accounts/actions';
// components
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';
import PrivateButton from '../../../components/commons/PrivateButton';

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      accounts: [],
      sort: {
        order_by: 'created_at'
      },
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [this.t('Accounts')];
  }

  // Lifecycle methods
  componentDidMount() {
    this.getAccounts();
    // ** Get window width
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener('resize', this.handleResize);
  }

  // -- custom methods --
  // API calls
  getAccounts = async (page_num = 1) => {
    const params = { page_num };
    // search
    const { search, sort } = this.state;
    if (search && search !== '') {
      params.where = { json_data: [] };
      if (search && search !== '') {
        params.where = { custom: { name: `%${search.toLowerCase()}` } };
      }
    }
    // sort
    if (sort) {
      params.order_by = sort.order_by;
      params.order_direction = sort.order_direction;
    }
    // GET
    await this.props.onGetAll(params);
    const { accounts } = this.props;
    if (accounts.error) {
      notify(this.t(accounts.error.message));
    } else {
      this.setState({ accounts: accounts.items });
    }
  };
  //  DELETE
  onRemove = (id, resetRows) => {
    const { accounts } = this.props;
    customSwal
      .fire({
        title: this.t(resetRows ? capitalize(this.t('delete multiple elements')) : this.t('¿Are you sure?')),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          accounts.loading = true;
          // ** If resetRows is received, it means we multiple remove
          if (resetRows) {
            // remove one by one (cannot really delete multiple)
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (accounts.error) {
              notify(this.t(accounts.error.message));
            } else {
              // reload
              this.getAccounts(this.state.currentPage);
            }
          } else {
            // remove one
            await this.props.onRemove(id);
            if (accounts.error) {
              notify(this.t(accounts.error.message));
            } else {
              // reload
              this.getAccounts(this.state.currentPage);
            }
          }
        }
      });
  };
  // ** Redirection to edit account route
  onEdit = id => {
    history.push(config.ROUTES.ACCOUNTS_EDIT.replace(':id', id));
  };
  // ** Redirection to new account route
  onNew = () => {
    history.push(config.ROUTES.ACCOUNTS_NEW);
  };
  // ** search function
  onSearch = data => {
    if (this.state.search !== data.search) this.setState({ search: data.search || '' }, () => this.getAccounts());
  };
  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () => this.getAccounts(currentPage));
  };
  // other methods
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    // ** Destructuring objects for direct use of values
    const { accounts, windowWidth } = this.state;
    // ** Column config for data table
    const columns = [
      {
        name: this.t('Type'),
        sortable: true,
        selector: row => row['type'],
        minWidth: '150px',
        sortSelector: 'type'
      },
      {
        name: this.t('Number'),
        sortable: true,
        selector: row => row['number'],
        minWidth: '150px',
        hide: 'md'
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '150px',
        hide: hideColumns(370, windowWidth),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <div className="flex justify-center align-center rounded-full">
                <span
                  onClick={() => this.onEdit(row.id)}
                  className="p-3 rounded-l-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                  <Icon className="h-3 w-3 text-white" name="view_show" />
                </span>
                <PrivateButton control="delete">
                  <span
                    onClick={() => this.onRemove(row.id)}
                    className="p-3 rounded-r-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                    <Icon className="h-3 w-3 text-white" name="trash" />
                  </span>
                </PrivateButton>
              </div>
            </div>
          );
        }
      }
    ];
    // NavActionBar actions
    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [],
      search: { onSearch: this.onSearch, title: this.t('Search') }
    };
    // Render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={this.props.accounts.loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={accounts}
            columns={columns}
            selectableRows={windowWidth > 600}
            query={this.props.accounts.query}
            getDataFunction={this.getAccounts}
            multipleDeleteFunction={this.onRemove}
            pagination
            loading={this.props.accounts.loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
            expandableRows={windowWidth < 600}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    accounts: state.accounts.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAll: params => dispatch(accountActions.getAll(params)),
    onRemove: id => dispatch(accountActions.del(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Accounts));
