import types from './types';
import productVariants from './services';

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  };
};

const getAll = (params) => {
  function request() {
    return { type: types.GETALL_REQUEST };
  }
  function success(payload) {
    return { type: types.GETALL_SUCCESS, payload };
  }
  function failure(error) {
    return { type: types.GETALL_FAILURE, error };
  }

  return async (dispatch, getState) => {
    console.log('ACTIONS::PRODUCT_VARIANTS::getAll', params);
    dispatch(request());
    const response = await productVariants.getAll(params, getState().users.auth.token);
    console.log(
      'ACTIONS::PRODUCT_VARIANTS::getAll::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const get = (id) => {
  console.log('ACTIONS::PRODUCT_VARIANTS::get', id);
  const request = (id) => {
    return { type: types.GET_REQUEST, id };
  };
  const success = (payload) => {
    return { type: types.GET_SUCCESS, payload };
  };
  const failure = (error) => {
    return { type: types.GET_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await productVariants.get(id, getState().users.auth.token);
    console.log(
      'ACTIONS::PRODUCT_VARIANTS::get::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const saveOrUpdate = (productVariant, productID) => {
  function request(payload) {
    return { type: types.SAVE_REQUEST, payload };
  }
  function success(payload) {
    return { type: types.SAVE_SUCCESS, payload };
  }
  function failure(error) {
    return { type: types.SAVE_FAILURE, error };
  }

  return async (dispatch, getState) => {
    console.log('ACTIONS::PRODUCT_VARIANTS::saveOrUpdate', productVariant, productID);
    dispatch(request(productVariant));

    let response;
    if (productVariant.id) {
      // editing a existing record
      response = await productVariants.update(productVariant, getState().users.auth.token);
    } else {
      response = await productVariants.save(productVariant, getState().users.auth.token, productID);
    }
    console.log(
      'ACTIONS::PRODUCT_VARIANTS::saveOrUpdate::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const del = (productID, variantID) => {
  console.log('ACTIONS::PRODUCT_VARIANTS::del', productID, variantID);
  function request(productID) {
    return { type: types.DELETE_REQUEST, productID };
  }
  function success(productID) {
    return { type: types.DELETE_SUCCESS, productID };
  }
  function failure(productID, error) {
    return { type: types.DELETE_FAILURE, productID, error };
  }

  return async (dispatch, getState) => {
    dispatch(request(productID));
    let response = await productVariants.del(productID, getState().users.auth.token, variantID);
    console.log(
      'ACTIONS::PRODUCT_VARIANTS::del::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(productID));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  del,
};

export default actions;
