import types from './types'; 

const reducer = (state = {
  error: false,
  data: null,
  loading: false,
}, action) => {
  // console.log('::::::REDUCER::FILES::', action);
  switch(action.type) {
    case types.PAYMENT_SEARCH_REQUEST:
      return { ...state,
        loading: true,
        error: false,
      };
    case types.PAYMENT_SEARCH_SUCCESS:
      return { ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case types.PAYMENT_SEARCH_FAILURE: 
      return { ...state,
        error: action.payload,
        loading: false
      };
    case types.PAYMENT_CAPTURE_REQUEST:
      return { ...state,
        loading: true,
        error: false,
      };
    case types.PAYMENT_CAPTURE_SUCCESS:
      return { ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case types.PAYMENT_CAPTURE_FAILURE: 
      return { ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;