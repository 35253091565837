import config from '../../config';
import { request } from '../../libraries/request';
import { isDate, isObject } from '../../libraries/utils';

const getAll = (params = {}, token) => {
  console.log('SERVICES::INVOICES::getAll', params, token);
  if (params?.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) {
    params.order_by = 'invoices__date';
    params.order_direction = 'DESC';
  }

  return request({
    url: config.BASE_API_URL + config.API.INVOICES,
    accessToken: token,
    data: params,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    // const {data : {data: items}, data} = response
    if (response.success) {
    /*       
      items.forEach((e, index) => {
        items[index].date = new Date(e.date);
      });
      data.query.page_size = config.QUERIES_PAGE_SIZE;
      // sorting array
      items.sort((i, j) => {
        return j.date - i.date; 
      });*/
    }
    return response;
  });
};

const save = (data, token) => {
  console.log('SERVICES::INVOICES::save', data, token);
  if (Array.isArray(data)) {
    console.log('SENDING ARRAY OF INVOICES');
    data.forEach((e, index) => {
      data[index].date = new Date(e.date);
    });
  } else {
    data.date = data.date.toISOString().substring(0, 10);
  }

  return request({
    url: config.BASE_API_URL + config.API.INVOICES,
    accessToken: token,
    method: 'POST',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const update = (data, token) => {
  console.log('SERVICES::INVOICES::update', data, token);
  data.date = !isDate(data.date)
    ? data.date
    : data.date.toISOString().substring(0, 10);

  return request({
    url: config.BASE_API_URL + config.API.INVOICE.replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const get = (id, token) => {
  console.log('SERVICES::INVOICES::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.INVOICE.replace('{id}', id),
    accessToken: token,
    // data: {},
  }).then((response) => {
    console.log('RETURN FROM API', response);
    const {data : {data}} = response
    if (response.success) {
      if (isObject(data.provider)) data.provider = data.provider.id
      data.date = new Date(data.date);
      if(data.invoices_lines) data.invoices_lines = data.invoices_lines.map((e, index) => ({...e, product: e.product.id, measure: e.measure.id}));
    }
    return response;
  });
};

const del = (id, token) => {
  console.log('SERVICES::INVOICES::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.INVOICE.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const services = {
  getAll,
  save,
  get,
  del,
  update,
};

export default services;