import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { history } from '../../../routes';
import {
  composeValidators,
  formatOnlyNumberInput,
  getOwner,
  validateIsfilled,
  validateOnlyNumber
} from '../../../libraries/utils';
// config
import config from '../../../config';
// context
import accountActions from '../../../context/accounts/actions';
// components
import FormLayout from '../../../components/forms/FormLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      account: {},
      editing: false,
      remove: false,
      submitting: false,
      titles: '',
      view: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Accounts'), this.t('New')];
    this.titles = this.t('User details');
  }

  // ** Life cycle methods
  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit Account')
      });
      this.breadcrumbs = [this.t('Accounts'), this.t('Edit')];
      this.getAccount(params.id);
    } else {
      this.setState({ titles: this.t('New Account') });
    }
  }
  componentWillUnmount() {
    this.props.onClearCurrent();
  }

  // custom methods
  // API calls
  getAccount = async id => {
    await this.props.onGet(id);
    const account = this.props.accounts;
    if (account.error) {
      notify(this.t(account.error.message));
    } else {
      this.setState({ account: account.item });
    }
  };
  onSubmit = async values => {
    const data = {
      number: values.number,
      owner: getOwner(this.props.auth.user)
    };
    await this.props.onSaveOrUpdate({
      ...data
    });
    const accounts = this.props.accounts;
    if (accounts.error) {
      notify(this.t(accounts.error.message));
    } else {
      history.push(config.ROUTES.ACCOUNTS);
    }
  };

  render() {
    // ** Destructuring objects for direct use of values
    const { account, editing, submitting, view } = this.state;
    // form validation
    const required = value =>
      validateIsfilled(value) || (view && !editing) ? undefined : this.t('This field is required');
    const checkNumber = value =>
      validateOnlyNumber(value) || (view && !editing) ? undefined : this.t('Not a valid number');
    // NavActionBar actions
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.ACCOUNTS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        }
      ]
    };

    // Render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={this.props.account.loading}>
          <Form initialValues={account || {}} onSubmit={this.onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="number"
                      placeholder={this.t('Number')}
                      label={this.t('Number')}
                      component={TextInput}
                      validate={composeValidators(required, checkNumber)}
                      parse={v => formatOnlyNumberInput(v, 8)}
                      readOnly={view && !editing}
                    />
                  </div>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    accounts: state.accounts.current
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveOrUpdate: params => dispatch(accountActions.saveOrUpdate(params)),
    onGet: id => dispatch(accountActions.get(id)),
    onRemove: id => dispatch(accountActions.del(id)),
    onClearCurrent: () => dispatch(accountActions.clearCurrent())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Account));
