import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ToastContainer, notify } from '../../../libraries/notifications';
import {
  capitalize,
  isEmptyObject,
  capitalizePhrase,
  selectGeneratorWObjChild,
  hideColumns,
  getQueryParams,
  getEventTypeTitleByCode,
} from '../../../libraries/utils';
// config
import config from '../../../config';
// components
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import SelectInput from '../../../components/forms/SelectInput';
// context
import transactionActions from '../../../context/transactions/actions';
import categoriesActions from '../../../context/categories/actions';
import companyActions from '../../../context/companies/actions';
import usersActions from '../../../context/users/actions';
import Icon from '../../../libraries/icons';
import { history } from '../../../routes';

class Transfers extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      actionCategoryTypes: [],
      // default type
      currentType: config.TYPES.TRANSACTIONS.MERCADO_PAGO,
      sort: {},
      transactions: [],
      users: [],
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [this.t('Transferencias')];
  }
  // Lifecycle methods
  componentDidMount() {
    // used to display user data in some columns
    this.getUsers();
    // used for the select
    this.getActionTypesArray();
    this.getTransactions();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  // API Calls
  getActionTypesArray = async () => {
//    await this.props.onGetActionTypes({ type: config.TYPES.ACTIONS });
//    const { categories } = this.props;
//    if (categories.error) {
//      notify(this.t(categories.error.message));
//    } else {
//      this.setState({ actionCategoryTypes: categories.items });
//    }
  };
  getUsers = async () => {
    await this.props.onGetAllUsers();
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items });
    }
  };
  getTransactions = async (page_num = 1, type = this.state.currentType) => {
    const params = { type }; //page_num,

    params.type = config.TYPES.TRANSACTIONS.BINANCE
    params.status = config.STATUS.TRANSACTIONS.REDEEMED

    const { search, sort } = this.state;
    if (search && search !== '') {
      params.where = {
        custom: {
          sub_type: `%${search.toLowerCase()}`,
          comparison: 'OR'
        }
      };
    }
    if (!isEmptyObject(sort)) {
      params.order_by = sort.order_by;
      params.order_direction = sort.order_direction;
    }
    await this.props.onGetAllTransactions(params);
    const { transactions } = this.props;
    if (transactions.error) {
      notify(this.t(transactions.error.message));
    } else {
      this.setState({ transactions: transactions.items.map(t => { return {...t, ...t.json_data}}) });
    }
  };
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () =>
      this.getTransactions(currentPage)
    );
  };
  // functions used by table
  onSearch = data => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || '' }, () => this.getTransactions());
  };

  searchByType = async type => {
    this.setState({ currentType: type }, () => this.getTransactions());
  };

  searchClear = form => {
    form.change('search', undefined);
    this.onSearch({ search: '' });
  };
  // Other methods
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };


  onView = id => {
    history.push({
      pathname: config.ROUTES.TRANSACTION.replace(':id', id),
      state: {
        fromTransfers: true
      },
    });
  };

  downloadCsv = async () => {
    //const params = {}
    //params.type = config.TYPES.TRANSACTIONS.BINANCE
    //params.status = config.STATUS.TRANSACTIONS.REDEEMED
    //await this.props.onGetAllTransactions(params);
    //const { transactions: trxs } = this.props;
    //if (trxs.error) {
    //  notify(this.t(trxs.error.message));
    //  return
    //}
    //let transactions = this.props.transactions.items.map(t => { return {...t, ...t.json_data}})
    
    const {transactions} = this.state
    const c = ["total","redeem_address"]

    if(transactions.length){
      const rows_ = Object.keys(transactions[0]).filter(o => c.includes(o))
      const columns = transactions.map(t => {
        return Object.keys(t).filter(o => c.includes(o)).map(o => t[o])
      })

      let columns_ = columns.map(c => c.reverse())

      const rows = [
        ["address", "amount"],
        ...columns_
      ];

      let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "transferencias.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    }


  }



  render() {
    const { actionCategoryTypes, currentType, transactions, users, windowWidth } = this.state;
    // Options
    const transactionTypes = selectGeneratorWObjChild(config.TRANSACTIONS_TYPES, 'value', 'label');
    // Made to display ID as readable text
    const actionNameDisplay = row => {
      if (currentType === 'action') {
        const foundItem = actionCategoryTypes.find(item => item.id === row['sub_type']);
        return foundItem?.name;
      }
      return capitalizePhrase(row['sub_type']);
    };
    const displaySourceName = row => {
      /* 
        Actions and initiatives do not have the source data saved in json_data
        So the user data must be found in order to display the name correctly
      */
      if (row['source'].length === 36) {
        const foundUser = users.find(user => user.id === row['source']);
        return capitalizePhrase(foundUser?.name);
      }
      return capitalizePhrase(row['source']);
    };


    const columns = [
      {
        name: this.t('sender email'),
        sortable: true,
        selector: row => row.sender?.email,
        //sortSelector: 'transactions__created_at',
        hide: hideColumns(1000, windowWidth)
      },      {
        name: this.t('sender name'),
        sortable: true,
        selector: row => row.sender?.first_name + ' ' + row.sender?.last_name,
        //sortSelector: 'transactions__created_at',
        hide: hideColumns(1000, windowWidth)
      },
      // {
      //   name: this.t('sender last_name'),
      //   sortable: true,
      //   selector: row => row.sender?.last_name,
      //   //sortSelector: 'transactions__created_at',
      //   hide: hideColumns(1000, windowWidth)
      // },
      {
        name: this.t('event_type'),
        sortable: true,
        selector: row => getEventTypeTitleByCode(row.json_data?.event_type),
      },
      {
        name: this.t('recipient email'),
        sortable: true,
        selector: row => row.recipient?.email,
        //sortSelector: 'transactions__created_at',
        hide: hideColumns(1000, windowWidth)
      },      {
        name: this.t('recipient name'),
        sortable: true,
        selector: row => row.recipient?.first_name + ' ' + row.recipient?.last_name,
        //sortSelector: 'transactions__created_at',
        hide: hideColumns(1000, windowWidth)
      },
      // {
      //   name: this.t('recipient last_name'),
      //   sortable: true,
      //   selector: row => row.recipient?.last_name,
      //   //sortSelector: 'transactions__created_at',
      //   hide: hideColumns(1000, windowWidth)
      // },

      {
        name: this.t('currency'),
        sortable: true,
        selector: row => row.currency,
      },
      {
        name: this.t('BTC price'),
        sortable: true,
        selector: row => row.exchange_rate,
      },
      {
        name: this.t('total BTC'),
        sortable: true,
        selector: row => row.total,
      },
      {
        name: this.t('total USD'),
        sortable: true,
        selector: row => row.json_data?.total_USD,
      },
      {
        name: this.t('total ARS'),
        sortable: true,
        selector: row => row.json_data?.total_ARS,
      },
      {
        name: this.t('status'),
        sortable: true,
        selector: row => row.status,
      },

  
      {
        name: this.t('Created at'),
        sortable: true,
        selector: row => row.created_at.toLocaleDateString(),
        sortSelector: 'transactions__created_at',
        hide: hideColumns(1000, windowWidth)
      },



      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: hideColumns(370, windowWidth),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <div className="flex justify-center align-center rounded-full">
                <span
                  onClick={() => this.onView(row.id)}
                  className="p-3 rounded-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                  <Icon className="h-3 w-3 text-white" name="view_show" />
                </span>

              </div>
            </div>
          );
        }
      }

    ];
    // Layout actions
    const _actions = {
      main: {
        onClick: this.downloadCsv,
        title: this.t('Download as CSV'),
        visible: true,
      },
      form: {
        onSubmit: () => null,

        fields: [
//          {
//            onChange: this.searchByType,
//            title: capitalize(this.t('type')),
//            component: SelectInput,
//            inputClassName: 'mt-3',
//            className: 'w-48 mt-3 mr-2',
//            name: 'type',
//            data: transactionTypes,
//          },

        ]
      },
//      search: {
//        onSearch: this.onSearch,
//        onClearClick: this.searchClear,
//        title: this.t('Search')
//      }
    };
    // Render

    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={_actions}
        loading={this.props.transactions.loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={transactions}
            columns={columns}
            query={this.props.transactions.query}
            getDataFunction={this.getTransactions}
            //pagination
            loading={this.props.transactions.loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}
// Redux state
const mapStateToProps = state => {
  return {
    auth: state.users.auth,
    companies: state.companies.list,
    transactions: state.transactions.list,
    query: state.transactions.list.query,
    categories: state.categories.list,
    users: state.users.list
  };
};
// Dispatch
const mapDispatchToProps = dispatch => {
  return {
    onGetAllTransactions: params => dispatch(transactionActions.getAll(params)),
    onGetAllCompanies: params => dispatch(companyActions.getAll(params)),
    onGetActionTypes: id => dispatch(categoriesActions.getAll(id)),
    onGetAllUsers: params => dispatch(usersActions.getAll(params))
  };
};
// Redux
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Transfers));
