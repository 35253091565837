import types from './types';
import receptions from './services';

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  }
}

const getAll = (params) => {

  function request() { return { type: types.GETALL_REQUEST } }
  function success(payload) { return { type: types.GETALL_SUCCESS, payload } }
  function failure(error) { return { type: types.GETALL_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::RECEPTIONS::getAll', params);
    dispatch(request());
    const response = await receptions.getAll(params, getState().users.auth.token)
    console.log('ACTIONS::RECEPTIONS::getAll::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };  

}

const get = (id) => {
  console.log('ACTIONS::RECEPTIONS::get', id);

  const request = (id) => { return { type: types.GET_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await receptions.get(id, getState().users.auth.token)
    console.log('ACTIONS::RECEPTIONS::get::RESPONSE FROM SERVICE', response);
    if (response.success) {
      console.log('DATA FROM API', response.data.data.date_in);
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}

const saveOrUpdate = (reception) => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::RECEPTIONS::saveOrUpdate', reception);
    dispatch(request(reception));

    let response;
    if (reception.id) {  // editing a existing record
      response = await receptions.update(reception, getState().users.auth.token)
    } else {
      response = await receptions.save(reception, getState().users.auth.token)
    }
    console.log('ACTIONS::RECEPTIONS::saveOrUpdate::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const del = (id) => {
  console.log('ACTIONS::RECEPTIONS::del', id);
 
  function request(id) { return { type: types.DELETE_REQUEST, id } }
  function success(id) { return { type: types.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: types.DELETE_FAILURE, id, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await receptions.del(id, getState().users.auth.token)
    console.log('ACTIONS::RECEPTIONS::del::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(id));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}


const getToProvision = (params) => {

  function request() { return { type: types.TO_PROVISON_GETALL_REQUEST } }
  function success(payload) { return { type: types.TO_PROVISON_GETALL_SUCCESS, payload } }
  function failure(error) { return { type: types.TO_PROVISON_GETALL_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::RECEPTIONS::getToProvision', params);
    dispatch(request());
    const response = await receptions.getToProvision(params, getState().users.auth.token)
    console.log('ACTIONS::RECEPTIONS::getToProvision::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };  

}

const updateMany = (params) => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::RECEPTIONS::updateMany', params);
    dispatch(request(params));

    let response;
    // editing a existing record
    response = await receptions.updateMany(params, getState().users.auth.token)
    console.log('ACTIONS::RECEPTIONS::updateMany::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };
}

const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  updateMany,
  del,
  getToProvision,
};


export default actions;