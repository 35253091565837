import config from '../../config';
import { request } from '../../libraries/request';
import { isDate } from '../../libraries/utils';

const getAll = (params = {}, token) => {
  console.log('SERVICES::RECEPTIONS::getAll', params, token);
  if (params?.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) {
    params.order_by = 'receptions__date_in';
    params.order_direction = 'DESC';
  }

  return request({
    url: config.BASE_API_URL + config.API.RECEPTIONS,
    accessToken: token,
    data: params,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      
      response.data.data.forEach((e, index) => {
        /* console.log(typeof response.data.data[index].date_in);
        response.data.data[index].date_in = response.data.data[index].date_in.toISOString();
        response.data.data[index].start_at = response.data.data[index].start_at.toISOString();
        response.data.data[index].end_at = response.data.data[index].end_at.toISOString(); */
      });
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    console.log(response)
    return response;
  });
};

const save = (data, token) => {
  console.log('SERVICES::RECEPTIONS::save', data, token);
  if (Array.isArray(data)) {
    console.log('SENDING ARRAY OF RECEPTIONS');
    data.forEach((e, index) => {
      data[index].date_in = new Date(e.date_in);

      // DO THIS FOR TWO MORE CASES
      if (data[index].start_at) {
        data[index].start_at = new Date(e.start_at);
      }
      if (data[index].end_at) {
        data[index].end_at = new Date(e.end_at);
      }
    });
  } else {
    data.date_in = data.date_in.toISOString().substring(0, 10);

    // ANOTHER TWO CASES
    if (data.start_at)
      data.start_at = data.start_at.toISOString().substring(0, 10);
    if (data.end_at) data.end_at = data.end_at.toISOString().substring(0, 10);
  }

  return request({
    url: config.BASE_API_URL + config.API.RECEPTIONS,
    accessToken: token,
    method: 'POST',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const update = (data, token) => {
  console.log('SERVICES::RECEPTIONS::update', data, token);

  // FIX DATE ISSUES
  data.date_in = !isDate(data.date_in)
    ? data.date_in.substring(0, 10)
    : data.date_in.toISOString().substring(0, 10);

  if (data.start_at) {
    data.start_at = !isDate(data.start_at)
      ? data.start_at.substring(0, 10)
      : data.start_at.toISOString().substring(0, 10);
  }

  if (data.end_at) {
    data.end_at = !isDate(data.end_at)
      ? data.end_at.substring(0, 10)
      : data.end_at.toISOString().substring(0, 10);
  }

  return request({
    url: config.BASE_API_URL + config.API.RECEPTION.replace('{id}', data.id),
    accessToken: token,
    method: 'PATCH',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const get = (id, token) => {
  console.log('SERVICES::RECEPTIONS::get', id, token);
  return request({
    url: config.BASE_API_URL + config.API.RECEPTION.replace('{id}', id),
    accessToken: token,
    // data: {},
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      /* response.data.data.date_in = response.data.data.date_in.toISOString().substring(0, 10)
      response.data.data.start_at = response.data.data.start_at.toISOString().substring(0, 10)
      response.data.data.end_at = response.data.data.end_at.toISOString().substring(0, 10) */
    }
    return response;
  });
};

const del = (id, token) => {
  console.log('SERVICES::RECEPTIONS::remove', id, token);
  return request({
    url: config.BASE_API_URL + config.API.RECEPTION.replace('{id}', id),
    accessToken: token,
    method: 'DELETE',
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const getToProvision = (params, token) => {
  console.log('SERVICES::RECEPTIONS::getToProvision', params, token);
  if (params?.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }

  return request({
    url: config.BASE_API_URL + config.API.RECEPTION_TO_PROVISION,
    accessToken: token,
    data: params,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.data.forEach((e, index) => {
        response.data.data[index].start_at = e.start_at && new Date(e.start_at);
        response.data.data[index].end_at = e.end_at && new Date(e.end_at);
      });
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  });
};

const updateMany = (data, token) => {
  console.log('SERVICES::RECEPTIONS::update', data, token);
  data.date_in = !isDate(data.date_in)
    ? data.date_in
    : data.date_in.toISOString();
  data.start_at = !isDate(data.start_at)
    ? data.start_at
    : data.start_at.toISOString();
  data.end_at = !isDate(data.end_at) ? data.end_at : data.end_at.toISOString();

  return request({
    url: config.BASE_API_URL + config.API.RECEPTIONS,
    accessToken: token,
    method: 'PATCH',
    data,
  }).then((response) => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const receptions = {
  getAll,
  save,
  get,
  del,
  update,
  updateMany,
  getToProvision,
};

export default receptions;
