import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import filesActions from '../../context/files/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import {
  validateIsfilled,
  getObjectWithJsonDataToFromValues,
} from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      selectedFile: null,
      profile_image: null,
      user: null,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    await this.props.onGetUser({ id: this.props.auth.user.id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const user = getObjectWithJsonDataToFromValues(this.props.user.item, [
        'id',
        'first_name',
        'last_name',
        'phone',
        'email',
        'profile_image',
        'identification',
        'identification_type',
        'gender',
        'birth_date',
      ]);

      this.setState({ user });
    }
  };

  gotoBack = async () => {
    await this.props.onGetToken();
    if (this.props.auth.error) notify(this.t(this.props.auth.error.message));
    history.push(config.ROUTES.USER.PROFILE);
  };

  onSubmit = async (values) => {
    this.setState({ submitting: true });
    const data = { ...values, user_type: config.USER_TYPE.REGULAR };
    await this.props.onUpdate(data);
    const error = this.props.user.error;
    if (error) {
      this.setState({ submitting: false });
      notify(this.t(error.message));
    } else {
      this.gotoBack();
    }
  };

  onFileChange = async (event) => {
    this.setState({ submitting: true });
    this.setState({ selectedFile: event.target.files[0] });
    await this.onFileUpload(event.target.files[0]);
    this.setState({ submitting: false });
  };

  onFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('myFile', file, file.name);
    await this.props.onPictureUpload(formData);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        profile_image: this.props.files.files.fileInfo.location,
      });
      this.props
        .onUpdate({
          id: this.state.user.id,
          user_type: config.USER_TYPE.REGULAR,
          profile_image: this.props.files.files.fileInfo.location,
        })
        .then(() => {
          const error = this.props.user.error;
          if (error) notify(this.t(error.message));
        });
    }
  };

  render() {
    const { user } = this.state;
    if (this.state.profile_image) {
      user.profile_image = this.state.profile_image;
    }
    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs,
          left: { icon: 'arrow_left', action: () => this.gotoBack() },
        }}
        container={{ className: 'px-8' }}
      >
        <ToastContainer />
        <section className="px-3 pt-2 pb-8">
          <div className="">
            <div className="px-4 py-3 rounded-lg">
              <div className="rounded-full bg-primary-focus overflow-hidden flex text-center w-28 h-28">
                {user && user.profile_image ? (
                  <img
                    className="w-full h-auto"
                    src={user && user.profile_image}
                    alt=""
                  />
                ) : (
                  <span className=" text-white text-2xl">
                    {user && user.name}
                  </span>
                )}
              </div>
              <label className="cursor-pointer mt-6">
                <span className="btn btn-secondary btn-sm mt-3">
                  {this.t('Upload a photo')}
                </span>
                <input
                  type="file"
                  className="hidden"
                  multiple="multiple"
                  accept="accept"
                  onChange={this.onFileChange}
                />
              </label>
            </div>
          </div>
        </section>
        <section className="px-4 -mt-5">
          <Form initialValues={user || {}} onSubmit={this.onSubmit}>
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg">
                <div className="card white p-4 mb-6">
                  <div className="w-full mb-3 md:mb-0">
                    <Field
                      name="first_name"
                      component={TextInput}
                      placeholder={this.t('First name')}
                      label={this.t('First name')}
                      validate={required}
                      inputClassName="input-bordered shadow-none"
                    />
                  </div>
                  <div className="w-full mb-3 md:mb-0">
                    <Field
                      name="last_name"
                      component={TextInput}
                      placeholder={this.t('Last name')}
                      label={this.t('Last name')}
                      validate={required}
                      inputClassName="input-bordered shadow-none"
                    />
                  </div>
                  <div className="w-full mb-4">
                    <Button
                      className="btn-primary btn-block"
                      title={this.t('Save')}
                      onClick={handleSubmit}
                      disabled={this.state.submitting || pristine}
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        </section>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.files,
    user: state.users.current,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserInfo));
