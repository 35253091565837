import types from './types';
import initiativeAssistants from './services';

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  };
};

const getAll = (params) => {
  function request() {
    return { type: types.GETALL_REQUEST };
  }
  function success(payload) {
    return { type: types.GETALL_SUCCESS, payload };
  }
  function failure(error) {
    return { type: types.GETALL_FAILURE, error };
  }

  return async (dispatch, getState) => {
    console.log('ACTIONS::INITIATIVE_ASSISTANTS::getAll', params);
    dispatch(request());
    const response = await initiativeAssistants.getAll(params, getState().users.auth.token);
    console.log(
      'ACTIONS::INITIATIVE_ASSISTANTS::getAll::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const get = (id) => {
  console.log('ACTIONS::INITIATIVE_ASSISTANTS::get', id);
  const request = (id) => {
    return { type: types.GET_REQUEST, id };
  };
  const success = (payload) => {
    return { type: types.GET_SUCCESS, payload };
  };
  const failure = (error) => {
    return { type: types.GET_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await initiativeAssistants.get(id, getState().users.auth.token);
    console.log(
      'ACTIONS::INITIATIVE_ASSISTANTS::get::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const saveOrUpdate = (initiativeAssistant, initiativeID) => {
  function request(payload) {
    return { type: types.SAVE_REQUEST, payload };
  }
  function success(payload) {
    return { type: types.SAVE_SUCCESS, payload };
  }
  function failure(error) {
    return { type: types.SAVE_FAILURE, error };
  }

  return async (dispatch, getState) => {
    console.log('ACTIONS::INITIATIVE_ASSISTANTS::saveOrUpdate', initiativeAssistant, initiativeID);
    dispatch(request(initiativeAssistant));

    let response;
    if (initiativeAssistant.id) {
      // editing a existing record
      response = await initiativeAssistants.update(initiativeAssistant, getState().users.auth.token);
    } else {
      response = await initiativeAssistants.save(initiativeAssistant, getState().users.auth.token, initiativeID);
    }
    console.log(
      'ACTIONS::INITIATIVE_ASSISTANTS::saveOrUpdate::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const del = (initiativeID, assistantID) => {
  console.log('ACTIONS::INITIATIVE_ASSISTANTS::del', initiativeID, assistantID);
  function request(initiativeID) {
    return { type: types.DELETE_REQUEST, initiativeID };
  }
  function success(initiativeID) {
    return { type: types.DELETE_SUCCESS, initiativeID };
  }
  function failure(initiativeID, error) {
    return { type: types.DELETE_FAILURE, initiativeID, error };
  }

  return async (dispatch, getState) => {
    dispatch(request(initiativeID));
    let response = await initiativeAssistants.del(initiativeID, getState().users.auth.token, assistantID);
    console.log(
      'ACTIONS::INITIATIVE_ASSISTANTS::del::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(initiativeID));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  del,
};

export default actions;