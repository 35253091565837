import { combineReducers } from 'redux';
import types from './types';

export const booking_base = (state = {
  item: null,
  loading: false,
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BOOKINGS::booking::', action);
  switch (action.type) {
    case types.GET_REQUEST:
    case types.SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_FAILURE:
    case types.SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SAVE_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: null,
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: null,
      };
    case types.AVAILABILITY_SUCCESS:
      return {
        ...state,
        item: action.payload.data.booking,
        loading: false,
        error: null,
      };
    case types.CLEAR_CURRENT:
      return {
        ...state,
        item: null,
      }
    default:
      return state
  }
}


export const bookings_base = (state = {
  loading: false,
  items: [],
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BOOKINGS::bookings::', action);
  switch (action.type) {
    case types.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        query: action.payload.query
      };
    case types.GETALL_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    case types.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.id
            ? { ...item, deleting: true }
            : item
        )
      };
    case types.DELETE_SUCCESS:
      // remove deleted item from state
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.id)
      };
    case types.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to item 
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            // make copy of product without 'deleting:true' property
            const { deleting, ...itemCopy } = item;
            // return copy of product with 'deleteError:[error]' property
            return { ...itemCopy, deleteError: action.error };
          }
          return item;
        })
      };
  
    default:
      return state
  }
}


const bookings_availability = (state = {
  loading: false,
  items: [],
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BOOKINGS::bookings_avalability::', action);
  switch (action.type) {
    case types.AVAILABILITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data.availability,
      };
    case types.AVAILABILITY_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}


/**
 *  SLOTS 
 */

const booking_slots = (state = {
  item: null,
  loading: false,
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BOOKINGS::booking_slots::', action);
  switch (action.type) {
    case types.GET_SLOTS_REQUEST:
    case types.SAVE_SLOTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SLOTS_FAILURE:
    case types.SAVE_SLOTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SAVE_SLOTS_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: null,
      };
    case types.GET_SLOTS_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: null,
      };
    case types.CLEAR_SLOTS_CURRENT:
      return {
        ...state,
        item: null,
      }
    default:
      return state
  }
}


const bookings_slots = (state = {
  loading: false,
  items: [],
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BOOKINGS::bookings_slots::', action);
  switch (action.type) {
    case types.UPDATEALL_SLOTS_REQUEST:
    case types.GETALL_SLOTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.UPDATEALL_SLOTS_SUCCESS:
    case types.GETALL_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data || [],
        query: action.payload.query
      };
    case types.UPDATEALL_SLOTS_FAILURE:
    case types.GETALL_SLOTS_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    case types.DELETE_SLOTS_REQUEST:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.id
            ? { ...item, deleting: true }
            : item
        )
      };
    case types.DELETE_SLOTS_SUCCESS:
      // remove deleted item from state
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.id)
      };
    case types.DELETE_SLOTS_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to item 
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            // make copy of product without 'deleting:true' property
            const { deleting, ...itemCopy } = item;
            // return copy of product with 'deleteError:[error]' property
            return { ...itemCopy, deleteError: action.error };
          }

          return item;
        })
      };
  
    default:
      return state
  }
}


/**
 *  INACTIVITY 
 */

const booking_inactivity = (state = {
  item: null,
  loading: false,
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BOOKINGS::booking_inactivity::', action);
  switch (action.type) {
    case types.SET_INACTIVITY_ARRAY:
      return {
        ...state,
        item: action
      }
    case types.GET_INACTIVITY_REQUEST:
    case types.SAVE_INACTIVITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_INACTIVITY_FAILURE:
    case types.SAVE_INACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SAVE_INACTIVITY_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: null,
      };
    case types.GET_INACTIVITY_SUCCESS:
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        error: null,
      };
    case types.CLEAR_INACTIVITY_CURRENT:
      return {
        ...state,
        item: null,
      }
    default:
      return state
  }
}


const bookings_inactivity = (state = {
  loading: false,
  items: [],
  error: null,
}, action) => {
  // console.log('::::::REDUCER::BOOKINGS::bookings_inactivity::', action);
  switch (action.type) {
    case types.GETALL_INACTIVITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GETALL_INACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        query: action.payload.query
      };
    case types.GETALL_INACTIVITY_FAILURE:
      return { 
        ...state,
        loading: false,
        error: action.error
      };
    case types.DELETE_INACTIVITY_REQUEST:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === action.id
            ? { ...item, deleting: true }
            : item
        )
      };
    case types.DELETE_INACTIVITY_SUCCESS:
      // remove deleted item from state
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.id)
      };
    case types.DELETE_INACTIVITY_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to item 
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            // make copy of product without 'deleting:true' property
            const { deleting, ...itemCopy } = item;
            // return copy of product with 'deleteError:[error]' property
            return { ...itemCopy, deleteError: action.error };
          }
          return item;
        })
      };
  
    default:
      return state
  }
}

export const base = combineReducers({
  current: booking_base,
  list: bookings_base
});

const slots = combineReducers({
  current: booking_slots,
  list: bookings_slots
});

const inactivity = combineReducers({
  current: booking_inactivity,
  list: bookings_inactivity
});


export default combineReducers({
  base,
  slots,
  inactivity,
  availability: bookings_availability
});

