import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize } from '../../../libraries/utils';
// config
import config from '../../../config';
// components
import FormLayout from '../../../components/forms/FormLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import TextareaInput from '../../../components/forms/TextareaInput';
// context
import transactionActions from '../../../context/transactions/actions';
import internalsActions from '../../../context/internals/actions';

import Swal from 'sweetalert2';

import Button from '../../../components/commons/Button';

import paymentsActions from '../../../context/payments/actions';


const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});

class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      editing: false,
      remove: false,
      transaction: {},
      submitting: false,
      titles: '',
      view: false,
      breadcrumbs: []
    };
    this.submit = null;
    this.reset = null;
    this.titles = this.t('Transaction');
  }
  // ** Lifecycle methods
  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Transaction')
      });

      const locationstate = this.props.location.state;
      if (locationstate?.fromTransfers) {
        this.setState({canChangeStatus: true})
      }
      // this.breadcrumbs = [this.t('Transaction'), this.t('View')];
      this.getTranscation(params.id);
    } else {
      
    }
  }
  componentWillUnmount() {
    this.props.onClearCurrent();
  }
  // API Calls
  getTranscation = async id => {
    await this.props.onGet(id);
    const { transaction } = this.props;
    if (transaction.error) {
      notify(this.t(transaction.error.message));
    } else {
      console.log("ttt", transaction.item)
      let mp_json_init =  transaction.item?.json_data?.mercadopago_init
      let mp_json_end  = transaction.item?.json_data?.mercadopago_end
      let binance_json =  transaction.item?.json_data?.binance_end


      let mp_error  = transaction.item?.json_data?.mercadopago_error
      let mp_error_capture  = transaction.item?.json_data?.mercadopago_error_capture


      if(typeof mp_json_init === 'string') mp_json_init = JSON.parse(mp_json_init)
      if(typeof mp_json_end === 'string') mp_json_end = JSON.parse(mp_json_end)
      if(typeof binance_json === 'string') binance_json = JSON.parse(binance_json)

      console.log("transaction_type", transaction.item.type)

      let mp_payment = null
      let mp_payment_status = null
      let mp_payment_status_detail = null
      let mp_payment_id = null

      if(transaction.item.status === 'redeemed') {
        this.setState({ breadcrumbs: [this.t('Tranferencia'), this.t('View')]})
      } else {
        this.setState({ breadcrumbs: [this.t('Operación'), this.t('View')]})
      }

      if(transaction.item.type === config.TYPES.TRANSACTIONS.MERCADO_PAGO && transaction.item.status === "created"){
        await this.props.onSearchPayment(transaction.item.id)
        //console.log("transaction_type", this.props.payments)
        if(this.props.payments.data?.length > 0){
          console.log("transaction_type", this.props.payments.data)
          mp_payment = this.props.payments.data[0]

          mp_payment_status = mp_payment.status
          mp_payment_status_detail = mp_payment.status_detail
          mp_payment_id = mp_payment.id
          console.log("transaction_type", mp_payment_status, mp_payment_status_detail, mp_payment_id)
        }
      }

      //'payment_id', 'external_reference', 'redirect_uri'
      if (transaction.item.json_data.total_ARS && transaction.item.json_data.total_USD) {
        transaction.item.USDprice = (transaction.item.json_data.total_ARS / (transaction.item.json_data.total_USD)).toFixed(2);
      }
      transaction.item = {
        ...transaction.item,
        ...transaction.item.json_data,
        sender_name: transaction.item.json_data.sender.first_name + ' ' + transaction.item.json_data.sender.last_name,
        sender_email: transaction.item.json_data.sender.email,
        recipient_name: transaction.item.json_data.recipient.first_name + ' ' + transaction.item.json_data.recipient.last_name,
        recipient_email: transaction.item.json_data.recipient.email,
      }
      
      this.setState({ 
        transaction: transaction.item, 
        transaction_type: transaction.item.type, 
        mp_json_init, 
        mp_json_end, 
        binance_json,
        mp_error,
        mp_error_capture,
        mp_payment,
        mp_payment_status,
        mp_payment_status_detail,
        mp_payment_id,
      });
    }
  };

  resolvePayment = async () => {
    this.setState({loading: true})
    //collection_id=55431977764&collection_status=approved&payment_id=55431977764&status=approved&external_reference=1d5c374f-ac65-480a-a1d3-a17e0cbf5e05&payment_type=account_money&merchant_order_id=8052141678&preference_id=1203442654-99d7c2ba-76f6-4d89-bbee-940fdf6845fb&site_id=MLA&processing_mode=aggregator&merchant_account_id=null
    //'payment_id', 'external_reference', 'redirect_uri'
    const data = {
      external_reference: this.state.transaction.id,
      redirect_uri: config.FRONTEND_URL,
      payment_id: this.state.mp_payment_id,
    }
    
    await this.props.onCapturePayment(data)
    this.getTranscation( this.state.transaction.id);
    this.setState({loading: false})
  }

  onSubmit = async values => {
    
  };


  onCompleteTransfer = async () => {
    const {value: swalResponse} = await customSwal
      .fire({
        title: this.t("¿Completar transferencia?"),
        text: this.t("La transferencia ya no aparecerá en el listado"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })

    if(swalResponse){
      const data = {
        id: this.state.transaction.id,
        status: config.STATUS.TRANSACTIONS.COMPLETED,
      };

      await this.props.onUpdateTransaction(data);
      const error = this.props.transaction.error;
      if (error) {
        notify(this.t(error.message));
        this.setState({ submitting: false });
      } else {
        this.sendMailUser(this.props.transaction.item);
        history.push(config.ROUTES.TRANSACTIONS_TRANSFERS)
      }
    }
  };

  sendMailUser = async (transaction) => {
    console.log("TRANSACTION",transaction)
    let subject = "Tus Bitcoins ya estan en tu wallet"
    let mailData = {
      template: "btc_generic",
      locale: "es",
      to: transaction.json_data.recipient.email,
      first_name: transaction.json_data.recipient.first_name,
      last_name:  transaction.json_data.recipient.last_name,
      params: {
          subject,
          title: "¡Felicidades!",
          text: "Hemos transferido tus Bitcoins a tu wallet con éxito!",
      }
    }
    
    await this.props.onSendMail(mailData);
    const internals = this.props.internals;
    if (internals.error) {
      notify(this.t(internals.error.message));
    }
  }

  render() {
    const { editing, transaction, submitting, view, canChangeStatus, mp_payment, mp_payment_status, mp_payment_status_detail } = this.state;
    console.log(transaction)
    // Layout settings
    const actions = {
      main: {
        //canChangeStatus
        
      },
      secondaries: [
        {
          onClick: this.onCompleteTransfer,
          title: this.t('Completar'),
          //icon: !user.enabled ? 'view_show' : 'view_hide',
          disabled: submitting,
          visible: canChangeStatus
        },
        {
          onClick: e => {
            if(canChangeStatus){
              history.push(config.ROUTES.TRANSACTIONS_TRANSFERS)
            } else {
              history.push(config.ROUTES.TRANSACTIONS)
            }
          },
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => {
            this.resolvePayment()
          },
          title: this.t('Resolver'),
          //icon: 'cheveron_left',
          disabled: !(mp_payment_status === "approved" && transaction.status === "created"),
          visible: true,
          className: 'btn-accent'
        },
      ]
    };

    // ** Render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.state.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={this.props.transaction.loading || this.state.loading}>


          <Form
            initialValues={transaction || {}}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              values.details = values.details || '';
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                {mp_payment && <div className="w-full px-3 mb-3 md:mb-0 text-center">
                  <h3>Chequeo mercadopago</h3>
                  <br/>
                  <p>Estado de pago: <span className="font-bold text-lg">{mp_payment_status}</span></p>
                  <p>Detalle: <span className="font-bold text-lg">{mp_payment_status_detail}</span></p>
                  <br/>
                 </div>}
                <div className="w-full px-3 mb-3 md:mb-0 flex gap-6">
                    <Field
                      name="sender_name"
                      component={TextInput}
                      placeholder={this.t('Sender')}
                      label={this.t('Sender')}
                      readOnly={view && !editing}
                      className='w-full'
                    />
                    <Field
                      name="sender_email"
                      component={TextInput}
                      placeholder={this.t('Sender email')}
                      label={this.t('Sender email')}
                      readOnly={view && !editing}
                      className='w-full'
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0 flex gap-6">
                      <Field
                        name="recipient_name"
                        component={TextInput}
                        placeholder={this.t('Recipient')}
                        label={this.t('Recipient')}
                        readOnly={view && !editing}
                        className='w-full'
                      />
                      <Field
                        name="recipient_email"
                        component={TextInput}
                        placeholder={this.t('Recipient email')}
                        label={this.t('Recipient email')}
                        readOnly={view && !editing}
                        className='w-full'
                      />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="type"
                        component={TextInput}
                        placeholder={this.t('Type')}
                        label={this.t('Type')}
                        readOnly={view && !editing}
                      />
                  </div>

                  <div className="w-full px-3 mb-3 md:mb-0">
                      <Field
                        name="status"
                        component={TextInput}
                        placeholder={this.t('Status')}
                        label={this.t('Status')}
                        readOnly={view && !editing}
                      />
                  </div>

                  <div className="w-full px-3 mb-3 md:mb-0">
                      {'Total'}
                      <div className='flex gap-6'>
                        <Field
                          name="total"
                          component={TextInput}
                          placeholder={this.t(transaction?.currency)}
                          label={this.t(transaction?.currency)}
                          readOnly={view && !editing}
                          className='w-full'
                        />
                        {transaction.type === config.TYPES.TRANSACTIONS.MERCADO_PAGO &&
                          <Field
                            name="taxed_amount"
                            component={TextInput}
                            placeholder={this.t('USD')}
                            label={this.t('USD')}
                            readOnly={view && !editing}
                            className='w-full'
                          />
                        }
                        {transaction.type === config.TYPES.TRANSACTIONS.BINANCE &&
                          <>
                            <Field
                              name="total_ARS"
                              component={TextInput}
                              placeholder={capitalize(this.t('ARS'))}
                              label={capitalize(this.t('ARS'))}
                              readOnly={view && !editing}
                              className='w-full'
                            />
                            <Field
                              name="total_USD"
                              component={TextInput}
                              placeholder={capitalize(this.t('USD'))}
                              label={capitalize(this.t('USD'))}
                              readOnly={view && !editing}
                              className='w-full'
                            />
                          </>
                        }
                      </div>
                      
                  </div>

                  <div className="w-full px-3 mb-3 md:mb-0 flex gap-6">
                      <Field
                        name="exchange_rate"
                        component={TextInput}
                        placeholder={capitalize(this.t('price'))}
                        label={transaction.type === config.TYPES.TRANSACTIONS.BINANCE ? `${capitalize(this.t('price'))} BTC (USD)` : `${capitalize(this.t('price'))} USD (ARS)`}
                        readOnly={view && !editing}
                        className='w-full'
                      />
                      {transaction.type === config.TYPES.TRANSACTIONS.BINANCE &&
                        <Field
                          name="USDprice"
                          component={TextInput}
                          placeholder={capitalize(this.t('price'))}
                          label={`${capitalize(this.t('price'))} USD (ARS)`}
                          readOnly={view && !editing}
                          className='w-full'
                        />
                      }
                  </div>
                  
                  {transaction.type === config.TYPES.TRANSACTIONS.MERCADO_PAGO && <div className="w-full px-3 mb-3 md:mb-0">
                      {'Descuento'}
                      <div className='flex gap-6'>
                        <Field
                          name="discount"
                          component={TextInput}
                          placeholder={this.t("Descuento")}
                          label={this.t("Descuento")}
                          readOnly={view && !editing}
                          className='w-full'
                        />
                       
                          <Field
                            name="discount_code"
                            component={TextInput}
                            placeholder={this.t("discount_code")}
                            label={this.t("discount_code")}
                            readOnly={view && !editing}
                            className='w-full'
                          />
                      </div>
                  </div>}
                  

                </FormLayout>
              );
            }}
          </Form>

          {/*<h1>Error mercadopago</h1>*/}
          {/*this.state.mp_error ? <><h1>mp_error</h1><pre>{JSON.stringify(JSON.parse(this.state.mp_error, 0, 2))}</pre></> : <p>Null</p>*/}
          {/*this.state.mp_error_capture ? <><h1>mp_error_capture</h1><pre>{JSON.stringify(JSON.parse(this.state.mp_error_capture), 0, 2)}</pre></> : <p>Null</p>*/}

          {this.state.mp_json_init && <><h1>mercadopago_init</h1><pre>{JSON.stringify(this.state.mp_json_init, 0, 2)}</pre></>}
          {this.state.mp_json_end && <><h1>mercadopago_end</h1><pre>{JSON.stringify(this.state.mp_json_end, 0, 2)}</pre></>}
          {this.state.binance_json && <><h1>binance_end</h1><pre>{JSON.stringify(this.state.binance_json, 0, 2)}</pre></>}
          
          
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    transaction: state.transactions.current,
    payments: state.payments.data,
    internals: state.internals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGet: (id) => dispatch(transactionActions.get(id)),
    onUpdateTransaction: (params) => dispatch(transactionActions.saveOrUpdate(params)),
    onClearCurrent: () => dispatch(transactionActions.clearCurrent()),
    onSearchPayment: id => dispatch(paymentsActions.searchPayment(id)),
    onCapturePayment: data => dispatch(paymentsActions.capturePayment(data)),
    onSendMail: (params) => dispatch(internalsActions.sendMail(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Transaction));
