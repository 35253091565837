const types = {
  CLEAR_CURRENT: 'RECEPTIONS_CLEAR_CURRENT',
  GET_REQUEST: 'RECEPTIONS_GET_REQUEST',
  GET_SUCCESS: 'RECEPTIONS_GET_SUCCESS',
  GET_FAILURE: 'RECEPTIONS_GET_FAILURE',

  SAVE_REQUEST: 'RECEPTIONS_SAVER_REQUEST',
  SAVE_SUCCESS: 'RECEPTIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'RECEPTIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'RECEPTIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'RECEPTIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'RECEPTIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'RECEPTIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'RECEPTIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'RECEPTIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'RECEPTIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RECEPTIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RECEPTIONS_UPDATE_FAILURE',   

  TO_PROVISON_GETALL_REQUEST: 'RECEPTIONS_TO_PROVISON_GETALL_REQUEST',
  TO_PROVISON_GETALL_SUCCESS: 'RECEPTIONS_TO_PROVISON_GETALL_SUCCESS',
  TO_PROVISON_GETALL_FAILURE: 'RECEPTIONS_TO_PROVISON_GETALL_FAILURE',

};

export default types