import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import { capitalize } from '../../../libraries/utils';
// config
import config from '../../../config';
// components
import FormLayout from '../../../components/forms/FormLayout';
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelWindow from '../../../components/PanelWindow';
import TextInput from '../../../components/forms/TextInput';
import TextareaInput from '../../../components/forms/TextareaInput';
// context
import settingActions from '../../../context/settings/actions';

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      editing: false,
      remove: false,
      setting: {},
      submitting: false,
      titles: '',
      view: false
    };
    this.submit = null;
    this.reset = null;
    this.breadcrumbs = [this.t('Settings'), this.t('New')];
    this.titles = this.t('Settings');
  }
  // ** Lifecycle methods
  componentDidMount() {
    const params = this.props.match.params;
    if (params.id) {
      this.setState({
        remove: true,
        view: true,
        titles: this.t('Edit Setting')
      });
      this.breadcrumbs = [this.t('Settings'), this.t('Edit')];
      this.getSetting(params.id);
    } else {
      this.setState({ titles: this.t('New Setting') });
    }
  }
  componentWillUnmount() {
    this.props.onClearCurrent();
  }
  // API Calls
  getSetting = async id => {
    await this.props.onGet(id);
    const { setting } = this.props;
    if (setting.error) {
      notify(this.t(setting.error.message));
    } else {
      this.setState({ setting: setting.item });
    }
  };
  onSubmit = async values => {
    values.owner = 'system';
    await this.props.onSaveOrUpdate({
      ...values
    });
    const { setting } = this.props;
    if (setting.error) {
      notify(this.t(setting.error.message));
    } else {
      history.push(config.ROUTES.SETTINGS);
    }
  };
  render() {
    // **Destructuring objects for direct use of values
    const { editing, setting, submitting, view } = this.state;
    // Layout settings
    const actions = {
      main: {
        onClick: e => this.submit(e),
        title: this.t('Save'),
        icon: 'checkmark',
        disabled: submitting || (view && !editing),
        checkPermissions: 'insert'
      },
      secondaries: [
        {
          onClick: e => history.push(config.ROUTES.SETTINGS),
          title: this.t('Go Back'),
          icon: 'cheveron_left',
          disabled: submitting,
          visible: true,
          className: 'btn-accent'
        },
        {
          onClick: e => this.setState({ editing: true }),
          title: this.t('Edit'),
          icon: 'edit_pencil',
          disabled: submitting,
          visible: view && !editing,
          checkPermissions: 'update'
        },
        {
          onClick: e => {
            this.reset();
            this.setState({ editing: false });
          },
          title: this.t('Cancel'),
          icon: 'edit_pencil',
          disabled: submitting || !editing,
          visible: editing
        }
      ]
    };
    // ** Render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}>
        <ToastContainer />
        <PanelWindow outerTitle={this.titles} loading={this.props.setting.loading}>
          <Form
            initialValues={setting || {}}
            onSubmit={this.onSubmit}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              this.submit = handleSubmit;
              this.reset = form.reset;
              values.details = values.details || '';
              return (
                <FormLayout form={form} onSubmit={this.onSubmit} values={values}>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="name"
                      component={TextInput}
                      placeholder={this.t('Name')}
                      label={this.t('Name')}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="code"
                      component={TextInput}
                      placeholder={this.t('Code')}
                      label={this.t('Code')}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full px-3 b-3 md:mb-0">
                    <Field
                      name="type"
                      component={TextInput}
                      placeholder={capitalize(this.t('type'))}
                      label={capitalize(this.t('type'))}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full px-3 b-3 md:mb-0">
                    <Field
                      name="data_type"
                      component={TextInput}
                      placeholder={this.t('Data type')}
                      label={this.t('Data type')}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="value"
                      component={TextInput}
                      placeholder={this.t('Value')}
                      label={this.t('Value')}
                      readOnly={view && !editing}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="details"
                      component={TextareaInput}
                      placeholder={capitalize(this.t('details'))}
                      label={capitalize(this.t('details'))}
                      readOnly={view && !editing}
                    />
                  </div>
                </FormLayout>
              );
            }}
          </Form>
        </PanelWindow>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    setting: state.settings.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveOrUpdate: (params) => dispatch(settingActions.saveOrUpdate(params)),
    onGet: (id) => dispatch(settingActions.get(id)),
    onRemove: (id) => dispatch(settingActions.del(id)),
    onClearCurrent: () => dispatch(settingActions.clearCurrent()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Setting));
