import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import Icon from '../../../libraries/icons';
import { capitalize, capitalizePhrase, hideColumns } from '../../../libraries/utils';
// config
import config from '../../../config';
// components
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';
import PrivateButton from '../../../components/commons/PrivateButton';
// context
import userActions from '../../../context/users/actions';
// custom swal
const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text',
  },
  buttonsStyling: false,
  background: '#fff',
});
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      users: [],
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [this.t('Users')];
  }
  // ** Lifecycle methods
  componentDidMount() {
    this.getUsers();
    // ** Get window width
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener('resize', this.handleResize);
  }
  // API calls
  getUsers = async (page_num = 1) => {
    const params = { page_num };
    const { search, sort } = this.state;
    // ** Search
    if (search && search !== '') {
      params.where = { json_data: [] };
      if (search && search !== '') {
        params.where = { custom: { name: `%${search.toLowerCase()}` } };
      }
    }
    // ** Sort
    if (sort) {
      params.order_by = sort.order_by;
      params.order_direction = sort.order_direction;
    }
    await this.props.onGetAll(params);
    const { users } = this.props;
    if (users.error) {
      notify(this.t(users.error.message));
    } else {
      this.setState({ users: users.items.map(u => {return {...u, ...u.json_data}}) });
    }
  };
  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () =>
      this.getUsers(currentPage)
    );
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { users } = this.props;
    customSwal
      .fire({
        title: this.t(
          resetRows ? capitalize(this.t('delete multiple elements')) : this.t('¿Are you sure?')
        ),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          users.loading = true;
          // Remove multiple
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // One by one
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (users.error) {
              notify(this.t(users.error.message));
            } else {
              // Reload
              this.getUsers(this.state.currentPage);
            }
          } else {
            // Remove one
            await this.props.onRemove(id);
            if (users.error) {
              notify(this.t(users.error.message));
            } else {
              // Reload
              this.getUsers(this.state.currentPage);
            }
          }
        }
      });
  };
  // ** Redirection to edit user route
  onEdit = id => {
    history.push(config.ROUTES.USERS_EDIT.replace(':id', id));
  };
  // ** Redirection to new user route
  onNew = () => {
    history.push(config.ROUTES.USERS_NEW);
  };
  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || '' }, () => this.getUsers());
  };
  // ** Other methods
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  onDisableEnableUser = async (id, enabled) => {
    const data = {
      id,
      enabled,
    };
    await this.props.onSaveUser(data);
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
      this.setState({ submitting: false });
    } else {
      await this.getUsers()
    }
  };

  render() {
    // **Destructuring objects for direct use of values
    const { windowWidth, users } = this.state;
    // ** Columns configuration for our data table
    const columns = [
      {
        name: this.t('First name'),
        sortable: true,
        hide: 'md',
        maxWidth: '200px',
        selector: row => capitalizePhrase(row['first_name']),
        sortSelector: 'name'
      },
      {
        name: this.t('Last name'),
        sortable: true,
        hide: 'md',
        maxWidth: '200px',
        selector: row => capitalizePhrase(row['last_name']),
        sortSelector: 'name'
      },
//      {
//        name: this.t('Username'),
//        sortable: true,
//        selector: row => row['username'],
//        sortSelector: 'username'
//      },
      {
        name: this.t('Email'),
        sortable: true,
        hide: 'md',
        selector: row => row['email'],
        sortSelector: 'email',
      },
      {
        name: this.t('Enabled'),
        hide: 'md',
        selector: row => (
          <p className={`${row.enabled ? 'bg-green-100' : 'bg-red-100'} rounded-box px-2 py-1`}>
            {row.enabled ? 'Active' : 'Inactive'}
          </p>
        ),
        maxWidth: '200px'
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: hideColumns(370, windowWidth),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <div className="flex justify-center align-center rounded-full">
                <span
                  onClick={() => this.onDisableEnableUser(row.id, !row.enabled)}
                  className="p-3 rounded-l-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                  <Icon className="h-3 w-3 text-white" name={row.enabled ? "cancel" : "checkmark"} />
                </span>

              </div>
            </div>
          );
        }
      }
    ];
    // ** Main and secondary actions of layout
    const actions = {
      search: { onSearch: this.onSearch, onClearClick: this.searchClear, title: this.t('Search') }
    };
    // ** Render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={this.props.users.loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={users}
            columns={columns}
            //selectableRows={windowWidth > 600}
            query={this.props.users.query}
            getDataFunction={this.getUsers}
            //multipleDeleteFunction={this.onRemove}
            pagination
            loading={this.props.users.loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}
// Redux state
const mapStateToProps = (state) => {
  return {
    users: state.users.list,
    user: state.users.current,
  };
};
// Dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAll: (params) => dispatch(userActions.getAll(params)),
    onRemove: (id) => dispatch(userActions.del(id)),
    onSaveUser: params => dispatch(userActions.saveOrUpdate(params)),
  };
};
// Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Users));
