import React from 'react';
// libraries
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../../../routes';
import { ToastContainer, notify } from '../../../libraries/notifications';
import Icon from '../../../libraries/icons';
import { capitalize, hideColumns } from '../../../libraries/utils';
// config
import config from '../../../config';
// components
import LayoutWithSidebar from '../../../components/layout/LayoutWithSidebar';
import PanelLayout from '../../../components/PanelLayout';
import StyledDataTable from '../../../components/commons/StyledDataTable';
import Swal from 'sweetalert2';
import PrivateButton from '../../../components/commons/PrivateButton';
// context
import settingsActions from '../../../context/settings/actions';
// custom swal
const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text',
  },
  buttonsStyling: false,
  background: '#fff',
});
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      settings: [],
      windowWidth: window.innerWidth
    };
    this.breadcrumbs = [this.t('Settings')];
  }

  // ** Lifecycle methods
  componentDidMount() {
    this.getSettings();
    // ** Get window width
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    // ** Get window width on resize
    window.removeEventListener('resize', this.handleResize);
  }
  // API Calls
  getSettings = async (page_num = 1) => {
    const params = { page_num };
    const { search, sort } = this.state;
    // Search
    if (search && search !== '') {
      params.where = { json_data: [] };
      if (search && search !== '') {
        params.where = { custom: { name: `%${search.toLowerCase()}` } };
      }
    }
    // Sort
    if (sort) {
      params.order_by = sort.order_by;
      params.order_direction = sort.order_direction;
    }
    await this.props.onGetAll(params);
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
    } else {
      this.setState({ settings: settings.items });
    }
  };
  // ** Custom sort function
  customSort = async (field, order, currentPage) => {
    this.setState({ sort: { order_by: field, order_direction: order } }, () =>
      this.getSettings(currentPage)
    );
  };
  //  ** Delete function
  onRemove = (id, resetRows) => {
    const { settings } = this.props;
    customSwal
      .fire({
        title: this.t(
          resetRows ? capitalize(this.t('delete multiple elements')) : this.t('¿Are you sure?')
        ),
        text: this.t("You can't roll back this operation"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel')
      })
      .then(async result => {
        if (result.isConfirmed) {
          settings.loading = true;
          // Remove multiple
          if (resetRows) {
            for (let index = 0; index < id.length; index++) {
              const element = id[index];
              // One by one
              await this.props.onRemove(element.id);
            }
            resetRows();
            if (settings.error) {
              notify(this.t(settings.error.message));
            } else {
              // Reload
              this.getSettings(this.state.currentPage);
            }
          } else {
            // Remove one
            await this.props.onRemove(id);
            if (settings.error) {
              notify(this.t(settings.error.message));
            } else {
              // Reload
              this.getSettings(this.state.currentPage);
            }
          }
        }
      });
  };
  // ** Redirection to edit setting route
  onEdit = id => {
    history.push(config.ROUTES.SETTINGS_EDIT.replace(':id', id));
  };
  // ** Redirection to new setting route
  onNew = () => {
    history.push(config.ROUTES.SETTINGS_NEW);
  };
  // ** Function to look for specific data in the table
  onSearch = data => {
    if (this.state.search !== data.search)
      this.setState({ search: data.search || '' }, () => this.getSettings());
  };
  // Other methods
  // ** Function to handle resize
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  render() {
    // **Destructuring objects for direct use of values
    const { windowWidth, settings } = this.state;
    // ** Columns configuration for our data table
    const columns = [
      {
        name: this.t('Name'),
        sortable: true,
        selector: row => row['name'],
        sortSelector: 'name',
        maxWidth: windowWidth < 900 ? '200px' : ''
      },
      {
        name: this.t('Value'),
        sortable: true,
        selector: row => row['value'],
        sortSelector: 'value',
        hide: hideColumns(900, windowWidth)
      },
      {
        name: capitalize(this.t('UI actions')),
        allowOverflow: true,
        center: true,
        width: '130px',
        hide: hideColumns(370, windowWidth),
        cell: row => {
          return (
            <div className="flex justify-center align-center rounded-full">
              <div className="flex justify-center align-center rounded-full">
                <span
                  onClick={() => this.onEdit(row.id)}
                  className="p-3 rounded-l-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                  <Icon className="h-3 w-3 text-white" name="view_show" />
                </span>
                <PrivateButton control="delete">
                  <span
                    onClick={() => this.onRemove(row.id)}
                    className="p-3 rounded-r-full cursor-pointer bg-gray-700 hover:bg-gray-800">
                    <Icon className="h-3 w-3 text-white" name="trash" />
                  </span>
                </PrivateButton>
              </div>
            </div>
          );
        }
      }
    ];
    // ** Main and secondary settings of layout
    const actions = {
      main: {
        onClick: this.onNew,
        title: this.t('New'),
        checkPermissions: 'insert'
      },
      secondaries: [],
      search: { onSearch: this.onSearch, title: this.t('Search') }
    };
    // ** Render
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{
          breadcrumbs: this.breadcrumbs
        }}
        container={{ className: 'px-8' }}
        actions={actions}
        loading={this.props.settings.loading}>
        <ToastContainer />
        <PanelLayout>
          <StyledDataTable
            data={settings}
            columns={columns}
            selectableRows={windowWidth > 600}
            query={this.props.settings.query}
            getDataFunction={this.getSettings}
            multipleDeleteFunction={this.onRemove}
            pagination
            loading={this.props.settings.loading}
            customSort={this.customSort}
            screenWidth={windowWidth}
          />
        </PanelLayout>
      </LayoutWithSidebar>
    );
  }
}
// Redux state
const mapStateToProps = (state) => {
  return {
    settings: state.settings.list,
  };
};
// Dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAll: (params) => dispatch(settingsActions.getAll(params)),
    onRemove: (id) => dispatch(settingsActions.del(id)),
  };
};
// Redux
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Settings));
