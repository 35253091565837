import React from 'react';
import Sidebar from './Sidebar';
import NavActionBar from '../commons/NavActionBar';

export default function LayoutWithSidebar({
  main,
  header,
  container,
  actions,
  children,
}) {
  return (
    <main
      className={
        'flex flex-col h-screen bg-gray-100 ' +
        (main?.className ? main.className : '')
      }
    >
      <Sidebar header={header} />
      <div
        className={
          'flex-1 overflow-y-auto md:ml-64 ' +
          (container?.className ? container.className : 'px-4')
        }
      >
        <NavActionBar
          actions={actions}
          breadcrumbs={header?.breadcrumbs}
          className={main?.className}
        />
        {children}
      </div>
    </main>
  );
}
