const types = {
  CLEAR_CURRENT: 'LOCATIONS_CLEAR_CURRENT',
  GET_REQUEST: 'LOCATIONS_GET_REQUEST',
  GET_SUCCESS: 'LOCATIONS_GET_SUCCESS',
  GET_FAILURE: 'LOCATIONS_GET_FAILURE',

  SAVE_REQUEST: 'LOCATIONS_SAVER_REQUEST',
  SAVE_SUCCESS: 'LOCATIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'LOCATIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'LOCATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'LOCATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'LOCATIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'LOCATIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'LOCATIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'LOCATIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'LOCATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'LOCATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'LOCATIONS_UPDATE_FAILURE'    

};

export default types;