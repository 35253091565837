import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { PrivateRoute } from '../components/commons/PrivateRoute';
import config from '../config';
import Error from '../pages/commons/Error';
import Dashboard from '../pages/Dashboard';
import UserLogin from '../pages/auth/UserLogin';
import UserSignup from '../pages/auth/UserSignup';
import UserPassword from '../pages/auth/UserPassword';
import UserPasswordChange from '../pages/auth/UserPasswordChange';
import UserVerify from '../pages/auth/UserVerify';
import UserInfo from '../pages/auth/UserInfo';
import UserForgot from '../pages/auth/UserForgot';
import UserProfile from '../pages/auth/UserProfile';
import InfoTerms from '../pages/info/InfoTerms';
import InfoContactUs from '../pages/info/InfoContactUs';
import InfoPrivacy from '../pages/info/InfoPrivacy';
import InfoHelp from '../pages/info/InfoHelp';
import Users from '../pages/crud/users/Users';
import User from '../pages/crud/users/User';

import Messages from '../pages/crud/messages/Messages';
import Message from '../pages/crud/messages/Message';
import Accounts from '../pages/crud/accounts/Accounts';
import Account from '../pages/crud/accounts/Account';
import Settings from '../pages/crud/settings/Settings';
import MySettings from '../pages/crud/settings/MySettings';
import Setting from '../pages/crud/settings/Setting';
import Transactions from '../pages/crud/transactions/Transactions';
import Transaction from '../pages/crud/transactions/Transaction';
import Transfers from '../pages/crud/transactions/Transfers';
import Discounts from '../pages/crud/discounts/Discounts';
import Discount from '../pages/crud/discounts/Discount';


export const history = createBrowserHistory();

export const Routes = () => {
  const location = useLocation();
  const { t } = useTranslation();  

  return (
    <Switch location={location}>
      <Route path={config.ROUTES.TERMS} exact component={InfoTerms} />
      <Route path={config.ROUTES.CONTACT_US} exact component={InfoContactUs} />
      <Route path={config.ROUTES.PRIVACY} exact component={InfoPrivacy} />
      <Route path={config.ROUTES.HELP} exact component={InfoHelp} />

      <Route path={config.ROUTES.LOGIN} exact component={UserLogin} />
      <Route path={config.ROUTES.SIGNUP} exact component={UserSignup} />
      <Route path={config.ROUTES.PASSWORD} exact component={UserPassword} />
      <Route path={config.ROUTES.VERIFY} exact component={UserVerify} />
      <Route path={config.ROUTES.FORGOT} exact component={UserForgot} />

      <PrivateRoute path={config.ROUTES.USER.PASSWORD_CHANGE} exact component={UserPasswordChange} />
      <PrivateRoute path={config.ROUTES.USER.PROFILE} exact component={UserProfile} />
      <PrivateRoute path={config.ROUTES.USER.INFO} exact component={UserInfo} />      

      <PrivateRoute path={config.ROUTES.USERS} exact component={Users} />
      <PrivateRoute path={config.ROUTES.USERS_NEW} exact component={User} />
      <PrivateRoute path={config.ROUTES.USERS_EDIT} exact component={User} />

      <PrivateRoute path={config.ROUTES.MESSAGES} exact component={Messages} />
      <PrivateRoute path={config.ROUTES.MESSAGES_NEW} exact component={Message} />
      <PrivateRoute path={config.ROUTES.MESSAGES_EDIT} exact component={Message} />

      <PrivateRoute path={config.ROUTES.DISCOUNTS} exact component={Discounts} />
      <PrivateRoute path={config.ROUTES.DISCOUNTS_NEW} exact component={Discount} />
      <PrivateRoute path={config.ROUTES.DISCOUNTS_EDIT} exact component={Discount} />

      <PrivateRoute path={config.ROUTES.SETTINGS} exact component={Settings} />
      <PrivateRoute path={config.ROUTES.MY_SETTINGS} exact component={MySettings} />
      <PrivateRoute path={config.ROUTES.SETTINGS_NEW} exact component={Setting} />
      <PrivateRoute path={config.ROUTES.SETTINGS_EDIT} exact component={Setting} />

      <PrivateRoute path={config.ROUTES.TRANSACTIONS} exact component={Transactions} />
      <PrivateRoute path={config.ROUTES.TRANSACTION} exact component={Transaction} />
      <PrivateRoute path={config.ROUTES.TRANSACTIONS_TRANSFERS} exact component={Transfers} />

      <PrivateRoute path={config.ROUTES.HOME} exact component={Dashboard} />

      <Route>
        <Error title={t('404')} headline={t('Page not found!')} button={t('Go back')}/>
      </Route>        
    </Switch>
  );
}